import { useState, useEffect } from "react";
import { ShareLocation, getLocation } from "../helper/api/requests.ts";
import { useAuth } from "../context/auth.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { SHARE_URL_BASE } from "../helper/api/constants.ts";
const useLongPressFetching = (setShare) => {
  const [t, il8n] = useTranslation();
  const { token, getToken, setToken } = useAuth();

  const LongPressShareLocation = async (token, { lat, lng }, toastId) => {
    try {
      let result = await ShareLocation(token, {
        lat,
        lng,
      });

      if (result.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result = await ShareLocation(newToken, { lat, lng });
      }

      if (result.error) {
        toast.update(toastId, {
          render: result.error + " " + t("long_press_on_map"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      } else {
        setShare({
          value: `${SHARE_URL_BASE}?l=${
            il8n.language === "ar" ? "A" : "E"
          }&sn=${result.DbSeqNo}`,
          isShare: true,
        });
        toast.dismiss(toastId);
      }
    } catch (error) {
      toast.update(toastId, {
        render: error + " Long press on map",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: il8n.language === "ar" ? "top-left" : "top-right",
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
      console.error(error);
    }
  };
  const LongPressGetLocation = async (DbSeqNo) => {
    try {
      let result = await getLocation(t, DbSeqNo, token);

      if (result?.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result = await getLocation(t, DbSeqNo, token);
      }
      if (result.error) {
        toast.error(result.error, {
          // render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
        return;
      }

      return result;
    } catch (e) {
      console.error(e, "error");
    }
  };

  return {
    LongPressShareLocation,
    LongPressGetLocation,
  };
};

export default useLongPressFetching;

///12:46
