import './style.css';
import { useTranslation } from "react-i18next";
const POICards = ({ pois }) => {
  const [t, il8n] = useTranslation();
  return (
    <div className='POIMenuItemCards'>
      {pois && pois.loading ? (
        <>{t('loading')}</>
      ) : (
        <>
          {pois && pois.data.length > 0 ? (
            pois.data.map((poi, index) => (
              <div className='POIMenuItemCard' key={index}>
                <div>
                  <p className='POIMenuItemCardTitle'>{poi.NameE}</p>
                </div>
                <div>
                  <p className='POIMenuItemCardCategory'>{poi.CommunityE}</p>
                </div>
                <div className='POIMenuItemCardNavigate'>
                  <p className='POIMenuItemCardNearestBarking'>
                    {poi.SubCategoryL1_E}
                  </p>
                  <p className='POIMenuItemCardNavigateTitle'>Navigate</p>
                </div>
              </div>
            ))
          ) : (
            <p>No POI found</p>
          )}
        </>
      )}
    </div>
  );
};

export default POICards;
