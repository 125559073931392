
import { getTranslations } from './indexedDB';

class CustomBackend {
  constructor(services, options = {}, allOptions = {}) {
    this.init(services, options, allOptions);
  }

  init(services, options = {}, allOptions = {}) {
    this.services = services;
    this.options = options;
    this.allOptions = allOptions;
  }

  read(language, namespace, callback) {
    getTranslations(language)
      .then((data) => {
        if (data) {
          callback(null, data);
        } else {
          callback(new Error(`No data found for language: ${language}`), false);
        }
      })
      .catch((error) => {
        callback(error, false);
      });
  }

  create(languages, namespace, key, fallbackValue) {
    // This method is required but we won't implement it for now.
  }
}

CustomBackend.type = 'backend';

export default CustomBackend;
