import React, { useEffect, useState, useRef } from "react";
import MyMakaniCard from "../myMakaniCard/index.jsx";
import "./style.css";
import { NoDataImg } from "../../assets/index.js";
import { useTranslation } from "react-i18next";
import { useAppearance } from "../../context/AppearanceContext.js";
import UseRemoveMapShape from "../../customHooks/RemoveMapShape.jsx";
import { useFavorites } from "../../context/FavouritesContext.js";
import { useDraggableContext } from "../../context/DraggableContext.js";
import { MOBILE_VIEW_DRAGGER_HEIGHT } from "../../helper/appConstants.js";
const MyMakaniCardsList = ({ setIsEdit, ConfirmEdit, setFavName, cardRef }) => {
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { distanceDragged } = useDraggableContext();
  const { theme } = useAppearance();
  UseRemoveMapShape();
  const { myMakani, setDisaplayMakaniMarker } = useFavorites();
  const [t, il8n] = useTranslation();

  useEffect(() => {
    setDisaplayMakaniMarker(true);
  }, []);

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() => {
    const calculatedHeight = 150 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);
  return (
    <div
      className="myMakaniCardsWarper"
      style={{
        overflowY: isMobileScreen ? "auto" : "",
        height: isMobileScreen ? `${sideBarContentHeight}px` : "auto",
      }}
    >
      {myMakani.length > 0 &&
        myMakani.map((item, index) => {
          return (
            <MyMakaniCard
              theme={theme}
              title={item.FavName}
              makaniNumber={item.FavValue}
              details={
                il8n.language === "ar" ? item.BuildingNameA : item.BuildingNameE
              }
              setIsEdit={setIsEdit}
              ConfirmEdit={ConfirmEdit}
              setFavName={setFavName}
              index={index}
              location={item.Location}
              cardRef={cardRef}
            />
          );
        })}
      {myMakani.length === 0 && (
        <div className="no-data-makani">
          <div className="no-data-img">
            <img src={NoDataImg} alt="No data" />
          </div>
          <p className="noDataFound">{t("noResult")}</p>
        </div>
      )}
    </div>
  );
};

export default MyMakaniCardsList;
