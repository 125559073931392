import { BASE_URL } from "./constants.ts";

const request = async (
  method: string,
  endpoint: string,
  body?: any
): Promise<any> => {
  try {
    const url = `${BASE_URL}${endpoint}`;
    const response: any = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: method === "GET" ? undefined : JSON.stringify(body),
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data from ${endpoint}: ${error.message}`);
  }
};

export const get = async (endpoint: string): Promise<any> => {
  return await request("get", endpoint);
};

export const post = async (endpoint: string, data: any): Promise<any> => {
  return await request("post", endpoint, data);
};

export const del = async (endpoint: string): Promise<any> => {
  return await request("delete", endpoint);
};

export const put = async (endpoint: string, data: any): Promise<any> => {
  return await request("put", endpoint, data);
};
