import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import CustomBackend from './customBackend'; 
// import Backend from 'i18next-http-backend';

i18n
.use(CustomBackend) 
.use(LanguageDetector)
.use(initReactI18next)
.init({
  fallbackLng: 'en', 
  debug: true,
  requestOptions: {
    cache: 'no-store',
  },
  interpolation: {
    escapeValue: false, 
  },
});


export default i18n;