import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeaderTitle, CustomSelect } from "../../component";
import {
  useGetCommunitiesFetching,
  useGetStreetForCommunityFetching,
  useGetBuildingsForStreetFetching,
  UseRemoveMapShape,
  useSingleMakaniCardFetching,
} from "../../customHooks/index";
import "../../scss/common.scss";
import "../../scss/custom-select.scss";

import { useAppearance } from "../../context/AppearanceContext";
import { useNavigate } from "react-router-dom";
import { useDraggableContext } from "../../context/DraggableContext";
const SearchByBuildingAddress = () => {
  const { theme } = useAppearance();
  const [t,i18n] = useTranslation();
  const navigate = useNavigate();
  const { distanceDragged } = useDraggableContext();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  UseRemoveMapShape();

  const [state, setState] = useState({
    isCommunityActive: true,
    isStreetActive: false,
    isBuildingActive: false,
    streetSelectOption: "",
    buildingSelectedOption: "",
    communitySelectedOption: "",
    isCommunityExpanded: false,
    isStreetExpanded: false,
    isBuildingExpanded: false,
  });

  const { community, loading: communityLoading } = useGetCommunitiesFetching();
  const {
    streets,
    fetchStreetForCommunity,
    loading: streetLoading,
  } = useGetStreetForCommunityFetching();
  const {
    buildings,
    fetchBuildingsForStreet,
    loading: buildingLoading,
  } = useGetBuildingsForStreetFetching();

  const handleOptionClick = (
    optionText,
    fetchFunction,
    field,
    filedActive,
    preOptionText
  ) => {
    fetchFunction(optionText, preOptionText);
    setState({ ...state, [field]: optionText, [filedActive]: false });
  };

  const toggleDropdown = (field, array) => {
    setState({
      ...state,
      [array[0]]: false,
      [array[1]]: false,
      [field]: !state[field],
    });
  };

  const {
    isStreetActive,
    isBuildingActive,
    streetSelectOption,
    buildingSelectedOption,
    communitySelectedOption,
    isCommunityExpanded,
    isStreetExpanded,
    isBuildingExpanded,
  } = state;

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() => {
    const calculatedHeight = 130 - distanceDragged; // adjust 95 according to the height of the header
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  return (
    <div
      className="sideBarContent swipe-line"
      style={{
        padding: "23px 35px",
      }}
    >
      <PageHeaderTitle title={t("SearchByBuildingAddress")} />
      <div
        className="searchBuildingContent"
        style={{
          overflowY: window.innerWidth <= 767 && "auto",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
        }}
      >
        <div className="searchBuildingInnerContent">
          <div className="custom-select-warper">
            <CustomSelect
              array={community}
              theme={theme}
              handleSelectOption={(optionText) => {
                handleOptionClick(
                  optionText,
                  fetchStreetForCommunity,
                  "communitySelectedOption",
                  "isCommunityExpanded"
                );
              }}
              SelectedValue={communitySelectedOption}
              placeholder={t("community")}
              toggleDropdown={() =>
                toggleDropdown("isCommunityExpanded", [
                  "isStreetExpanded",
                  "isBuildingExpanded",
                ])
              }
              isExpanded={isCommunityExpanded}
              isActive={true}
              value={i18n.language ==="ar" ? "CommunityA":"CommunityE"}
              loading={communityLoading}
              allowLikeSearch={true}
              isMobileScreen={isMobileScreen}
            />

            <CustomSelect
              array={streets}
              theme={theme}
              handleSelectOption={(optionText) => {
                handleOptionClick(
                  optionText,
                  fetchBuildingsForStreet,
                  "streetSelectOption",
                  "isStreetExpanded",
                  communitySelectedOption
                );
              }}
              SelectedValue={streetSelectOption}
              placeholder={t("street")}
              toggleDropdown={() => {
                if (communitySelectedOption) {
                  toggleDropdown("isStreetExpanded", [
                    "isCommunityExpanded",
                    "isBuildingExpanded",
                  ]);
                }
              }}
              isExpanded={isStreetExpanded}
              isActive={communitySelectedOption}
              value={i18n.language ==="ar"? "StreetNameA" :"StreetNameE"}
              loading={streetLoading}
              allowLikeSearch={true}
              isMobileScreen={isMobileScreen}
            />
            <CustomSelect
              array={buildings}
              theme={theme}
              handleSelectOption={(optionText) =>
                handleOptionClick(
                  optionText,
                  () => {},
                  "buildingSelectedOption",
                  "isBuildingExpanded"
                )
              }
              SelectedValue={buildingSelectedOption}
              placeholder={t("buildingNo")}
              isExpanded={isBuildingExpanded}
              toggleDropdown={() => {
                if (communitySelectedOption && streetSelectOption) {
                  toggleDropdown("isBuildingExpanded", [
                    "isCommunityExpanded",
                    "isStreetExpanded",
                  ]);
                }
              }}
              isActive={communitySelectedOption && streetSelectOption}
              value={"AddressE"}
              loading={buildingLoading}
              allowLikeSearch={true}
              isMobileScreen={isMobileScreen}
            />
          </div>
          <div className="searchByBuildingBtnWarper">
            <div
              className={
                buildingSelectedOption &&
                streetSelectOption &&
                communitySelectedOption
                  ? "searchByBuildingBtnActive"
                  : "searchByBuildingBtn"
              }
              onClick={() => {
                if (
                  buildingSelectedOption &&
                  streetSelectOption &&
                  communitySelectedOption
                ) {
                  navigate("/building-address", {
                    state: {
                      community: communitySelectedOption,
                      street: streetSelectOption,
                      building: buildingSelectedOption,
                    },
                  });
                }
              }}
            >
              {t("search")}
            </div>
          </div>
        </div>
      </div>
      <div class="copy_right">
      {t('Copyright')}
      </div>
    </div>
  );
};

export default SearchByBuildingAddress;

//18:14
