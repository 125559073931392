import { useEffect } from 'react';
import { useMapControl } from '../context/MapControllContext';
import { useFavorites } from '../context/FavouritesContext';
import { useAuth } from '../context/auth';
import {usePOICategories} from '../context/POICategoriesContext'


const UseRemoveMapShape = () => {
  const {
    setMakaniCoords,
    setPolygonCoords,
    setPolygonCoords2,
    setDestination,
    setStreetLevel,
    setOrigin,
    setCenter,
    setDirections,
    setDurationOnCar,
    setDurationOnFeet,
    setDistanceOnCar,
    setDistanceOnFeet,
    setBuildingsPolygon,
    setZoom,
    setLongPressPosition,
    setOnPressMarker
    
  } = useMapControl();
  const{setPoi,setSelectedPoi}=usePOICategories()

  const {setDisaplayMakaniMarker}=useFavorites();
  useEffect(() => {
    setDirections(null);
    setMakaniCoords([]);
    setPolygonCoords([]);
    setPolygonCoords2([]);
    setOrigin('');
    setDestination('');
    setDurationOnCar('');
    setDurationOnFeet('');
    setDistanceOnCar('');
    setDistanceOnFeet('');
    setDisaplayMakaniMarker(false)
    setBuildingsPolygon([])
    setPoi([])
    setSelectedPoi(-1)
    setStreetLevel({
      lat: '',
      lng: '',
    });
    setOnPressMarker({
      lat:"",
      lng:""
    })

    setLongPressPosition({
      lat:"",
      lng:"",
      CommunityE:"",
      CommunityA:""
    })

  }, []);
};


export default UseRemoveMapShape;
