import {
  PageHeaderTitle,
  MakaniDetails as MakaniDetailsComponent,
  MakaniCard,
} from '../../component';
import '../../scss/common.scss';
import '../../scss/article.scss';
import { useTranslation } from 'react-i18next';
import {vision01Img, vision02Img } from '../../assets/index';
import AccessibleButton from '../../component/Accessibility/accessibility';
import { UseRemoveMapShape } from '../../customHooks';
import { useLocation, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants';
import { useDraggableContext } from '../../context/DraggableContext';
import{useToggle} from '../../context/index.js';
import { SpeechSynthesisButton } from '../../component/index';

const About= () => {
  const [t] = useTranslation();
  UseRemoveMapShape();
  const location = useLocation();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { distanceDragged } = useDraggableContext();
  const {setMenuToggle} =useToggle()

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() =>{
    const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  const textToRead = [
    `${t('about')} ${t('aboutdesc')}`,
    `${t('abouttitle')}: ${t('abouttitledesc')}`,
    `${t('aboutapplicationavailable')}: ${t('appsavailable')} www.makani.ae`,
    `${t('ourvision')}: ${t('visiondesc')}`,
    `${t('ourmission')}: ${t('missiondesc')}`
  ].join(' ');

  return (
    <div
      className='sideBarContent inner-content inner-article swipe-line'
      style={{
        padding: '',
      }}
    >
      <div className='PageHeaderTitleWarper'>
        <PageHeaderTitle title={t('about')}   parent={location?.state?.parent} fromMenu={location?.state?.fromMenu}  />
        <SpeechSynthesisButton text={textToRead}/>
      </div>
      
      <div
        className="content-cover"
        style={{
          overflowY: isMobileScreen ? 'auto' : '',
          height: isMobileScreen ? `${sideBarContentHeight}px` : 'auto', // added scroll ability for mobile screen
        }}
      >
        <article>
          <AccessibleButton 
            className="aboutButton" 
            ariaLabel={t('aboutdesc')}
          >
            <p>{t('aboutdesc')}</p>
          </AccessibleButton>
       

           <div className="tilte-md">{t('abouttitle')}</div>
            <AccessibleButton 
              className="aboutit" 
              ariaLabel={t('abouttitledesc')}
            >
              <p>{t('abouttitledesc')}</p>
            </AccessibleButton>
            
            <div className="tilte-md">{t('aboutapplicationavailable')}</div>
            <p>{t('appsavailable')} <a className='link'href="http://www.makani.ae" target="_blank" rel="noopener noreferrer">www.makani.ae</a> </p>
            <div className="tilte-md">{t('ourvision')}</div>
            <AccessibleButton 
              ariaLabel={t('visiondesc')}
            >
              <p>{t('visiondesc')}</p>
              </AccessibleButton>
            

            <div className="tilte-md">{t('ourmission')}</div>
            <AccessibleButton 
              ariaLabel={t('missiondesc')}
            >
              <p>{t('missiondesc')}</p>
              </AccessibleButton>
            

        </article>

      </div>
   
      <div class="copy_right">{t('Copyright')}</div>
    </div>
    
  );
};

export default About;
//15:02