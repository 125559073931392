import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import {
  IconWhatsup,
  IconFb,
  IconX,
  IconIn,
  IconEmail,
  checked,
} from "../../../assets/index";
import { ModalCloseBtn } from "../../../assets/svg/svgIcons";

import useClipboard from "react-use-clipboard";
import { logEvent } from "../../../analytics";
import { useTranslation } from "react-i18next";
import { useMapControl } from "../../../context";
const ShareModel = ({
  setShare,
  url,
  ForMakani,
  FormFavorite,
  handlePopClose,
}) => {
  const { setLongPressPosition } = useMapControl();

  const handleClose = () => {
    if (ForMakani) {
      setShare(false);
    } else if (FormFavorite) {
      handlePopClose();
    } else if (url) {
      setLongPressPosition({ lat: null, lng: null });
      setShare({
        value: "",
        isShare: false,
      });
    } else {
      setShare({
        value: "",
        isShare: false,
      });
    }
  };
  const handleCopy = () => {
    setCopied();
  };

  const [isCopied, setCopied] = useClipboard(url, { successDuration: 1000 });
  const getFullLanguageName = (langCode) => {
    switch (langCode) {
      case "en":
        return "English";
      case "ar":
        return "Arabic";
      default:
        return "Unknown"; // Default case if the language code is not recognized
    }
  };
  const [t, i18n] = useTranslation();
  const currentLanguage = getFullLanguageName(i18n.language);

  return (
    <div
      className={`${
        ForMakani || FormFavorite ? "share-drop-down-slider" : "share-drop-down"
      }`}
    >
      <div className="share-box">
        <div className="btn-close" onClick={handleClose}>
          <ModalCloseBtn />
        </div>
        <div className="share-head">
          <span>{t("share")}</span>
        </div>
        <div className="share-row">
          <div className="share-link">
            <div className="share-circle">
              <WhatsappShareButton url={url}>
                <img src={IconWhatsup} alt="WhatsApp" />
              </WhatsappShareButton>
            </div>
            <p>{t("WhatsApp")}</p>
          </div>

          <div className="share-link">
            <div className="share-circle">
              <FacebookShareButton
                url={url}
                onClick={() =>
                  logEvent("Share to Facebook", "Share Click", currentLanguage)
                }
              >
                <img src={IconFb} alt="Facebook" />
              </FacebookShareButton>
            </div>
            <p>{t("Facebook")}</p>
          </div>

          <div className="share-link">
            <div className="share-circle">
              <TwitterShareButton
                url={url}
                onClick={() =>
                  logEvent("Share to twitter", "Share Click", currentLanguage)
                }
              >
                <img src={IconX} alt="Twitter" />
              </TwitterShareButton>
            </div>
            <p>{t("Twitter")}</p>
          </div>

          <div className="share-link">
            <div className="share-circle">
              <LinkedinShareButton
                url={url}
                onClick={() =>
                  logEvent("Share to Linkedin", "Share Click", currentLanguage)
                }
              >
                <img src={IconIn} alt="LinkedIn" />
              </LinkedinShareButton>
            </div>
            <p>{t("LinkedIn")}</p>
          </div>

          <div className="share-link">
            <div className="share-circle">
              <a
                href={`mailto:?body=${encodeURIComponent(url)}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  logEvent("Share to Email", "Share Click", currentLanguage)
                }
              >
                <img src={IconEmail} alt="Email" />
              </a>
            </div>
            <p>{t("Email")}</p>
          </div>
        </div>

        <div className="copy-link-row">
          <div className="label">{t("orCopyLink")}</div>
          <div className="copy-link">
            <p>{url}</p>
            {isCopied ? (
              <span className="coped-align">
                <span class="copied" style={{
                      whiteSpace: 'nowrap'
                }}>{t("copied")}</span>{" "}
                <img
                  width="14"
                  height="14"
                  className="copy__btn copiedbtn"
                  src={checked}
                  alt="copy"
                />
              </span>
            ) : (
              <button className="copy" onClick={() => handleCopy()}>
                {t("copy")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModel;
