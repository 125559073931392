import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
const Callback = () => {

  const [t, il8n] = useTranslation();
  const location = useLocation();
  useEffect(() => {
    const fetchToken = async (code) => {
      try {
        const response = await fetch('https://stg-id.uaepass.ae/idshub/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic c2FuZGJveF9zdGFnZTpzYW5kYm94X3N0YWdl'
          },
          body: queryString.stringify({
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: 'http://localhost:4000/profile', // Replace with your redirect URI
            client_id: 'sandbox_stage',
             client_secret: 'YOUR_CLIENT_SECRET'
          })
        });

        if (!response.ok) {

          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        fetchUserInfo(data.access_token);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserInfo = async (accessToken) => {
      try {
        const response = await fetch('https://stg-id.uaepass.ae/idshub/userinfo', {
            mode: 'no-cors',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

      } catch (error) {
        console.error(error);
      }
    };

    const { code } = queryString.parse(location.search);
    if (code) {
      fetchToken(code);
    }
  }, [location.search]);

  return <div>{t('loading')}</div>;
};

export default Callback;
