import { useState } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  MenuIcon1,
  MenuIcon2,
  MenuIcon3,
  MenuIcon4,
  MenuIcon5,
  MenuIcon6,
  MenuIcon7,
  MenuIcon8,
  DownloadIcon,
  SecurityIcon
} from "../../../assets/svg/svgIcons";
import { menuClose, LogoMain } from "../../../assets/index";
import "../../../scss/menu.scss";
import "../../../scss/header.scss";
import { useToggle } from "../../../context/ToggleModel";
import DownloadApp from "../../downloadApp";
import AccessibleButton from "../../Accessibility/accessibility";
import { logEvent } from "../../../analytics";

const Menu = () => {
  const { handleLangChange } = useLanguage();
  const { menuToggle, setMenuToggle } = useToggle();
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const change = (lang) => {
    const direction = lang === "ar" ? "rtl" : "ltr";
    handleLangChange(direction, lang);
  };

  const toggleLanguage = (lang) => {
    change(lang);
    window.location.reload(); //reload the page when language is switched
  };

  const toggleSubMenu = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const handleDownloadClick = () => {
    setShowDownloadPopup(true);
  };

  const openFeedbackWindow = () => {
    const width = window.screen.width * 0.9;
    const height = window.screen.height * 0.9;
    window.open(
      "https://geodubai.dm.gov.ae/en/Pages/ContactUsForm.aspx",
      "_blank",
      `width=${width},height=${height}`
    );
  };

  const openSecurityPrivacyWindow = () => {
    const url = `https://www.dm.gov.ae/website-policies/security-privacy/`;
    window.open(url, '_blank');
  }


  return (
    <div
      className={`menu   ${menuToggle ? "side_menu" : ""}`}
      style={{
        display: menuToggle ? "block" : "none",
      }}
    >
      <div className="menuHeader">
        <div
          className="menuBtn"
          onClick={() => {
            setMenuToggle(false);
            setIsSubMenuVisible(false);
          }}
        >
          <AccessibleButton ariaLabel={t("Click here for menu")}>
            <img
              width="26"
              height="19"
              src={menuClose}
              alt="close"
              className="btn-menu-icon"
              onClick={() => {
                setMenuToggle(false);
                setIsSubMenuVisible(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </AccessibleButton>
        </div>
        <div className="logo">
          <img
            width="164.98"
            height="63.61"
            src={LogoMain}
            alt="logo"
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
          <p className="header-title">{t("geographicAddressingSystem")}</p>
        </div>
      </div>
      <div className="menuHeaderMobile"></div>
      <div className="menuContent">
        <ul>
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/"
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon1 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for home page")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("home")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/login"
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon2 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for login")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("login")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          {/* <li>
            <div className='menuItem' onClick={() => setMenuToggle(false)}>
              <div className='icon-m'>
                <MenuIcon3 />
              </div>
              <AccessibleButton
                className='menuItemTitle'
                ariaLabel={t('Click here for accessibility information')}
                onClick={() => setMenuToggle(false)}
              >
                <span className='menuItemTitle'>{t('accessibility')}</span>
              </AccessibleButton>
            </div>
          </li> */}
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/contact"
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon5 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for contact us information")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("contactUs")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/about "
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon4 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for about us information")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("about")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/disclaimer"
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon6 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for disclaimer information")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("Disclaimer")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          <li>
            <div
              className="menuItem"
              onClick={handleDownloadClick}
              style={{
                color: showDownloadPopup && "#009D91",
                cursor: "pointer",
              }}
            >
              <div
                className="icon-m"
                style={{
                  color: showDownloadPopup && "#009D91",
                }}
              >
                <DownloadIcon />
              </div>
              <AccessibleButton
                className="menuItemTitle"
                ariaLabel={t("Click here to download")}
              >
                <span
                  className="menuItemTitle cursor-pointer"
                  style={{
                    color: showDownloadPopup && "#009D91",
                  }}
                >
                  {t("download")}
                </span>
              </AccessibleButton>
            </div>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none" }}
              to="/sitemap"
              state={{ fromMenu: true }}
            >
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <MenuIcon7 />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for sitemap information")}
                  onClick={() => {
                    setMenuToggle(false);
                    setIsSubMenuVisible(false);
                  }}
                >
                  <span className="menuItemTitle">{t("Sitemap")}</span>
                </AccessibleButton>
              </div>
            </Link>
          </li>
          
          <li>
            <div
              className={`menuItem sub-menu ${
                isSubMenuVisible ? "arrow-up" : ""
              }`}
              onClick={toggleSubMenu}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="icon-m">
                <MenuIcon8 />
              </div>
              <AccessibleButton
                className="menuItemTitle"
                ariaLabel={t("Click here for information")}
              >
                <span className="menuItemTitle">{t("Information")}</span>
              </AccessibleButton>
            </div>
            {isSubMenuVisible && (
              <ul className="sub-menu">
                <Link
                  style={{ textDecoration: "none" }}
                  to="/faq"
                  state={{ fromMenu: true }}
                >
                  <AccessibleButton ariaLabel={t("clickHereForFAQ")}>
                    <li
                      className="nav-item"
                      style={{
                        background: location.pathname === "/faq" && "#333C4F",
                      }}
                      onClick={() => {
                        // setIsSubMenuVisible(false)
                        setMenuToggle(false);
                      }}
                    >
                      {t("Faq")}
                    </li>
                  </AccessibleButton>
                </Link>
                {/* <AccessibleButton ariaLabel={t('Click here for help')}>
                  <li className='nav-item'>{t('help')}</li>
                </AccessibleButton> */}
                <Link
                  style={{ textDecoration: "none" }}
                  to="/legend"
                  state={{ fromMenu: true }}
                >
                  <AccessibleButton ariaLabel={t("clickHereForLegend")}>
                    <li
                      className="nav-item"
                      style={{
                        background:
                          location.pathname === "/legend" && "#333C4F",
                      }}
                      onClick={() => {
                        // setIsSubMenuVisible(false)
                        setMenuToggle(false);
                      }}
                    >
                      {t("Legend")}
                    </li>
                  </AccessibleButton>
                </Link>
                <AccessibleButton
                  ariaLabel={t("Click here for feedback")}
                  onClick={openFeedbackWindow}
                >
                  <li className="nav-item">{t("Feedback")}</li>
                </AccessibleButton>
              </ul>
            )}
          </li>
            {/* new change start*/}
          <li>
            <Link
            style={{textDecoration: 'none'}}>
              <div
                className="menuItem"
                onClick={() => {
                  setMenuToggle(false);
                  setIsSubMenuVisible(false);
                }}
              >
                <div className="icon-m">
                  <SecurityIcon />
                </div>
                <AccessibleButton
                  className="menuItemTitle"
                  ariaLabel={t("Click here for login")}
                  onClick={openSecurityPrivacyWindow}
                >
                  <span className="menuItemTitle">{t("security_and_privacy")}</span>
                </AccessibleButton>
              </div>
              </Link>
          </li>
          {/* end of new change */}
          
        </ul>
        {showDownloadPopup && (
          <DownloadApp setShowPopup={setShowDownloadPopup} />
        )}
        <div
          className="lang-otr"
          style={{
            marginTop: "",
          }}
        >
          <AccessibleButton
            className="toggle-container"
            ariaLabel={t("toggle here to change the language")}
          >
            <div
              className={`toggle-button ${
                il8n.language === "ar" ? "active" : ""
              }`}
              onClick={() => {
                toggleLanguage("ar");
                logEvent("Change Language", "Change Language Click", "Arabic");
              }}
            >
              العربية
            </div>

            <div
              className={`toggle-button ${
                il8n.language === "en" ? "active" : ""
              }`}
              onClick={() => {
                toggleLanguage("en");
                logEvent("Change Language", "Change Language Click", "English");
              }}
            >
              English
            </div>
          </AccessibleButton>
        </div>
      </div>
    </div>
  );
};

export default Menu;
