// NOTE: Distance on user location
const isLocationEnabled = () => {
  return new Promise((resolve) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        () => {
          resolve(true);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            resolve(false);
          } else {
            console.error("Error while checking location:", error);
            resolve(false);
          }
        }
      );
    } else {
      resolve(false);
    }
  });
};

export default isLocationEnabled;
