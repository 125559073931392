import { useState } from 'react';
import { GetAllStreetForCommunity } from '../helper/api/requests.ts';
import { useAuth } from '../context/auth.js';
import { useTranslation } from "react-i18next";

const useGetStreetForCommunityFetching = () => {
  const [streets, setStreets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token,getToken,setToken } = useAuth();
  const [t,i18n] = useTranslation();

  const fetchStreetForCommunity = async (community) => {
    try {
     let result = await GetAllStreetForCommunity(community, token,i18n.language);
      if(result.isExp){
        let newToken= await getToken();
        localStorage.setItem("token", JSON.stringify(newToken));
        setToken(newToken);
        result = await GetAllStreetForCommunity(community, newToken ,i18n.i18n);
      }
      
      setStreets(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fetchStreetForCommunity,
    streets,
    loading,
  };
};

export default useGetStreetForCommunityFetching;
//16:31
