import { useTranslation } from 'react-i18next';
import {
  PageHeaderTitle,
  CollapsibleMenu,
  MapAndNavigationSettings,
  AppearanceSettings,
  SettingDetermination,
  SettingHelping,
} from '../../component/index';
import { settingResponsiveIcon,atm } from '../../assets/index';
import { useEffect, useRef, useState } from 'react';
import '../../scss/common.scss';
import { UseRemoveMapShape } from '../../customHooks';
import { useDraggableContext } from '../../context/DraggableContext';
import AccessibleButton from '../../component/Accessibility/accessibility';
import withTour from '../../withTour';

const Settings = () => {
  const [t] = useTranslation();
  const { distanceDragged } = useDraggableContext();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const settingsContentRef = useRef(null);
  UseRemoveMapShape();

  const setScrollProperties = () => {
    setTimeout(() => {
      if (isMobileScreen && settingsContentRef && settingsContentRef.current && settingsContentRef.current.style) {
        settingsContentRef.current.style.setProperty('height', `${sideBarContentHeight}px`);
        settingsContentRef.current.style.setProperty('overflow-y', 'auto');
      }
    }, 100);
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() =>{
    const calculatedHeight = 95 - distanceDragged;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    setScrollProperties();
  }, [isMobileScreen, settingsContentRef, sideBarContentHeight]);

  useEffect(() => {
    setScrollProperties();
  }, []);
  return (
    <div
      className='sideBarContent swipe-line'
      // style={{
      //   padding: '',
      //   overflowX: 'auto',
      // }}
    >
      <PageHeaderTitle
        title={t('settings')}
        responsiveIcon={settingResponsiveIcon}
      />

      <div
        className='settingsContent'
        ref={settingsContentRef}
      >
        <CollapsibleMenu
          items={[
            {
              img: atm,
              title: (
                <AccessibleButton ariaLabel={t('Click here to change language')}>
                  {t('language')}
                </AccessibleButton>
              ),
            },
            {
              img: atm,
              title: (
                <AccessibleButton ariaLabel={t('Click here for map settings')}>
                  {t('mapsetting')}
                </AccessibleButton>
              ),
            },
            {
              img: atm,
              title: (
                <AccessibleButton ariaLabel={t('Click here to change appearance')}>
                  {t('appearance')}
                </AccessibleButton>
              ),
            },
            {
              img: atm,
              title: (
                <AccessibleButton ariaLabel={t('Click here for people of determination settings')}>
                  {t('peopleOfDetermination')}
                </AccessibleButton>
              )
            },
            {
              img: atm,
              title: (
                <AccessibleButton ariaLabel={t('Click here for help')}>
                  {t('help')}
                </AccessibleButton>
              ),
            },
          ]}
          defaultActiveIndex={3}
          notHasArrow={true}
          hasLogo={false}
          children={[
            <></>,
            <MapAndNavigationSettings />,
            <AppearanceSettings />,
            <SettingDetermination />,
            <SettingHelping />,
          ]}
          className='itemTitleSetting'
          itemStyle={{
            padding: '19px 0',
          }}
        />
   

      </div>
      <div class="copy_right">{t('Copyright')}</div>
    </div>
  );
};

export default withTour(Settings,'settings');
