import React, { createContext, useState, useContext, useEffect,useRef } from 'react';
const AppearanceContext = createContext();
export const useAppearance = () => useContext(AppearanceContext);

export const AppearanceProvider = ({ children }) => {

  const loadSetting = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };
  const [theme, setTheme] = useState(loadSetting('theme','light'));
  const [fontSize, setFontSize] = useState(loadSetting('font','0'));
  const [showPopup, setShowPopup] = useState(loadSetting('showDownload',true));
  const [walkThrough,setWalkThrough]=useState(loadSetting('walkThrough', false));



  const saveSetting = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };


  useEffect(() => {

    if(showPopup){
      setWalkThrough(true)
      saveSetting('walkThrough', true);
    }

  }, [showPopup]);


  useEffect(() => {
    saveSetting('theme', theme);
  }, [theme]);

  useEffect(() => {
    saveSetting('font', fontSize);
  }, [fontSize]);
  useEffect(() => {
    saveSetting('walkThrough', walkThrough);
  }, [walkThrough]);

  useEffect(() => {


    saveSetting('showDownload', showPopup);
    
  }, [showPopup]);

  return (
    <AppearanceContext.Provider
      value={{
        theme,
        setTheme,
        fontSize,
        setFontSize,
        setShowPopup,
        showPopup,
        walkThrough,
        setWalkThrough,

      }}
    >
      {children}
    </AppearanceContext.Provider>
  );
};
export default AppearanceProvider;
