import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { compose, withProps } from "recompose";
import { DirectionsService, OverlayView } from "@react-google-maps/api";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import withTour from "../../withTour";
import _ from "lodash";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Polygon,
  TrafficLayer,
  Marker,
  InfoWindow,
} from "react-google-maps";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useToggle,
  useMapControl,
  useAppearance,
  useFavorites,
  useAuth,
  usePOICategories,
  useTour,
} from "../../context/index.js";
import {
  useSmartSearchResultFetching,
  useLongPressFetching,
} from "../../customHooks/index.js";
import { getUserLocation, calculate } from "../../helper/index.js";
import {
  GetNearestMakani,
  GetVirtualPlate,
} from "../../helper/api/requests.ts";
import { getLocationName } from "../../helper/index.js";
import {
  CustomLoader,
  MakaniCardViewOnMap,
  FavoriteLocationPopup,
} from "../index.js";
import useClipboard from "react-use-clipboard";

import {
  MapPlus,
  Mapminus,
  NorthLocation,
  ZoomIn,
  MapLayer,
  Cloud,
} from "../../assets/svg/svgIcons";
import {
  makaniCloseIcon,
  directionMap,
  checked,
  copy as copyIcon,
  clickIcon,
} from "../../assets/index";
import AccessibleButton from "../Accessibility/accessibility.jsx";
import { logEvent } from "../../analytics.js";

const MyMapComponent = compose(
  withProps((props) => {
    const [t, i18n] = useTranslation();
    const [mapKey, setMapKey] = useState(Date.now());

    useEffect(() => {
      setMapKey(Date.now());
    }, [i18n.language]);

    return {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places&language=${i18n.language}}`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: (
        <div style={{ height: "100vh", position: "relative" }} />
      ),
      mapElement: <div style={{ height: `100%` }} />,
      key: mapKey, // Add a key to force re-render
    };
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [t, il8n] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    avoidToll,
    mapType,
    zoomInBtn,
    scaleBar,
    routeType,
    measurementUnit,
    setDurationOnCar,
    setDurationOnFeet,
    setDistanceOnCar,
    setDistanceOnFeet,
    setCurrentLocation,
    setCenter,
    setZoom,
    zoom,
    setDirections,
    currentLocation,
    center,
    streetLevel,
    directions,
    destination,
    startDirection,
    setStartDirection,
    makaniCoords,
    origin,
    directionMode,
    waypoints,
    setStreetLevel,
    lightTheme,
    darkTheme,
    traffic,
    setNeareastMakani,
    nearestMakani,
    buildingsPolygon,
    selectedDirectionMap,
    setLocations,
    locations,
    updateDirection,
    setSelectedDirectionMap,
    setDirectionLoading,
    setTapPosition,
    tapPosition,
    currentAvoidToll,
    homeSelectedMakani,
    homeFavroiteSelect,
    setCurrentLocationDirection,
    currentLocationDirection,
    setHomeFavroiteSelect,
    mapRef,
    longPressPosition,
    setDispalyNearestMakani,
    disaplyNearestMakani,
    onPressMarker,
    setOnPressMarker,
    myMakaniCardRef,
    setMyMakaniCardRef,
  } = useMapControl();
  const { stepIndex } = useTour();

  const {
    setFavoritesToggle,
    favoritesToggle,
    MakaniMarker,
    favoritesMarker,
    displayMarker,
    setSelectedFavoriteMarker,
    setSelectedMakaniMarker,
    selectedMakaniMarker,
    disaplayMakaniMarker,
    myMakani,
    selectedFavoriteMarker,
    selectedFavoriteLoading,
    favoritePlaceInfo,
    setFavoritePalceInfo,
    fetchSingleFavoritePlace,
    fetchSyncFavorites,
    locations: FavLocations,

    cardRef,
  } = useFavorites();
  const { theme } = useAppearance();
  const {
    setMapLayerToggle,
    setMenuToggle,
    setAllowAccessLocation,
    allowAccessLocation,
  } = useToggle();
  const { selectedPoi, setSelectedPoi, poi } = usePOICategories();
  const { token, userId, setToken, getToken, initialToken } = useAuth();
  const streetViewRef = useRef(null);
  const panoramaRef = useRef(null);
  const timerRef = useRef(null);
  const LONG_PRESS_THRESHOLD = 1000;
  const MOVEMENT_THRESHOLD = 5;
  const LONG_PRESS_DURATION = 500;
  const mouseDownTimeRef = useRef(null);
  const mouseUpTimeRef = useRef(null);
  const [showLongPress, setShowLongPress] = useState(true);
  const startPositionRef = useRef({ x: null, y: null });
  const hasMovedRef = useRef(false);
  const [markers, setMarkers] = useState([]);
  let longPressTimeout;
  const [wayPointsMarkers, setWayPointsMarkers] = useState([]);
  const [mapFavoriteToggle, setMapFavroiteToggle] = useState({
    isFavorite: false,
    index: -1,
  });
  const [addToFavrite, setAddToFavroite] = useState({
    index: -1,
    add: false,
  });

  const [homeFavoriteToggle, setHomeFavoriteToggle] = useState({
    index: -1,
    add: false,
  });
  const [addToFavriteHome, setAddToFavroiteHome] = useState({
    index: -1,
    add: false,
  });
  const [text, setText] = useState("initial text");
  const [isCopied, setCopied] = useClipboard(text, { successDuration: 1000 });

  const [heading, setHeading] = useState(90);
  const { LongPressShareLocation } = useLongPressFetching(props.setShare);
  const { GetBuildingOutLineEntrancePoints, makani, loading } =
    useSmartSearchResultFetching();
  const { i18n } = useTranslation();
  const getFullLanguageName = (langCode) => {
    switch (langCode) {
      case "en":
        return "English";
      case "ar":
        return "Arabic";
      default:
        return "Unknown";
    }
  };
  const [loadingId, setLoadingId] = useState("");

  useEffect(() => {
    if (streetLevel.lat && streetLevel.lng) {
      const panorama = new window.google.maps.StreetViewPanorama(
        streetViewRef.current,
        {
          position: { lat: streetLevel.lat, lng: streetLevel.lng },
          pov: { heading: 165, pitch: 0 },
          zoom: 1,
        }
      );

      panoramaRef.current = panorama;
      panorama.addListener("position_changed", async () => {
        const lat = panoramaRef.current.getPosition().lat();
        const lng = panoramaRef.current.getPosition().lng();
        const result = await GetVirtualPlateResult(token, { lat, lng });

        const entrances = result?.data?.Entrances ?? [];

        clearMarkers();
        addMarkers();

        //const [lat, lng] = entrance.Location.split(',').map(Number);

        function addMarkers() {
          if (entrances.length > 0) {
            const filteredEntrances = entrances.filter(
              (entrance) => !entrance.LocationLink.includes("unknown")
            );
            const newMarkers = filteredEntrances.map((entrance) => {
              const [lng, lat] = entrance.Location.split(",").map(Number);
              const marker = new window.google.maps.Marker({
                position: { lat: lat, lng: lng },
                icon: entrance.LocationLink,
                map: panoramaRef.current,
              });
              marker.addListener("click", () => {
                handleMarkerClick(entrance);
              });

              return marker;
            });
            setMarkers(newMarkers);
          }
        }

        function clearMarkers() {
          if (markers.length > 0) {
            markers.forEach((marker) => marker.setMap(null));
            setMarkers([]);
          }
        }

        function handleMarkerClick(entrance) {
          // Perform your desired action here
          // For example, log the entrance details to the console
          const dgiD = entrance.Makani.replace(/\s+/g, ""); // Item works for makani numbers

          navigate("/searchResult", {
            state: {
              dgis: dgiD,
              featureclassId: 2,
              searchValue: entrance.AddressE,
            },
          });

          // You can also trigger any other actions, such as displaying a modal,
          // fetching additional data, or redirecting to another page
          // Example:
          // showEntranceDetailsModal(entrance);
        }
      });
    }
  }, [streetLevel]);

  useEffect(() => {
    if (location.pathname !== "/makani" && mapRef.current) {
      if (buildingsPolygon.length > 0) {
        const combinedPolygon = {
          polygan: [],
        };

        // Iterate through each object and concatenate the polygan arrays
        buildingsPolygon.forEach((item) => {
          combinedPolygon.polygan = combinedPolygon.polygan.concat(
            item.polygan
          );
        });
        const cPolygon = combinedPolygon.polygan.flat();
        const bounds = new window.google.maps.LatLngBounds();
        cPolygon.forEach((point) => {
          bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
        });

        mapRef.current.fitBounds(bounds);
      }
      const width =
        props.sidebarRef.current && props.sidebarRef.current.offsetWidth;
      const height =
        props.sidebarRef.current && props.sidebarRef.current.offsetHeight;
      const screnwidth = window.innerWidth;
      if (screnwidth > 767) {
        if (il8n.language === "en") {
          mapRef.current.panBy(-width / 2, 0);
        } else {
          mapRef.current.panBy(width / 2, 0);
        }
      }
    }
  }, [center, il8n.language]);

  const plotRoute = (route, svgElement, stepKey, onComplete) => {
    const svg = d3.select(svgElement);
    const lineGenerator = d3
      .line()
      .x((d) => d[0])
      .y((d) => d[1]);

    const stepData = route.find((step) => Object.keys(step)[0] === stepKey);

    if (stepData) {
      const stepDetails = Object.values(stepData)[0].stepDetails;
      const routePoints = stepDetails.map((detail) => {
        const [x, y] = detail.svgXY
          .split(" ")
          .map((coord) => parseFloat(coord));
        return [x, y];
      });

      const path = svg
        .append("path")
        .attr("d", lineGenerator(routePoints))
        .attr("stroke", "red")
        .attr("stroke-width", 10) // Updated stroke-width
        .attr("fill", "none")
        .attr("stroke-dasharray", function () {
          return this.getTotalLength();
        })
        .attr("stroke-dashoffset", function () {
          return this.getTotalLength();
        });

      path
        .transition()
        .duration(2000) // Adjust the duration as needed
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0)
        .on("end", onComplete);
    }
  };

  const GetVirtualPlateResult = async (token, { lat, lng }) => {
    try {
      let response = await GetVirtualPlate(token, { lat, lng });

      if (response.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        response = await GetVirtualPlateResult(token, { lat, lng });
      }

      return response;
    } catch (e) {}
  };

  const handleAddToFavorite = (item, value, index) => {
    item.FavName = value;
    const {
      isFavorite: _isFavorite,
      Makani: _Makani,
      distance: _distance,
      ...itemWithoutIsFavorite
    } = item;
    fetchSyncFavorites(
      {
        ...itemWithoutIsFavorite,
        IsMyMakani: false,
        FavType: "LOCATION",
        FavValue: item.Makani,
        Location: item.SHAPE,
        UserId: userId,
      },
      {
        action: "add",
      }
    );
    setAddToFavroite({
      index: index,
      add: true,
    });
    setAddToFavroiteHome({
      index: index,
      add: true,
    });
    setMapFavroiteToggle({
      index: -1,
      add: false,
    });
    setHomeFavoriteToggle({
      index: -1,
      add: false,
    });
  };

  const handleClick = () => {
    setSelectedFavoriteMarker(-1);
    setFavoritePalceInfo({});
    setHomeFavroiteSelect(-1);
  };

  const handleNorthButtonClick = () => {
    if (mapRef.current) {
      mapRef.current.panTo(
        { lat: center.lat, lng: center.lng },
        { heading: 180 }
      );
    }
  };

  const handleDownload = (value) => {
    setMyMakaniCardRef(value);
  };

  //Long press

  const handleMouseDown = (event) => {
    mouseDownTimeRef.current = Date.now();
    startPositionRef.current = { x: event.clientX, y: event.clientY };
    hasMovedRef.current = false;
    let toastId = null;

    const map = mapRef.current;
    const projection = map.getProjection();
    const scale = Math.pow(2, map.getZoom());
    const bounds = map.getBounds();
    const nw = projection.fromLatLngToPoint(bounds.getNorthEast());
    const se = projection.fromLatLngToPoint(bounds.getSouthWest());
    const point = new window.google.maps.Point(
      event.clientX / scale + nw.x,
      event.clientY / scale + nw.y
    );
    const latLng = projection.fromPointToLatLng(point);

    // Start long press timer
    longPressTimeout = setTimeout(() => {
      if (!hasMovedRef.current) {
        toastId = toast.loading(t("loading"), {
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
        setLoadingId(toastId);
      }
    }, LONG_PRESS_DURATION);

    // Handle mouse movement (drag detection)
    const handleMouseMove = (moveEvent) => {
      const diffX = Math.abs(moveEvent.clientX - startPositionRef.current.x);
      const diffY = Math.abs(moveEvent.clientY - startPositionRef.current.y);

      if (diffX > 5 || diffY > 5) {
        hasMovedRef.current = true;
        clearTimeout(longPressTimeout);

        // If the toast is already shown due to long press and drag starts, dismiss it
        if (toastId !== null) {
          toast.dismiss(toastId);
        }
      }
    };

    // Clean up on mouse up
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(longPressTimeout);

      if (toastId !== null) {
        toast.dismiss(toastId);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp, { once: true });
  };

  const handleMouseUp = (event) => {
    toast.dismiss(loadingId);
    mouseUpTimeRef.current = Date.now();
    const pressDuration = mouseUpTimeRef.current - mouseDownTimeRef.current;

    if (pressDuration < LONG_PRESS_THRESHOLD) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseMove = (event) => {
    toast.dismiss(loadingId);
    if (
      startPositionRef.current.x === null ||
      startPositionRef.current.y === null
    ) {
      return;
    }

    const { x, y } = startPositionRef.current;
    const dx = Math.abs(event.clientX - x);
    const dy = Math.abs(event.clientY - y);
    if (dx > MOVEMENT_THRESHOLD || dy > MOVEMENT_THRESHOLD) {
      hasMovedRef.current = true;
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    const mapDiv = mapRef.current.getDiv();
    const map = mapRef.current;
    if (mapRef.current) {
      mapDiv.addEventListener("mouseup", handleMouseUp);
      mapDiv.addEventListener("mousedown", handleMouseDown);
      mapDiv.addEventListener("mousemove", handleMouseMove);

      return () => {
        mapDiv.removeEventListener("mouseup", handleMouseUp);
        mapDiv.removeEventListener("mousedown", handleMouseDown);
        mapDiv.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  useEffect(() => {
    const fetchNearestMakani = async () => {
      try {
        const location = await getUserLocation();
        const locationName = await getLocationName(location.lat, location.lng);

        setCurrentLocationDirection({
          lat: location.lat,
          lng: location.lng,
          BuildingNameEn: locationName.englishName,
          BuildingNameAr: locationName.arabicName,
        });
      } catch (error) {
        setDispalyNearestMakani(false);
        // NOTE:Setting location
        const fallbackLocation = {
          lat: 25.2642691390032,
          lng: 55.3119033649999,
        };
        const fallbackLocationName = await getLocationName(
          fallbackLocation.lat,
          fallbackLocation.lng,
          il8n.language
        );

        setCurrentLocationDirection({
          lat: fallbackLocation.lat,
          lng: fallbackLocation.lng,
          BuildingNameEn: "Dubai",
          BuildingNameAr: "دبي",
        });
      }
    };

    if (mapRef.current) {
      fetchNearestMakani();
    }
  }, [mapRef.current]);

  //end long press

  const fetchData = async (location, token) => {
    let LngLat = `${location.lng} ${location.lat}`;

    try {
      let response = await GetNearestMakani(LngLat, token);

      if (response.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        response = await GetNearestMakani(LngLat, newToken);
      }

      if (response.LngLat) {
        setNeareastMakani({
          lng: Number(response.LngLat.split(" ")[0]),
          lat: Number(response.LngLat.split(" ")[1]),
        });

        setText(response.Makani);
        setCenter({
          lng: Number(response.LngLat.split(" ")[0]),
          lat: Number(response.LngLat.split(" ")[1]),
        });
      }
      return response;
    } catch (e) {
      setCenter({
        lat: 25.2642691390032,
        lng: 55.3119033649999,
      });
    }
  };
  useEffect(() => {
    const fetchNearestMakani = async (lat, lng) => {
      try {
        await fetchData({ lat: lat, lng: lng }, token);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (
      token &&
      currentLocationDirection.lat &&
      currentLocationDirection.lng &&
      disaplyNearestMakani
    ) {
      fetchNearestMakani(
        currentLocationDirection.lat,
        currentLocationDirection.lng
      );
    }
  }, [initialToken, currentLocationDirection]);

  const handleLocatedToCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({
            lat: latitude,
            lng: longitude,
          });
          setCenter({ lat: latitude, lng: longitude });
        },

        (error) => {
          console.error("Error getting user location:", error);
          setAllowAccessLocation(true);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleStreetView = (info) => {
    setStreetLevel({
      lat: info.lat,
      lng: info.lng,
    });
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const debouncedGetBuildingOutlineEntrancePoints = useCallback(
    _.debounce((lat, lng) => {
      GetBuildingOutLineEntrancePoints(token, { lat, lng });
    }, 1000), // Adjust the debounce delay as needed
    [token]
  );

  const throttledGetBuildingOutlineEntrancePoints = useCallback(
    _.throttle((lat, lng) => {
      GetBuildingOutLineEntrancePoints(token, { lat, lng });
    }, 5000), // Adjust the throttle delay as needed
    [token]
  );

  const handleMapClick = async (event) => {
    if (location.pathname !== "/direction") {
      const timeDifference =
        (mouseUpTimeRef.current - mouseDownTimeRef.current) / 1000;

      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      if (timeDifference < 1) {
        toast.dismiss(loadingId);
        debouncedGetBuildingOutlineEntrancePoints(lat, lng);
        setTapPosition({
          lat,
          lng,
        });
      } else {
        toast.dismiss(loadingId);
        setOnPressMarker({
          lat,
          lng,
        });
        LongPressShareLocation(
          token,
          {
            lat,
            lng,
          },
          loadingId
        );
      }
    } else if (location.pathname === "/direction" && selectedDirectionMap) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      let newLocation = [
        ...locations,
        {
          value: t("mappoint"),
          position: {
            lat: lat,
            lng: lng,
          },
        },
      ];

      setLocations(newLocation);
      updateDirection(newLocation);
      setSelectedDirectionMap(false);
    }
  };

  const handleMouseOver = (event) => {
    alert("zzz");
  };

  const handleZoomIn = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      const newZoom = currentZoom + 1;
      setZoom(newZoom);
    }
  };

  const handleZoomOut = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      const newZoom = currentZoom - 1;
      setZoom(newZoom);
    }
  };

  const fetchMarkerPositions = async () => {
    try {
      const positions = await Promise.all(
        waypoints.map(async (waypoint, index) => {
          let markerPosition;

          if (waypoint.location.lat && waypoint.location.lng) {
            markerPosition = {
              lat: waypoint.location.lat,
              lng: waypoint.location.lng,
            };
            return markerPosition;
          }
        })
      );

      setWayPointsMarkers(positions);
    } catch (error) {
      console.error("Error fetching marker positions:", error);
      setWayPointsMarkers([]);
    }
  };

  function getDistanceAndDuration(modeValue) {
    const directionsService = new window.google.maps.DirectionsService();

    const request = {
      origin:
        origin.position?.lat && origin.position?.lng
          ? {
              lat: parseFloat(origin.position.lat),
              lng: parseFloat(origin.position.lng),
            }
          : origin.value,
      destination:
        destination.position?.lat && destination.position?.lng
          ? {
              lat: parseFloat(destination.position.lat),
              lng: parseFloat(destination.position.lng),
            }
          : destination.value,
      waypoints: waypoints,
      travelMode: modeValue,
      provideRouteAlternatives: true,
      avoidTolls: currentAvoidToll,
      // optimizeWaypoints: selectedRoute === 'shortest' ? true : false,
      unitSystem: unitSystem,
      // departureTime: routeType === 'fastest' ? true : false,
    };
    directionsService.route(request, (response, status) => {
      if (response.status === "OK") {
        const route = response.routes[0];
        const legs = route.legs;
        const distance = calculate(legs).distance;
        const duration = calculate(legs).duration;
        if (modeValue === "DRIVING") {
          setDurationOnCar(duration);
          setDistanceOnCar(distance);
        } else if (modeValue === "WALKING") {
          setDurationOnFeet(duration);
          setDistanceOnFeet(distance);
        }
      } else {
        console.error("Directions request failed due to " + status);
      }
    });
  }
  const directionsCallback = (response, mode) => {
    if (response !== null) {
      if (response.status === "OK") {
        const route = response.routes[0];
        const legs = route.legs;

        let routeSummary = {
          via: route.summary,
          distance: route.legs[0].distance.text,
          duration: route.legs[0].duration.text,
          tolls:
            route.legs[0].via_waypoints.length > 0
              ? "This route has tolls."
              : "This route has no tolls.",
          traffic: "Fastest route, the usual traffic",
        };

        fetchMarkerPositions();
        const distance = calculate(legs).distance;
        const duration = calculate(legs).duration;
        if (mode === "DRIVING") {
          setDurationOnCar(duration);
          setDistanceOnCar(distance);
          getDistanceAndDuration("WALKING");
          setDirections(response);
        } else if (mode === "WALKING") {
          setDurationOnFeet(duration);
          setDistanceOnFeet(distance);
          getDistanceAndDuration("DRIVING");
        }
        setDirections(response);
        setStartDirection(false);
        setDirectionLoading(false);
      } else {
        toast.error(t("No_Directions_Found"), {
          autoClose: 2000,
          type: "error",
          toastId: "uniqueId",
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          }, // Add a unique id to prevent duplication issues
        });

        setDurationOnFeet("");
        setDistanceOnFeet("");
        setDurationOnCar("");
        setDistanceOnCar("");
        setStartDirection(false);
        setDirections(null);
        setDirectionLoading(false);

        console.error("Directions request failed due to " + response.status);
      }
    }
  };

  const infoBoxOpts = {
    closeBoxURL: "",
    infoBoxCleargitance: new window.google.maps.Size(50, 50),
    pixelOffset: new window.google.maps.Size(-185, -40),
    alignBottom: true,
  };
  const infoBoxOpts3 = {
    closeBoxURL: "",
    infoBoxCleargitance: new window.google.maps.Size(50, 50),
    pixelOffset: new window.google.maps.Size(-185, -40),
    alignBottom: true,
  };

  const infoBoxOpts2 = {
    closeBoxURL: "",
    pixelOffset: new window.google.maps.Size(-218, -60),
    alignBottom: true,
  };
  const infoBoxFavroite = {
    closeBoxURL: "",
    pixelOffset: new window.google.maps.Size(-218, -300),
    alignBottom: true,
  };

  const infoBoxHomeFavroiteLocation = {
    closeBoxURL: "",
    pixelOffset:
      window.innerWidth <= 500
        ? new window.google.maps.Size(-150, -70)
        : window.innerWidth <= 768
        ? new window.google.maps.Size(-190, -70)
        : window.innerWidth >= 768 && window.innerWidth <= 990
        ? new window.google.maps.Size(-200, -70)
        : new window.google.maps.Size(-215, -70),
    alignBottom: true,
  };
  // BuildingNameE: item.BldgNameEn.trim() || item.searchText || ( item.CommEn && item.StreetE && `${item.CommEn + " , " + item.StreetE + " , " + item.AddressE}`) || item.Makani,
  // BuildingNameA: item.BldgNameAr.trim() || item.searchText || (item.CommAr  && item.StreetA  &&`${item.CommAr +" , " + item.StreetA + " , " +item.AddressA}`)  || item.Makani,

  const handleNavigate = (info, favName) => {
    navigate("/direction", {
      state: {
        BuildingNameE: info.BldgNameEn.trim() || favName,
        BuildingNameA: info.BldgNameAr.trim() || favName,

        position: {
          lat: info.lat,
          lng: info.lng,
        },
      },
    });
  };

  const handleAddToFavrite = (item, value) => {
    item.FavName = value;
    fetchSyncFavorites(
      {
        ...item,
      },
      {
        action: "add",
      }
    );

    setMapFavroiteToggle({});
    // setValue('');
  };

  const handelMakaniSearch = (info) => {
    props.setSearch(il8n.language === "ar" ? info.BldgNameAr : info.BldgNameEn);
    navigate("/");
  };

  const unitSystem =
    measurementUnit === "kilometers"
      ? window.google.maps.UnitSystem.METRIC
      : window.google.maps.UnitSystem.IMPERIAL;

  // useEffect(() => {
  //   if (mapRef.current) {

  //     const map = mapRef.current.state.map;

  //     // Reposition the scale control
  //     map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
  //       map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].pop()
  //     );
  //   }
  // }, []);

  return (
    <>
      <GoogleMap
        className="map_tap"
        ref={mapRef}
        defaultZoom={zoom}
        defaultCenter={{ lat: 25.2642691390032, lng: 55.3119033649999 }}
        center={center}
        zoom={zoom}
        onLoad={onMapLoad}
        onClick={handleMapClick}
        onMousemove={handleMouseOver}
        mapTypeId={
          mapType === "default"
            ? "roadmap"
            : mapType === "satellite"
            ? "hybrid"
            : mapType
        }
        scaleBar={scaleBar}
        options={{
          scaleControl: scaleBar,
          fullscreenControl: false,
          rotateControl: false,
          zoomControl: false,
          mapTypeControl: false,
          clickableIcons: false,

          styles: theme === "dark" ? darkTheme : lightTheme,
          // mapId: theme === 'dark' ? 'da6d01117c811ba4' : '59ca1752ff6c2b',

          // heading: heading,
          //mapId: '100d7e0358ff7c93',
        }}
      >
        <div
          className="map_tap"
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
        >
          <img
            src={clickIcon}
            alt="click"
            style={{
              display: stepIndex === 12 ? "block" : "none",
              width: "90px",
              height: "90px",
            }}
          />
        </div>

        <div
          className="long-pres-desc"
          style={{
            position: "absolute",
            top: "40%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
        >
          <img
            src={clickIcon}
            alt="click"
            style={{
              display: stepIndex === 13 ? "block" : "none",
              width: "90px",
              height: "90px",
            }}
          />
        </div>

        {traffic && <TrafficLayer />}

        {directions &&
          directions.routes.slice(1).map((route, index) => (
            <>
              <DirectionsRenderer
                key={index}
                directions={{
                  ...directions,
                  routes: [route],
                }}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#b8cdca",
                    // strokeColor: "#1c53f8",
                    strokeOpacity: 1,
                    strokeWeight: 13,
                    zIndex: 0,
                  },
                }}
              />
              <DirectionsRenderer
                directions={{
                  ...directions,
                  routes: [route],
                }}
                options={{
                  directions: {
                    ...directions,
                    routes: [route],
                  },
                  suppressMarkers: true,

                  polylineOptions: {
                    // strokeColor: "#BFE3E0",
                    strokeColor: "#1c53f8",
                    strokeOpacity: 0.8,
                    strokeWeight: 7,
                  },
                }}
              />
            </>
          ))}
        {directions && (
          <>
            <DirectionsRenderer
              directions={{
                ...directions,
                routes: [directions.routes[0]],
              }}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#022aa3",
                  strokeOpacity: 1,
                  strokeWeight: 9,
                  zIndex: 1,
                },
              }}
            />
            <DirectionsRenderer
              directions={{
                ...directions,
                routes: [directions.routes[0]],
              }}
              options={{
                suppressMarkers: true,

                polylineOptions: {
                  // strokeColor: "#02CEBE",
                  strokeColor: "#1c53f8",
                  strokeOpacity: 0.8,
                  strokeWeight: 7,
                  zIndex: 1,
                },
              }}
            />
          </>
        )}
        {poi &&
          poi.length > 0 &&
          poi.map((item, index) => {
            return (
              <Marker
                onClick={() => {
                  navigate("/makani");
                  setSelectedPoi(index);
                  setCenter({ lat: item.Lat, lng: item.Lng });
                }}
                position={{ lat: item.Lat, lng: item.Lng }}
                icon={{
                  url: item.image,
                  scaledSize:
                    selectedPoi === index
                      ? new window.google.maps.Size(40, 40)
                      : new window.google.maps.Size(25, 25),
                }}
              />
            );
          })}

        {directions && (
          <>
            <Marker
              position={directions.routes[0].legs[0].start_location}
              icon={{
                url: require("../../assets/images/startMarker.png"),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
            <Marker
              position={
                directions.routes[0].legs[directions.routes[0].legs.length - 1]
                  .end_location
              }
              icon={{
                url: require("../../assets/images/endMarker.png"),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
            {/* Way point marker */}
            {wayPointsMarkers.map((waypoint, index) => (
              <Marker
                key={index}
                position={waypoint}
                icon={{
                  url: require(`../../assets/images/route_icon_${
                    index + 1
                  }.png`),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            ))}
          </>
        )}
        {tapPosition && tapPosition.lat && tapPosition.lng && (
          <Marker
            position={tapPosition}
            icon={{
              url: require("../../assets/images/makaniMarker.png"),
              scaledSize: new window.google.maps.Size(34, 59),
            }}
          />
        )}

        {currentLocation.lat && currentLocation.lng ? (
          <div className="infoBoxLoader">
            <InfoBox
              position={currentLocation}
              options={{ closeBoxURL: "", enableEventPropagation: true }}
            >
              <>
                <CustomLoader />
              </>
            </InfoBox>
          </div>
        ) : (
          <>
            {nearestMakani?.lat && nearestMakani?.lng && (
              <div className="infoBoxLoader">
                <InfoBox
                  position={nearestMakani}
                  options={{ closeBoxURL: "", enableEventPropagation: true }}
                >
                  <>
                    <div
                      className="nearestMakaniPopUp tooltip-wrp"
                      onClick={() => {
                        setCopied();
                      }}
                    >
                      <p className="title">Nearest Makani No.</p>
                      <div className="nearestMakaniNumberWraper">
                        <p className="makaniNumber">{text}</p>
                        {isCopied ? (
                          <span className="coped-align">
                            <span class="copied">{t("copied")}</span>
                            <img
                              className="copy__btn copiedbtn"
                              src={checked}
                              alt="copy"
                            />
                          </span>
                        ) : (
                          <img
                            width="14"
                            height="14"
                            className="copy__btn"
                            src={copyIcon}
                            alt="copy"
                          />
                        )}
                      </div>
                    </div>

                    <CustomLoader />
                  </>
                </InfoBox>
              </div>
            )}
          </>
        )}
        {startDirection &&
          ((origin.position?.lat && origin.position?.lng) || origin.value) &&
          ((destination.position?.lat && destination.position?.lng) ||
            destination.value) && (
            <DirectionsService
              options={{
                origin:
                  origin.position?.lat && origin.position?.lng
                    ? {
                        lat: parseFloat(origin.position.lat),
                        lng: parseFloat(origin.position.lng),
                      }
                    : origin.value,
                destination:
                  destination.position?.lat && destination.position?.lng
                    ? {
                        lat: parseFloat(destination.position.lat),
                        lng: parseFloat(destination.position.lng),
                      }
                    : destination.value,
                provideRouteAlternatives: true,
                waypoints: waypoints,
                travelMode: directionMode,
                avoidTolls: currentAvoidToll,
                //  optimizeWaypoints: selectedRoute === 'shortest' ? true : false,
                unitSystem: unitSystem,

                // departureTime: routeType === 'fastest' ? true : false,
              }}
              callback={(response) => {
                directionsCallback(response, directionMode);
              }}
            />
          )}
        {displayMarker &&
          favoritesMarker.length > 0 &&
          favoritesMarker.map((item, index) => {
            return (
              <Marker
                onClick={() => {
                  setSelectedFavoriteMarker(index);
                  setCenter({ lat: item.lat, lng: item.lng });
                  fetchSingleFavoritePlace(2, item.makani);
                }}
                zIndex={selectedFavoriteMarker === index ? 999 : 0}
                position={{ lat: item.lat, lng: item.lng }}
                icon={{
                  url:
                    selectedFavoriteMarker === index
                      ? require("../../assets/images/selectedMarkerFavorite.png")
                      : require("../../assets/images/favoriteMarker.png"),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              ></Marker>
            );
          })}

        {displayMarker &&
          favoritesMarker.length > 0 &&
          selectedFavoriteMarker > -1 &&
          FavLocations.length > 0 && (
            <InfoBox
              options={infoBoxHomeFavroiteLocation}
              position={{
                lat: parseFloat(
                  FavLocations[selectedFavoriteMarker]?.Location.split(",")[0]
                ),
                lng: parseFloat(
                  FavLocations[selectedFavoriteMarker]?.Location.split(",")[1]
                ),
              }}
            >
              <FavoriteLocationPopup
                handelMakaniSearch={handelMakaniSearch}
                info={favoritePlaceInfo}
                localInfo={FavLocations[homeFavroiteSelect]}
                language={il8n.language}
                loading={selectedFavoriteLoading}
                handleClick={() => {
                  handleClick();
                }}
                handleNavigate={handleNavigate}
                handleStreetView={handleStreetView}
                setShare={props.setShare}
              />
            </InfoBox>
          )}
        {location.pathname === "/" &&
          homeFavroiteSelect > -1 &&
          FavLocations.length > 0 && (
            <Marker
              icon={{
                url: require("../../assets/images/selectedMarkerFavorite.png"),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              position={{
                lat: parseFloat(
                  FavLocations[homeFavroiteSelect]?.Location?.split(",")[0]
                ),
                lng: parseFloat(
                  FavLocations[homeFavroiteSelect].Location.split(",")[1]
                ),
              }}
            >
              <InfoBox
                options={infoBoxHomeFavroiteLocation}
                position={{
                  lat: parseFloat(
                    FavLocations[homeFavroiteSelect]?.Location?.split(",")[0]
                  ),
                  lng: parseFloat(
                    FavLocations[homeFavroiteSelect].Location.split(",")[1]
                  ),
                }}
              >
                <FavoriteLocationPopup
                  handelMakaniSearch={handelMakaniSearch}
                  info={favoritePlaceInfo}
                  localInfo={FavLocations[homeFavroiteSelect]}
                  language={il8n.language}
                  loading={selectedFavoriteLoading}
                  handleClick={() => {
                    handleClick();
                  }}
                  handleNavigate={handleNavigate}
                  handleStreetView={handleStreetView}
                  setShare={props.setShare}
                />
              </InfoBox>
            </Marker>
          )}

        {location.pathname === "/" && homeSelectedMakani > -1 && (
          <Marker
            position={{
              lat: parseFloat(
                myMakani[homeSelectedMakani].Location.split(",")[0]
              ),
              lng: parseFloat(
                myMakani[homeSelectedMakani].Location.split(",")[1]
              ),
            }}
            icon={{
              url: require("../../assets/images/selectedMarkerFavorite.png"),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          >
            <InfoBox
              options={infoBoxOpts3}
              calssName="infoBox2"
              style={{
                width: "362.53px!important",
              }}
            >
              <MakaniCardViewOnMap
                item={{
                  IsMyMakani: myMakani[homeSelectedMakani].IsMyMakani,
                  LastUpdated: myMakani[homeSelectedMakani].LastUpdated,
                  BuildingNameE: myMakani[homeSelectedMakani].BuildingNameE,
                  BuildingNameA: myMakani[homeSelectedMakani].BuildingNameA,
                  SHAPE: myMakani[homeSelectedMakani].Location,
                  Makani: myMakani[homeSelectedMakani].FavValue,
                  FavType: myMakani[homeSelectedMakani].FavType,
                  distance: myMakani[homeSelectedMakani].distance,
                  isFavorite: myMakani[homeSelectedMakani].isFavorite,
                  FavName: myMakani[homeSelectedMakani].FavName,
                }}
                style={{
                  display: "block",
                }}
                handleAddToFavorite={handleAddToFavorite}
                favoriteToggle={homeFavoriteToggle}
                index={homeSelectedMakani}
                fromHome={true}
                onFavoriteClick={() => {
                  setHomeFavoriteToggle({
                    index: homeSelectedMakani,
                    isFavorite: true,
                  });
                }}
                onFavoriteCancelClick={() => {
                  setHomeFavoriteToggle({
                    isFavorite: false,
                    index: -1,
                  });
                }}
                addToFavrite={addToFavriteHome}
                theme={theme}
                setStreetLevel={setStreetLevel}
                setStartDirection={setStartDirection}
                navigate={navigate}
                setShare={props.setShare}
                handleDownload={handleDownload}
              />
            </InfoBox>
          </Marker>
        )}

        {disaplayMakaniMarker &&
          MakaniMarker.length > 0 &&
          MakaniMarker.map((item, index) => {
            return (
              <Marker
                onClick={() => setSelectedMakaniMarker(index)}
                position={{ lat: item?.lat, lng: item?.lng }}
                zIndex={selectedMakaniMarker === index ? 999 : 0}
                icon={{
                  url:
                    selectedMakaniMarker === index
                      ? require("../../assets/images/selectedMarkerFavorite.png")
                      : require("../../assets/images/favoriteMarker.png"),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              >
                {selectedMakaniMarker === index && (
                  <InfoBox
                    options={infoBoxOpts}
                    calssName="infoBox2"
                    style={{
                      width: "362.53px!important",
                    }}
                  >
                    <MakaniCardViewOnMap
                      item={{
                        IsMyMakani: myMakani[index].IsMyMakani,
                        LastUpdated: myMakani[index].LastUpdated,
                        BuildingNameE: myMakani[index].BuildingNameE,
                        BuildingNameA: myMakani[index].BuildingNameA,
                        SHAPE: myMakani[index].Location,
                        Makani: myMakani[index].FavValue,
                        FavType: myMakani[index].FavType,
                        distance: myMakani[index].distance,
                        isFavorite: myMakani[index].isFavorite,
                        FavName: myMakani[index].FavName,
                      }}
                      index={index}
                      favoriteToggle={mapFavoriteToggle}
                      onFavoriteClick={() => {
                        setMapFavroiteToggle({
                          isFavorite: true,
                          index: index,
                        });
                      }}
                      onFavoriteCancelClick={() => {
                        setMapFavroiteToggle({
                          isFavorite: false,
                          index: -1,
                        });
                      }}
                      style={{
                        display: "block",
                      }}
                      handleAddToFavorite={handleAddToFavorite}
                      theme={theme}
                      setStreetLevel={setStreetLevel}
                      setStartDirection={setStartDirection}
                      navigate={navigate}
                      addToFavrite={addToFavrite}
                      setShare={props.setShare}
                      handleDownload={handleDownload}
                    />
                  </InfoBox>
                )}
              </Marker>
            );
          })}

        {longPressPosition?.lat && longPressPosition?.lng && (
          <Marker
            position={{
              lat: parseFloat(longPressPosition.lat),
              lng: parseFloat(longPressPosition.lng),
            }}
            icon={{
              url: require("../../assets/images/makaniMarker.png"),
              scaledSize: new window.google.maps.Size(34, 59),
            }}
            onClick={() => setShowLongPress(true)}
          >
            {showLongPress && (
              <InfoWindow
                position={{
                  lat: parseFloat(longPressPosition.lat),
                  lng: parseFloat(longPressPosition.lng),
                }}
              >
                <div className="InfoWindowLongPress">
                  <div className="InfoWindowLongPressHeader">
                    <p className="coummityName">{t("community_Name")}</p>
                    <button
                      className="close_btn"
                      style={{
                        marginRight: il8n.language === "ar" ? "auto" : "unset",
                        marginLeft: il8n.language === "en" ? "auto" : "unset",
                      }}
                      onClick={() => {
                        setShowLongPress(false);
                      }}
                    >
                      <img src={makaniCloseIcon} alt="close button" />
                    </button>
                  </div>

                  <p
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {il8n.language === "ar"
                      ? longPressPosition.CommunityA
                      : longPressPosition.CommunityE}
                  </p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        )}
        {onPressMarker?.lat && onPressMarker?.lng && (
          <Marker
            position={onPressMarker}
            icon={{
              url: require("../../assets/images/makaniMarker.png"),
              scaledSize: new window.google.maps.Size(34, 59),
            }}
          />
        )}

        {makaniCoords?.map((item, index) => {
          return (
            <Marker
              onClick={() => {
                props.sliderRef.current.slickGoTo(index);
              }}
              style={{
                width: "23px",
                height: "41px",
              }}
              // require("../../assets/images/emergencyselected.png")
              position={{ lat: item.lat, lng: item.lng }}
              icon={{
                url:
                  item.entType === "Emergency"
                    ? props.currentSlide === index
                      ? require("../../assets/images/emergency.png")
                      : require("../../assets/images/emergency.png")
                    : item.entType === "Wheelchair"
                    ? props.currentSlide === index
                      ? require("../../assets/images/wheelchairselected.png")
                      : require("../../assets/images/wheelchair.png")
                    : props.currentSlide === index
                    ? require("../../assets/images/selectedMarker.png")
                    : require("../../assets/images/makaniMarker.png"),
                scaledSize: new window.google.maps.Size(34, 59), // NOTE: Used to change the height of the marker
              }}
            />
          );
        })}
        {buildingsPolygon &&
          buildingsPolygon.length > 0 &&
          buildingsPolygon.map((item) => {
            return (
              <Polygon
                paths={item.polygan}
                options={{
                  strokeColor: item.color || "#0b0d8c",
                  fillColor: "#EBEBEB59",
                  strokeWeight: 2,
                }}
              />
            );
          })}
      </GoogleMap>
      {location.pathname === "/" && (
        <div
          onClick={() => {
            navigate("/direction");
            setMenuToggle(false);
          }}
          className={`start_nav ${
            il8n.language === "ar" ? "directionMap-ar" : "directionMap"
          }`}
          style={{
            cursor: "pointer",
          }}
        >
          <img src={directionMap} alt="directionMap" width="30" height="34" />
        </div>
      )}
      {streetLevel.lat && streetLevel.lng && (
        <div className="streetView" ref={streetViewRef}>
          <div
            style={{
              zIndex: "1000",
            }}
          >
            <img
              src={makaniCloseIcon}
              style={{
                position: "absolute",
                right: "20px",
                top: "60px",
                cursor: "pointer",
              }}
              alt="close"
              onClick={() => {
                setStreetLevel({});
              }}
            />
          </div>
        </div>
      )}
      {/* <div className={il8n.language === 'ar' ? 'weather-ar' : 'weather'}>
        <Cloud />
      </div> */}
      {zoomInBtn && (
        <div
          className={
            il8n.language === "ar" ? "zoom-controls-ar" : "zoom-controls"
          }
        >
          <AccessibleButton
            aria-labelledby="'zoom-in "
            className="zoom-button zoom_in_desc"
            onClick={handleZoomIn}
            ariaLabel="Click here to zoom in"
          >
            <MapPlus />
          </AccessibleButton>
          <AccessibleButton
            aria-labelledby="'zoom-out "
            className="zoom-button zoom_out_desc"
            onClick={handleZoomOut}
            ariaLabel="Click here to zoom out"
          >
            <Mapminus />
          </AccessibleButton>
        </div>
      )}
      <AccessibleButton
        className={`Map_layer_desc ${
          il8n.language === "ar" ? "map-layer-ar" : "map-layer"
        }`}
        onClick={() => {
          setMapLayerToggle(true);
          const languageName = getFullLanguageName(i18n.language);

          logEvent("Map Select", "Map Click", languageName);
        }}
        ariaLabel={t("click here for map layer")}
      >
        <MapLayer />
      </AccessibleButton>

      <div
        className={`geo_tagged_desc ${
          il8n.language === "ar" ? "current-controls-ar" : "current-controls"
        }`}
      >
        {/* <button
          className='current-location-button nort-btn'
          onClick={() => handleNorthButtonClick()}
        >
          <NorthLocation />
        </button> */}
        <AccessibleButton
          ariaLabel={t("click here for geolocation")}
          className="current-location-button Current_location_desc zoom-btn"
          onClick={() => {
            handleLocatedToCurrentLocation();
            const languageName = getFullLanguageName(i18n.language); // Call the original function
            logEvent(
              "Current Location",
              "Current Location Click",
              languageName
            ); // Log the event to Google Analytics
          }}
        >
          <ZoomIn />
        </AccessibleButton>
      </div>
    </>
  );
});

export default withTour(MyMapComponent, "map");

//12:43
