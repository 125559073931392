import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ModalCloseBtn } from "../../assets/svg/svgIcons";
import {
  useSmartSearchFetching,
  useLongPressFetching,
  UseRemoveMapShape,
} from "../../customHooks/index.js";

import {
  SearchSuggestions,
  RecentSearch,
  MainNavBar,
} from "../../component/index.js";
import "../../scss/common.scss";
import "../../scss/home.scss";
import withTour from "../../withTour";
import { useLocation } from "react-router-dom";

import { UserIcon, SearchIcon} from "../../assets/svg/svgIcons";
import { useAppearance } from "../../context/AppearanceContext.js";
import { useFavorites } from "../../context/FavouritesContext.js";
import { useDraggableContext } from "../../context/DraggableContext.js";
import { usePOICategories } from "../../context/POICategoriesContext";
import { useMapControl } from "../../context/MapControllContext.js";
import AccessibleButton from "../../component/Accessibility/accessibility.jsx";
import { useLanguage } from "../../context/LanguageContext";
import { useAuth } from "../../context/auth.js";
import { useToggle } from "../../context/ToggleModel.js";
import { MOBILE_VIEW_DRAGGER_HEIGHT } from "../../helper/appConstants.js";
import { convertToEasternArabicNumerals } from "../../helper/index.js";
import { logEvent } from "../../analytics.js";

const Home = ({
  setDisplaySuggestions,
  displaySuggestions,
  handleTouchMove,
  handleTouchStart,
  setShare,
  search,
  setSearch,
}) => {
  const { theme } = useAppearance();
  const { distanceDragged } = useDraggableContext();
  const [isMobileView, setIsMobileView] = useState(false);
  //not using the existing mobile view logic, as it is not working as expected
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const searchRef = useRef(null);
  const searchRef2 = useRef(null);
  const { setDisplayMarker } = useFavorites();
  const { setCenter, setZoom, setDirections, setLongPressPosition } =
    useMapControl();
  const [t] = useTranslation();
  const { setPoi, setSelectedPoi } = usePOICategories();
  UseRemoveMapShape();
  const [inputValue, setInputValue] = useState(search);

  const { handleLangChange } = useLanguage();
  const { initialToken } = useAuth();
  const { setCollapsibe } = useToggle();
  const isFirstSearch = useRef(true);

  let {
    suggestions,
    displayRecentSearch,
    loading,
    handleInputFocus,
    handleSmartSearch,
    setDisplayRecentSearch,
  } = useSmartSearchFetching(setDisplaySuggestions, setSearch, search);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const { LongPressGetLocation } = useLongPressFetching();
  const location = useLocation();
  const debounceTimeout = useRef(null);
  const { i18n } = useTranslation();
  const getFullLanguageName = (code) => {
    const languageMap = {
      en: "English",
      ar: "Arabic",
    };
    return languageMap[code] || "Unknown";
  };

  useEffect(() => {
    setCollapsibe(-1);
  }, []);

  useEffect(() => {
    setDisplayMarker(false);
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setDisplayRecentSearch(false);
      }
      if (searchRef2.current && !searchRef2.current.contains(event.target)) {
        setDisplaySuggestions(false);
      }
      setIsSearchFocused(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const change = (lang) => {
    const direction = lang === "ar" ? "rtl" : "ltr";
    handleLangChange(direction, lang);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const seqNo = params.get("sn");
    const language = params.get("l");
    const fetchData = async () => {
      if (language && language === "E") {
        change("en");
      } else if (language && language === "A") {
        change("ar");
      }

      const result = await LongPressGetLocation(seqNo);
      setLongPressPosition(result);
      setCenter({
        lat: parseFloat(result.lat),
        lng: parseFloat(result.lng),
      });
    };

    if (seqNo && language) {
      fetchData();
    }
  }, [initialToken]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const makainNumber = params.get("m");
    const language = params.get("l");

    if (makainNumber && language) {
      if (language && language === "E") {
        change("en");
      } else if (language && language === "A") {
        change("ar");
      }
      navigate("/searchResult", {
        state: {
          dgis: makainNumber,
          featureclassId: 2,
        },
      });
    }
  }, [initialToken]);
  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      isMobileScreen &&
      targetRef &&
      targetRef.current &&
      targetRef.current.style
    ) {
      targetRef.current.style.setProperty(
        "padding-top",
        `${MOBILE_VIEW_DRAGGER_HEIGHT}px`,
        "important"
      );
    }
  }, [isMobileScreen]);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const targetRect = targetRef.current.getBoundingClientRect();
        const middleOfPage = window.innerHeight / 2;

        if (
          targetRect.top <= middleOfPage &&
          targetRect.bottom >= middleOfPage &&
          window.innerWidth <= 1100
        ) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };
    window.removeEventListener("resize", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    if (search && search !== inputValue) {
      // setInputValue(search);
      // if(isFirstSearch.current){
        setInputValue(search);
        if (search.includes("&")) {
          let value = search.replace(/&/g, "");
          handleSmartSearch(value);
        } else {
          handleSmartSearch(search);
        }
      // }        
      
      setDisplaySuggestions(true);
      setDisplayRecentSearch(false);
    }
  }, [search]);

  const handleOnInputChange = (e) => {
      // setOnInputChange(true);
      if (i18n.language === "ar") {
        const inputValue = e.target.value;
        setInputValue(convertToEasternArabicNumerals(inputValue));
      } else {
        setInputValue(e.target.value);
      }

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      if (e.target.value.length <= 3) {
        setDisplayRecentSearch(true);
        setDisplaySuggestions(false);
      } else {
        setDisplayRecentSearch(false);
        setDisplaySuggestions(true);
        if (isFirstSearch.current) {
          handleSmartSearch(e.target.value);
          isFirstSearch.current = false;
        } else {
          debounceTimeout.current = setTimeout(() => {
            handleSmartSearch(e.target.value);
          }, 1500);
        }
      }
  }
  return (
    <div
      handleTouchMove={(e) => handleTouchMove(e)}
      handleTouchStart={(e) => handleTouchStart(e)}
      className={`sideBarContent  swipe-line top-spacing ${
        isFixed ? "fixed" : ""
      }`}
      ref={targetRef}
    >
      <div className="searchContainer ">
        <div
          className="searchInputWrapper search_des"
          style={{
            boxShadow:
              (displayRecentSearch || displaySuggestions) &&
              "box-shadow: 0px 0px 0px 1.3px rgba(51, 60, 79, 0);",
          }}
        >
          <AccessibleButton
            className="searchButton"
            ariaLabel={t(
              "Search here using Makani number, places, and full plot number"
            )}
            onClick={() => {
              const languageName = getFullLanguageName(i18n.language);
              logEvent("Smart Search", "Search click", languageName);
            }}
          >
            <SearchIcon />
          </AccessibleButton>

          <input
            value={inputValue}
            onFocus={(e) => {
              handleInputFocus(e);
              setIsSearchFocused(true);
            }}
            onChange={handleOnInputChange}
            placeholder={t("makaniPlaceholder")}
            className="searchInput"
          />

          {search && (
            <AccessibleButton
              className="clear-search"
              onClick={() => {
                setSearch("");
                setInputValue(""); //NOTE
              }}
              ariaLabel={t("click here to Clear Search")}
            >
              <ModalCloseBtn />
            </AccessibleButton>
          )}
        </div>

        <AccessibleButton ariaLabel={t("Click here for profile")}>
          <div className="profile SignIn" onClick={() => navigate("/profile")}>
            <UserIcon />
          </div>
        </AccessibleButton>
        {/* new change */}
        {/* <AccessibleButton
            className="searchButton"
            ariaLabel={t(
              "Search here using Makani number, places, and full plot number"
            )}
            onClick={() => {
              const languageName = getFullLanguageName(i18n.language);
              logEvent("Smart Search", "Search click", languageName);
            }}
          >
            <div className="profile SignIn"><SearchIcon /></div>
            
          </AccessibleButton> */}
          {/* change ends here */}
      </div>

      <div
        className="sideBarContentChild"
        style={{
          display: displayRecentSearch || displaySuggestions ? "none" : "block",
          marginTop: "",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
        }}
      >
        <MainNavBar
          theme={theme}
          setShare={setShare}
          setSearch={setSearch}
          handleSmartSearch={handleSmartSearch}
        />
      </div>
      {displayRecentSearch && (
        <div
          className="suggestionWarper sideBarContentChild recentSearchWarper search-result-cover"
          ref={searchRef}
          style={{
            padding: "",
            borderRadius: "10px",
            height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
          }}
        >
          {displayRecentSearch && <RecentSearch setSearch={setSearch} />}
        </div>
      )}
      <div
        ref={searchRef2}
        className="suggestionWarper sideBarContentChild"
        style={{
          padding: "",
          borderRadius: "10px",
          display: displaySuggestions ? "block" : "none",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
          overflowY: isMobileScreen ? "auto" : "scroll",
        }}
      >
        <SearchSuggestions
          suggestions={suggestions}
          loading={loading}
          setDisplaySuggestions={setDisplaySuggestions}
          searchValue={search}
          setShare={setShare}
          setSearch={setSearch}
        />
      </div>
      {/* {(displayRecentSearch || displaySuggestions )&& 
      <div class="copy_right">Copyright © 2024 Dubai Municipality, all rights reserved.</div>} */}
      <div class="copy_right top-spacing">{t("Copyright")}</div>
    </div>
  );
};

export default withTour(Home, "app");
