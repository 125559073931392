
const loadSvg = async (url) => {
    const response = await fetch(url);
    const data = await response.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(data, 'image/svg+xml');
    return svgDoc.querySelector('svg');
  };


  export default loadSvg;