const convertToEasternArabicNumerals = (input) => {
    const westernToEasternMap = {
      '0': '٠',
      '1': '١',
      '2': '٢',
      '3': '٣',
      '4': '٤',
      '5': '٥',
      '6': '٦',
      '7': '٧',
      '8': '٨',
      '9': '٩',
    };
  
    return input.split('').map(char => westernToEasternMap[char] || char).join('');
  };
  const convertToWesternArabicNumerals = (input) => {
    const easternToWesternMap = {
      '٠': '0',
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9',
    };
  
    return input.split('').map(char => easternToWesternMap[char] || char).join('');
  };
  export {
    convertToEasternArabicNumerals,
    convertToWesternArabicNumerals
  }

