import { useEffect, useState } from "react";
import { makaniCardLogo, makaniCardLogoDark } from "../../assets/index.js";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";

import {
  DownLoadIcon,
  EditIcon,
  DeleteIcon,
  ShareIcon,
} from "../../assets/svg/svgIcons.jsx";
import { useAppearance } from "../../context/AppearanceContext.js";
import { useRef } from "react";

import { useFavorites } from "../../context/FavouritesContext";
import EditFavoritesModel from "../models/editFavoritesModel/index.jsx";
import { useMapControl } from "../../context/MapControllContext.js";
import DeleteMakaniCard from "../deleteMakaniCard/index.jsx";
import { SHARE_URL_BASE } from "../../helper/api/constants.ts";

const MyMakaniCards = ({ title, details, makaniNumber, index, location }) => {
  const [t, il8n] = useTranslation();
  const cardRef = useRef(null);

  const { theme } = useAppearance();

  const {
    setEditFavoritesToggle,
    editFavoritesToggle,
    setSelectedMakaniMarker,
  } = useFavorites();
  const {
    setCenter,
    setZoom,
    setShareFromMakaniCard,
    myMakaniCardRef,
    setMyMakaniCardRef,
  } = useMapControl();

  const [DeleteMakaniToggle, setDeleteMakaniToggle] = useState({
    item: {},
    isDeleteMakani: false,
    index: -1,
  });

  useEffect(() => {
    setCenter({
      lat: 25.271769414141,
      lng: 55.3129336687579,
    });
    setZoom(12);
    setSelectedMakaniMarker(-1);
    if (cardRef.current) {
    }
    // to set edit model off when closed and open
    setEditFavoritesToggle({
      isEditFavoritesToggle: false,
      index: "",
      item: {},
    })
  }, []);

  useEffect(() => {
    if (myMakaniCardRef.value) {
      if (myMakaniCardRef.makaniId === makaniNumber) {
        handleDownload(makaniNumber);
        setMyMakaniCardRef({
          makaniId: myMakaniCardRef.makaniId,
          value: false,
        });
      }
    }
  }, [myMakaniCardRef, makaniNumber]);

  const handleEdit = (item) => {
    setEditFavoritesToggle({
      isEditFavoritesToggle: true,
      index: index,
      item,
    });
  };

  const handleDownload = async (makaniNumber) => {
    if (cardRef.current === null) {
      return;
    }
    if (cardRef && cardRef.current) {

    try {
      const dataUrl = await toPng(cardRef.current);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${makaniNumber}.png`;
      link.click();
    } catch (error) {
      console.error('Failed to download image:', error);
    }
  }
  };

   // FOR enabling the shareModel popup
   const shareMakaniDetails = () => {
    const url = `${SHARE_URL_BASE}?l=${
      il8n.language === "ar" ? "A" : "E"
    }&m=${makaniNumber.replace(/\s+/g, "")}`;
    setShareFromMakaniCard({
      value: url,
      isShare: true,
    });
  };


  // url=`${SHARE_URL_BASE}?l=${
  //   il8n.language === "ar" ? "A" : "E"
  // }&m=${item.Makani.replace(/\s+/g, "")}`
  function reverseAndConcat(input) {
    return input.replace(/ /g, '\u00A0')
  }

  

  return (
    <div className="myMakaniCard">
      {!(
        editFavoritesToggle.isEditFavoritesToggle &&
        editFavoritesToggle.index === index
      ) ? (
        DeleteMakaniToggle.isDeleteMakani && DeleteMakaniToggle.index ===index ? <DeleteMakaniCard
        setDeleteMakaniToggle={setDeleteMakaniToggle}
          item={{ FavValue: makaniNumber,
              FavName: title,
              FavType: 'MAKANI'}} 
              title={t('Delete_Makani_Card')}
              description={t('Delete_Makani_Description')}
        />:
        <div
          onClick={() => {
            setSelectedMakaniMarker(index);

            setCenter({
              lat: parseFloat(location.split(',')[0]) ,
              lng:parseFloat(location.split(',')[1])
            });
          }}
          className='myMakaniCardHeader'
          ref={cardRef}
          style={{
            flexDirection: il8n.language === 'ar' && 'row-reverse',
          }}
        >
          <div className='myMakaniCardContent'>
            <div>
              <img
                src={theme === 'dark' ? makaniCardLogoDark : makaniCardLogo}
                alt='makani Logo'
              />
            </div>
            <div className='myMakaniCardContentDetails'>
              <p className='title'>{(title === makaniNumber) && il8n.language === 'ar' ? reverseAndConcat(title) : title} </p>
              {/* style={{direction: `${il8n.language === 'ar' ? 'rtl' : 'ltr'}`}} */}
              <p className='makaniNumber'>{il8n.language === 'ar' ? reverseAndConcat(makaniNumber) : makaniNumber}</p>
              <p className='details'>{details} </p>
            </div>
          </div>
          <div className='CardQrWrapper'>
            <div className='qrCodeImage'>
              <QRCode
                value={makaniNumber && `${SHARE_URL_BASE}?l=${
                  il8n.language === "ar" ? "A" : "E"
                }&m=${makaniNumber.replace(/\s+/g, "")}`}
                style={{
                  width: '58.72px',
                  height: '58.72px',
                }}
                bgColor={theme === 'dark' ? '#FFFFFF' : '#D1D1D1'}
              />
            </div>

            <p className=''>makani.ae</p>
          </div>
        </div>
      ) : (
        <EditFavoritesModel />
      )}

      <div className="myMakaniCardBtns">
        {/* <div>
          <p>
            <span className="icons-sm top-spc">
              <DownLoadIcon />
            </span>{" "}
            {t("download")}
          </p>
        </div> */}
        <div
          onClick={() =>
            handleEdit({
              FavValue: makaniNumber,
              FavName: title,
              FavType: "MAKANI",
            })
          }
        >
          <p>
            <span className="icons-sm">
              {" "}
              <EditIcon />
            </span>{" "}
            {t("edit")}
          </p>
        </div>
        <div onClick={shareMakaniDetails}>
          <p>
            <span className="icons-sm">
              {" "}
              <ShareIcon />
            </span>{" "}
            {t("share")}
          </p>
          
        </div>
        <div
          onClick={() => {
            setDeleteMakaniToggle({
              item: {
                FavValue: makaniNumber,
                FavName: title,
                FavType: "MAKANI",
              },
              index,
              isDeleteMakani: true,
            });
          }}
        >
          <p>
            <span className="icons-sm">
              {" "}
              <DeleteIcon />{" "}
            </span>{" "}
            {t("delete")}
          </p>
        </div>
      </div>
    </div>
  );
};
export default MyMakaniCards;
