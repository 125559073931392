import React, { useState,useRef,useEffect } from 'react';

import { modelClose } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import { useFavorites,useAuth } from '../../../context/index.js';
import '../AddFavoritesModel/style.css'


const AddRouteModel = ({distance, locations,setAddRouteFavroite,setAddSuccessfult,value,setValue}) => {
  const inputRef = useRef(null)
  const {fetchSyncFavorites}=useFavorites()
  const { userId} = useAuth();
  const [t] = useTranslation();

  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  function convertDistanceToMeters(distanceStr) {

    if(distanceStr.trim() =="0.00"){
      return 0  

    }else{
      const regex = /([\d.]+)\s*(km|mi|كم|ميل)/i;
      const match = distanceStr.match(regex);
      const distance = parseFloat(match[1]);
      const unit = match[2].toLowerCase();
    
      if (unit === "km" || unit==='كم') {
        return distance * 1000;
        
      } else if (unit === "mi" || unit ==="ميل") {
        return distance * 1609.34;
      }
    }

  }
  
  const handleAddFavorite=(distance,locations)=>{
    let items=[]
    locations.forEach((item,index)=>{

    let i=   {
      "IsMyMakani": false,
      "FavName": `${value}_route_${index + 1}`,
      "FavType": "ROUTE_LOCATION",
      "FavValue": `${convertDistanceToMeters(distance)}`,
      "UserId":userId,
      "BuildingNameE":item.BuildingNameEn,
      "BuildingNameA":item.BuildingNameA,
      "Location": `${item.position.lat},${item.position.lng}`,
      "LastUpdated":  new Date().getTime()
    }


    items.push(i)
  })
    fetchSyncFavorites(
    items,
      {
        action: 'add',
        routeLocation:true
      }
    );
    setAddSuccessfult(true)
    setAddRouteFavroite(false)

  
      }
    

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleAddFavorite(distance,locations)
        }
      };
    
    

  return (
    <div className='AddFavoriteModel side-fav' style={{
      height:"170px"
    }}>
      <div className='AddFavoriteModelHeader'>
        <p>
      {t('routeName')}
        </p>
        <div
          className='closeFavoriteWarper'
        onClick={()=>setAddRouteFavroite(false)}
        >
          <img src={modelClose} alt='close' />
        </div>
      </div>
      <div className='AddFavoriteContent'>
        <div className='input-cover'>
          <input
          ref={inputRef}
            className='AddInput'
            placeholder={t('Name')}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div
          className='addBtn'
          onClick={() =>{
            handleAddFavorite(distance,locations)
          }}
        >
          <p>{t('save')}</p>
        </div>
      </div>
    </div>
  );
};

export default AddRouteModel;


