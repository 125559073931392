import React from "react";
import { useTranslation } from "react-i18next";

const TourSteps = () => {
  const [t, il8n] = useTranslation();
  const tourSteps = {
    app: [
      {
        title: t("search"),
        target: ".search_des",
        content: <div>{t("search-journey")}</div>,
      },
      {
        target: ".SignIn",
        title: t("signIn"),
        content: t("signIn-journey"),
      },
      {
        title: t("findMyLandNumber"),
        target: ".Enter_land_number_desc",
        content: t("findMyLandNumber-journey"),
      },
      {
        title: t("findMyBuilding"),
        target: ".search_by_community_desc",
        content: <div>{t("findMyBuilding-journey")}</div>,
      },
      {
        target: ".scan_qr_desc",
        content: t("scanQRCode-journey"),
        title: t("scanQRcode"),
      },
      {
        target: ".main_menu_desc",
        title: t("mainMenu"),
        content: (
          <div dangerouslySetInnerHTML={{ __html: t("mainMenu-journey") }} />
        ),
      },
      {
        target: ".search_poi_desc",
        title: t("searchByPointOfInterest"),
        content: t("searchByPOI-journey"),
      },
      {
        title: t("zoomIn"),
        target: ".zoom_in_desc",
        content: t("zoomIn-journey"),
      },
      {
        title: t("zoomOut"),
        target: ".zoom_out_desc",
        content: t("zoomOut-journey"),
      },
      {
        title: t("mapLayer"),
        target: ".Map_layer_desc",
        content: (
          <div
            dangerouslySetInnerHTML={{ __html: t("mapLayer-journey") }}
            style={{ textAlign: "left" }}
          />
        ),
      },
      {
        title: t("currentLocation"),
        target: ".Current_location_desc",
        content: t("currentLocation-journey"),
      },
      {
        target: ".start_nav",
        title: t("navigation"),
        content: t("navigation-journey"),
      },
      {
        target: ".map_tap",
        title: t("mapTap"),
        content: t("mapTap-journey"),
      },
      {
        target: ".long-pres-desc",
        title: t("mapLongPress"),
        content: t("mapLongPress-journey"),
      },
      {
        target: ".setting_desc",
        title: t("settings"),
        content: (
          <div
            dangerouslySetInnerHTML={{ __html: t("settings-journey") }}
            style={{ textAlign: "left" }}
          />
        ),
      },
      {
        target: ".about_desc",
        title: t("aboutUs"),
        content: t("aboutUs-journey"),
      },
      {
        target: ".report_damage",
        title: t("reportDamage"),
        content: t("reportDamage-journey"),
      },
    ],
    searchResultPage: [
      { 
        target: ".add_makani_desc",
        title: t("addMakani"),
        content: t("addMakani-journey"),
      },
   

            {
        target: ".street-view-desc",
        title: t("streetView"),
        content: t("streetView-journey"),
      },
      {
        target: ".share_location_desc",
        title: t("shareLocation"),
        content: t("shareLocation-journey"),
      },
        
      {
        target: ".direction_desc",
        title: t("calculateRoute"),
        content: t("calculateRoute-journey"),
      },
      {
        target: ".add_favorite_desc",
        title: t("addFavoritePlace"),
        content: t("addFavoritePlace-journey"),
      },
            {
        target:".indoor-navigation_desc",
        title: t("indoorNavigation"),
        content: t("indoorNavigationDescription"),
  
      },
   
   



    ],
    // searchResult:[

    // ],
    direction: [
      {
        target: ".start_point",
        content: t("startingPoint"),
      },
      {
        target: ".Add_destination",
        content: t("addDestination-journey"),
      },
      {
        target: ".Swipe_destination_desc",
        content: t("switchDestinations-journey"),
        title: t("switchDestination"),
      },
      {
        target: ".Add_favourite_desc",
        title: t("addRouteToFavourite"),
        content: t("addRouteToFavourite-journey"),
      },
      {
        target: ".Start_navigation",
        content: t("startNavigation-journey"),
      },
    ],

    about: [
      {
        target: ".step1",
        content: "This is the first step of the tour on the About page.",
        title: "First Step",
      },
      {
        target: ".step2",
        content: "This is the second step of the tour on the About page.",
        title: "Second Step",
      },
    ],
    indoorNavigation:[
      {
        target: ".start_point_indoor",
        title: t("startingPoint"),
        content:t("addStartPoint")
      },
      {
        target: ".end_point_indoor",
        title: t("endPoint"),
        content:t('addEndPoint')
      },
   
      {
        target:".travel_mode_indoor",
        title: t("Travel Mode"),
        content:t('travel-points-content')

      },
      {
        target:".swap_item",
        title: t("swap-points"),
        content:t('swap-points-content')
      },
      
    ],
    settings: [
      {
        target: ".Talkback_desc",
        title: t("talkback"),
        content: t("talkback-journey"),
      },
      {
        target: ".GrayScale_desc",
        title: t("greyscale"),
        content: t("greyscale-journey"),
      },
      {
        target: ".Font_size_desc",
        content: t("fontSize-journey"),
        title: t("fontsize"),
      },
      {
        target: ".Walkthrough_desc",
        content: t("walkthroug-journey"),
        title: t("Walkthrough"),
      },
    ],
  };

  return tourSteps;
};
export default TourSteps;
///18:55
