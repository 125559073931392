
import {
    PageHeaderTitle,
    MakaniDetails as MakaniDetailsComponent,
    MakaniCard,
  } from '../../component';
  import '../../scss/common.scss';
  import '../../scss/legend.scss';
  import { useTranslation } from 'react-i18next';
  import { UseRemoveMapShape } from '../../customHooks';

  import {pin,greenpin,emergencypin, makanipin } from '../../assets/index';
  import {makaniMarker} from '../../assets/images/makaniMarker.png'

  import { useLocation, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDraggableContext } from '../../context/DraggableContext';
import { MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants';
import{useToggle} from '../../context/index.js';
import { SpeechSynthesisButton } from '../../component/index';
import { image } from 'd3';

const TextItem = ({ text, style }) => {
    return <p style={style}>{text}</p>;
};


const Legend  = () => {
    const [t] = useTranslation();
    const location = useLocation();
    UseRemoveMapShape();
    const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const { distanceDragged } = useDraggableContext();
    const {setMenuToggle} =useToggle()
    const entries = [
        {
            // image: pin, 
            image: require('../../assets/images/makaniMarker.png'),
            description: t('tapmakani')
        },
        {
            // image: greenpin, 
            image: require('../../assets/images/selectedMarker.png'),
            description:t('selectmakani')
        },
        {
            // image: emergencypin, 
            // image: require('../../assets/images/emergency.png'),
            image: require('../../assets/images/emergencyLegend.png'),
            description: t('emergencymakani')
        },
        {
            // image: pin, 
            image: require('../../assets/images/makaniMarker.png'),
            description: t('mappoint')
        },
        {
            // image: greenpin, 
            image: require('../../assets/images/selectedMarker.png'),
            description: t('selectedpin')
        },
        {
            // image: pin, 
            image: require('../../assets/images/wheelchair.png'),
            description: t('accessibleentrance')
        },
    ];

    useEffect(() => {
        setIsMobileScreen(window.innerWidth <= 767);
      }, []);
    
      useEffect(() =>{
        const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
        setSideBarContentHeight(calculatedHeight);
      }, [distanceDragged]);

      const textToRead = [
        `${t('maplegend')}`,
        `${t('tapmakani')}`,
        `${t('selectmakani')}`,
        `${t('emergencymakani')}`,
        `${t('mappoint')}`,
        `${t('selectedpin')}`,
        `${t('accessibleentrance')}`,
        `${t('hightraffic')}, ${t('mediumtraffic')}, ${t('notraffic')}`
    ].join(' ');

     return (

       
<div className="sideBarContent inner-content inner-article swipe-line" style={{ padding: '' }}>
<div className='PageHeaderTitleWarper'>
</div>
              <div className='PageHeaderTitleWarper'>
          <PageHeaderTitle title={t('maplegend')}  parent={location?.state?.parent}  fromMenu={location?.state?.fromMenu} />
          <SpeechSynthesisButton text={textToRead}/>
         </div>
         <div
            className="content-cover"
            style={{
              overflowY: isMobileScreen ? 'auto' : '',
              height: isMobileScreen ? `${sideBarContentHeight}px` : 'auto', // added scroll ability for mobile screen
            }}    
        >
         <div className="legend-cover">
            {entries.map((entry, index) => (
                <div key={index} className="legendlist">
                  <img width='11' height='17' src={entry.image} alt="Pin" className="pin-icon" />
                    <div className="text-container">
                  
                   
                    <p className="text-bold">{entry.description}</p>
                    


                    
                    </div>
                    
                </div>
            ))}
         

         <div className="traffic-info-cover">
                
                <div className="traffic-col high">
                <div className="t-label ">{t('hightraffic')}</div>
                </div>
              <div className="traffic-col medium">
              <div className="t-label ">{t('mediumtraffic')}</div>
              </div>
              <div className="traffic-col no-trafic">
              <div className="t-label ">{t('notraffic')}</div>
              </div>
              </div>
       
           
      
            </div>
            </div>
            <div class="copy_right">{t('Copyright')}.</div>
        </div>
       
       
    );
};

export default Legend ;
