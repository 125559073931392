import { useNavigate } from "react-router-dom";
import { backArrow, close } from "../../assets/index";
import React, { useEffect, useState } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { BackIcon } from "../../assets/svg/svgIcons.jsx";
import { useToggle } from "../../context/index.js";
const PageHeaderTitle = ({
  title,
  customStyle = {},
  responsiveIcon,
  parent = "/",
  fromMenu,
}) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const { setMenuToggle } = useToggle();
  const [t, i18n] = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const goBack = () => {
    if (fromMenu) {
      setMenuToggle(true);
      navigate(-1);
    } else {
      navigate(parent || "/");
    }
  };

  return (
    <div className="pageTitleHeader" style={{ ...customStyle }}>
      <div
        className="closeWarper "
        onClick={() => {
          navigate(parent || "/");
        }}
      >
        <img src={close} alt="close" />
      </div>
      <div className="pageHeaderIcons">
        {isMobileView ? (
          responsiveIcon && (
            <div className="backPage" onClick={goBack}>
              <BackIcon />
            </div>
          )
        ) : (
          <div
            className="backPage"
            onClick={goBack}
            style={{
              transform:
                i18n.language === "ar" ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <BackIcon
              src={backArrow}
              alt="back arrow"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        )}
        <div className="pageTitle">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
};
export default PageHeaderTitle;
