const SettingsAlert = ({ message }) => {
  return (
    <div className="delete-m-card settingsAlert">
      <div className="body-content">
        <p className="allowAccessDesc">{message}</p>
      </div>
    </div>
  );
};

export default SettingsAlert;
