import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./webreader.css";
// import { VoiceoverPause, VoiceoverPlay } from '../../assets/svg/svgIcons';
import voiceover_pause from "../../assets/svg/voiceover_pause.svg";
import voiceover_play from "../../assets/svg/voiceover_play.svg";
import { useLocation } from "react-router-dom";
import { path } from "d3";

const SpeechSynthesisButton = ({ text, isBackPressed }) => {
  const { i18n } = useTranslation();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const speakText = (text) => {
    if (!window.speechSynthesis) {
      console.error("Speech Synthesis API not supported");
      return;
    }

    if (isSpeaking) {
      window.speechSynthesis.cancel();
    }

    const utterance = new SpeechSynthesisUtterance(text);
    const lang = i18n.language;
    utterance.lang = lang === "ar" ? "ar-SA" : "en-US";

    utterance.onstart = () => {
      setIsSpeaking(true);
    };

    utterance.onend = () => {
      setIsSpeaking(false);
    };

    utterance.onerror = (event) => {
      console.error("Speech synthesis error:", event);
      setIsSpeaking(false);
    };

    window.speechSynthesis.speak(utterance);
  };

  const stopSpeaking = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  };

  useEffect(() => {
    return () => {
      stopSpeaking();
    };
  }, []);

  return (
    <div className="speech-controls">
      {!isSpeaking ? (
        <button onClick={() => speakText(text)} className="speakButton">
          <img src={voiceover_play} alt="voiceover play"></img>
        </button>
      ) : (
        <button
          onClick={stopSpeaking}
          className="stopButton"
          disabled={!isSpeaking}
        >
          <img src={voiceover_pause} alt="voiceover pause"></img>
        </button>
      )}
    </div>
  );
};

export default SpeechSynthesisButton;
