import React from 'react';
import ReactDOM from 'react-dom/client';
import POICategoriesProvider from '../src/context/POICategoriesContext.js';
import MapControlProvider from './context/MapControllContext';
import LanguageProvider from './context/LanguageContext.js'
import AuthContext from './context/auth';
import 'react-toastify/dist/ReactToastify.css'; 
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppearanceProvider from './context/AppearanceContext';
import RecentSearchProvider from './context/recentSearchContext';
import ToggleProvider from './context/ToggleModel';
import TourProvider from './context/tourContext'
import IndoorNavigationProvider from './context/indoorNavigation'

import FavoritesProvider from './context/FavouritesContext';
import { DraggableContextProvider } from './context/DraggableContext';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import App from './App';
import './i18n';
// const pathUrl = process.env.MAKANI_WEB;
const package1 = require('../package.json');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={package1.homepage}>
    <AuthContext>
    <TourProvider>
      <AppearanceProvider>
      <IndoorNavigationProvider>
      <MapControlProvider>
        <RecentSearchProvider>
        <POICategoriesProvider>
          <ToggleProvider>
              <FavoritesProvider>
                <LanguageProvider>
                  <DraggableContextProvider>
                    <ToastContainer />

                    <App />
                  </DraggableContextProvider>
                </LanguageProvider>
              </FavoritesProvider>
          </ToggleProvider>
          </POICategoriesProvider>
        </RecentSearchProvider>
        </MapControlProvider>
        </IndoorNavigationProvider>
      </AppearanceProvider>
      </TourProvider>
    </AuthContext>
  </BrowserRouter>
);

