import {makaniCloseIcon} from '../../../assets/index';
import { useToggle } from '../../../context/ToggleModel.js';
import { useAuth } from '../../../context/auth.js';
import { useTranslation } from "react-i18next";
const LoginModel=()=>{
    const {setIsRequireLogin}=useToggle();
    const {authUrl,setUrl}=useAuth();
    const [t,il8n ] = useTranslation();

    const handleLogin = async () => {
        window.location.href = authUrl;
        // setUrl(window.location.origin)
        
      };
    
     
    return (        
          <div className='delete-m-card loginPopUp'>
        <div className="d-head">
      
        <span
          style={{
          marginRight: il8n.language ==="ar" && "auto",
          marginLeft: il8n.language ==="ar" && "unset"
        }} 
        ><strong> {t('loginRequired')} </strong></span>
          <button 
             style={{
          marginRight: il8n.language ==="ar" && "auto",
          marginLeft: il8n.language ==="ar" && "unset"
        }}
          
          className='close_btn' onClick={()=>setIsRequireLogin(false)}><img src={makaniCloseIcon} alt="close button" /></button>
        </div>
        <div className="body-content">
     
          <p>{t('accessToThisFeatureRequiresYouToBeLoggedIn')}</p>
          <div className="btn__brp" style={{
            direction: "ltr",
          }}>
            <button className='btn-m btn-cancel'  onClick={()=>setIsRequireLogin(false)}>{t('cancel')}</button>
            <button className='btn-m btn-delete' onClick={()=>handleLogin()}>{t('logIn')}</button>
          </div>
        </div>

       </div>
       )
}
export default LoginModel;