import {
    PageHeaderTitle,
    MakaniDetails as MakaniDetailsComponent,
    MakaniCard,
  } from '../../component';
  import '../../scss/common.scss';
  import '../../scss/article.scss';
  import '../../scss/contact.scss';
  import { useTranslation } from 'react-i18next';
  import {callicon,mailicon,websiteicon,makanipin,locationicon } from '../../assets/index';
  import AccessibleButton from '../../component/Accessibility/accessibility';
  import { UseRemoveMapShape } from '../../customHooks';

  import { useLocation, Outlet } from 'react-router-dom';
import { useDraggableContext } from '../../context/DraggableContext';
import { useEffect, useState } from 'react';
import { MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants';
// import { SpeechSynthesisButton } from '../../component/Webreader/webreader.jsx';
import { SpeechSynthesisButton } from '../../component/index';

  const Contact= () => {
    const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const { distanceDragged } = useDraggableContext();
    const [t, il8n] = useTranslation();
    UseRemoveMapShape();
    const location = useLocation();
    const entries = [
    
        {
            image: callicon, 
            text1: t('contactnumber'),
            text2: `+${t('phonenumber')}`,
            link: "tel:+97142064500",
        },
        {
            image: mailicon, 
            text1: t('emailaddress'),
            text2: "makaniteam@dm.gov.ae",
            link: "mailto:makaniteam@dm.gov.ae"
        },
        {
             image: websiteicon, 
            text1: t('website'),
            text2: "www.geodubai.ae",
            link: "http://www.geodubai.ae"
        },
        {
            image: makanipin, 
            text1: t('makaniNumber'),
            text2: t('nummakani'),
            link:`https://makani.dev.hashinclu.de/map?l=${il8n.language === "ar" ? 'A': 'E'}&m=3003295320`,
        },
        {
            image: locationicon, 
            text1: t('address'),
            text2: t('contactaddress')
        },
    ];
    const handleCallClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    useEffect(() => {
        setIsMobileScreen(window.innerWidth <= 767);
      }, []);
    
      useEffect(() =>{
        const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
        setSideBarContentHeight(calculatedHeight);
      }, [distanceDragged]);

      function reverseAndConcat(input) {
        return input.split(' ').reverse().join(' ');
      }

      // Speech synthesis button
      const textToRead = [
        `${t('contact')}`,
        `${t('contactdescription')}`,
        `${t('contactnumber')}: +${t('phonenumber')}`,
        `${t('emailaddress')}: makaniteam@dm.gov.ae`, 
        `${t('website')}: www.geodubai.ae`, 
        `${t('makaniNumber')}: ${t('nummakani')}`,  
        `${t('address')}: ${t('contactaddress')}`
      ].join(' ');
    



    return (
      <div
        className='sideBarContent inner-content inner-article swipe-line' 
        style={{
          padding: '',
        }}
      >
        <div className='PageHeaderTitleWarper'>
          <PageHeaderTitle title={t('contact')}   parent={location?.state?.parent}  
          fromMenu={location?.state.fromMenu}  />
          <SpeechSynthesisButton text={textToRead}/>
        </div>
        
        <div
            className="content-cover"
            style={{
            overflowY: isMobileScreen ? 'auto' : '',
            height: isMobileScreen ? `${sideBarContentHeight}px` : 'auto', // added scroll ability for mobile screen
            }}  
        >
          <article>
            <AccessibleButton 
              ariaLabel={t('contactdescription')}
            >
                <p>
                {t("contactdescription")}
                </p>  
            </AccessibleButton>      
             </article>
            <div className="contact-container">
            {entries.map((entry, index) => (
                <div key={index} className="contact-col">
                    
                        <img width='36' height='35' src={entry.image} alt="icon" className="contacticon"/>
                       
                        <div className="contact-text">
                                <p className="contacttext1">{entry.text1}</p>
                                {entry.link ? (
                                    <p className="contacttext2">
                                        {entry.text1 === "Website" || entry.text1 === "Email Address" ? (
                                            <a href={entry.link} className="contact-link" target="_blank" rel="noopener noreferrer">{entry.text2}</a>
                                        ) : entry.text1 === "Contact Number" ? (
                                            <a  className="contact-link" onClick={() => handleCallClick(entry.text2)}>{entry.text2}</a>
                                        ) : (
                                          <a className="contact-link" href= {entry.link}  target="_blank" rel="noopener noreferrer">{il8n.language === "ar" ? reverseAndConcat(entry.text2) : entry.text2}</a>
                                        )}
                                    </p>
                                ) : (
                                    <p className="contacttext2">{entry.text2}</p>
                                )}
                            </div>
                   
                </div>
            ))}
                </div>
        </div>
        <div class="copy_right">{t('Copyright')}</div>
     </div>
            );
};
export default Contact;
  //15:02