import React, { createContext, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./auth.js";
import { toast } from "react-toastify";
import {
  GetAllPOICategories,
  GetAllPOIsOfCategoriesAPI,
} from "../helper/api/requests.ts";
const POICategoriesContext = createContext();
export const usePOICategories = () => useContext(POICategoriesContext);

export const POICategoriesProvider = ({ children }) => {
  const { token, setToken, getToken, initialToken } = useAuth();
  const [POICategories, setPOICategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [poi, setPoi] = useState([]);
  const [t, il8n] = useTranslation();
  const [selectedPoi, setSelectedPoi] = useState(-1);

  useEffect(() => {
    const fetchData = async (currentToken) => {
      try {
        let result = await GetAllPOICategories(currentToken);

        if (result.isExp) {
          const newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          result = await GetAllPOICategories(newToken);
        }

        if (il8n.language === "ar") {
          setPOICategories(
            result.data.sort((a, b) => a.CategoryA.localeCompare(b.CategoryA))
          );
        } else {
          setPOICategories(
            result.data.sort((a, b) => a.CategoryE.localeCompare(b.CategoryE))
          );
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (token) fetchData(token);
  }, [initialToken]);

  const GetAllPOIsOfCategories = async (
    categoryId,
    makaniPosition,
    image,
    color
  ) => {
    try {
      let result = await GetAllPOIsOfCategoriesAPI(
        categoryId,
        makaniPosition,
        token
      );

      if (result.isExp) {
        const newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));

        result = await GetAllPOIsOfCategoriesAPI(
          categoryId,
          makaniPosition,
          newToken
        );
      }

      const poiWithImageAndColor = result.data.map((item) => ({
        ...item,
        image: image,
        color: color,
      }));

      if (poiWithImageAndColor.length > 0) {
        setPoi(poiWithImageAndColor);
      } else {
        toast.info(t("No_POI_found"), {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
           position: il8n.language ==="ar" ? 'top-left' : 'top-right',
           style: {
            textAlign:  il8n.language ==="ar" ? 'right' : 'left', 
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <POICategoriesContext.Provider
      value={{
        loading,
        POICategories,
        GetAllPOIsOfCategories,
        poi,
        setPoi,
        setSelectedPoi,
        selectedPoi,
      }}
    >
      {children}
    </POICategoriesContext.Provider>
  );
};

export default POICategoriesProvider;
