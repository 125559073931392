import { useState, useEffect } from 'react';
import { GetBuildingsAddress,GetSmartSearchResult ,GetFavorites} from '../helper/api/requests.ts';
import { useMapControl } from '../context/MapControllContext.js';
import { useAuth } from '../context/auth.js';
import {calculateDistanceBetweenCoordinates,processShapes} from '../helper/index.js';
import { useTranslation } from "react-i18next";

const useGetBuildingAddressFetching = (community, street, buildingNumber) => {
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token,sessionId,userId,initialToken,getToken, setToken} = useAuth();
  const [t,i18n] = useTranslation();

  const {
    setMakaniCoords,
    setCenter,
    setZoom,
    setBuildingsPolygon,
    currentLocation,
    currentLocationDirection,
    mapRef
  } = useMapControl();
  const calculateBoundingBox = (polygon) => {
    let minLat = Infinity, maxLat = -Infinity;
    let minLng = Infinity, maxLng = -Infinity;
    
    polygon.forEach(point => {
      if (point.lat < minLat) minLat = point.lat;
      if (point.lat > maxLat) maxLat = point.lat;
      if (point.lng < minLng) minLng = point.lng;
      if (point.lng > maxLng) maxLng = point.lng;
    });
  
    return { minLat, maxLat, minLng, maxLng };
  };


  const calculateZoomLevel = (width, height) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 18.9;
    const mapWidth = window.innerHeight;
    const mapHeight = window.innerWidth;

    const latFraction = height / 180;
    const lngFraction = width / 360;

    const latZoom = Math.log2(mapHeight / WORLD_DIM.height / latFraction);
    const lngZoom = Math.log2(mapWidth / WORLD_DIM.width / lngFraction);

    const zoomLevel = Math.min(latZoom, lngZoom);
    return zoomLevel;
    //return Math.min(zoomLevel, ZOOM_MAX);
  };

  function getZoomLevelAndCenter(polygon) {
    const { minLat, maxLat, minLng, maxLng } = calculateBoundingBox(polygon);
    const width = maxLng - minLng;
    const height = maxLat - minLat;
    
    const zoomLevel = calculateZoomLevel(width, height);
    const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
    
    return { zoomLevel, center };
}

function getCombinedPoliygons(polygans){
    const combinedPolygon = {
      polygan: []
  };

  // Iterate through each object and concatenate the polygan arrays
  polygans.forEach(item => {
      combinedPolygon.polygan = combinedPolygon.polygan.concat(item.polygan);
  });
  return { combinedPolygon };
}
  
  useEffect(() => {
    const fetchData = async () => {
      const map =  mapRef.current;
      try {
        let result = await GetBuildingsAddress(
          community,
          street,
          buildingNumber,
          token,
          i18n.language
        );

        if(result.isExp){
          let newToken= await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          result=   await GetBuildingsAddress(
            community,
            street,
            buildingNumber,
            newToken,
            i18n.language
          );
        }

        let MakainWithoutSpace =result[0].Makani.replace(/\s+/g, '');
        const makaniInfo = await GetSmartSearchResult(2, MakainWithoutSpace, token);
        let newBuildingsPolygon =makaniInfo.buildings.map((item) => {
    
          return {
            polygan:processShapes(item.SHAPE, false, false)
          }
        })
        setBuildingsPolygon(newBuildingsPolygon);
   
        let FavoriteResult =[]
        if(userId && sessionId){
          FavoriteResult = await GetFavorites(userId, sessionId, token);
        }
        const isFavoriteLocation = FavoriteResult.find(FavoriteResultItem => {
            
          return FavoriteResultItem.FavValue === result[0].Makani && FavoriteResultItem.FavType === 'LOCATION';
      });
            
      const isFavoriteMakani = FavoriteResult.find(FavoriteResultItem => {
        
        return FavoriteResultItem.FavValue === result[0].Makani && FavoriteResultItem.IsMyMakani ;
    });

        let  position=  {
          lat: Number(result[0].LatLng.split(',')[0]),
          lng: Number(result[0].LatLng.split(',')[1]),
        };
        let distance = calculateDistanceBetweenCoordinates(
          {
            lat:Number(currentLocationDirection.lat),
            lng:Number(currentLocationDirection.lng)
          },position,
        "km");

        //setCenter(position);
        setMakaniCoords([
          position
        ]);
        setBuildingInfo([
          {
            ...result[0],
            distance:distance,
            isFavoriteLocation : isFavoriteLocation ? true :false,
            isFavoriteMakani:isFavoriteMakani ? true :false


          }
          ]);
        //setZoom(20);
        const {combinedPolygon} = getCombinedPoliygons(newBuildingsPolygon)
        const { zoomLevel, center } = getZoomLevelAndCenter(combinedPolygon.polygan);
        setCenter(center);
        setZoom(zoomLevel);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    if(token,currentLocationDirection) fetchData();
  }, [currentLocationDirection,initialToken]);

  return {
    buildingInfo,
    loading,
  };
};

export default useGetBuildingAddressFetching;



