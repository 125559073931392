import React, { useState, useEffect, useRef } from "react";
import {
  PageHeaderTitle,
  MakaniDetails as MakaniDetailsComponent,
} from "../../component";
import "../../scss/common.scss";
import "../../scss/indoornavigations.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  useGetNodeListFetching,
  useGetRouteByType,
  useGetTravelMode,
} from "../../customHooks";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import withTour from "../../withTour";

import { Swapplace } from "../../assets/svg/svgIcons";
import { useAuth, useIndoorNavigation } from "../../context/index.js";
import { UseRemoveMapShape } from "../../customHooks";
import { useDraggableContext } from "../../context/DraggableContext";
import AccessibleButton from "../../component/Accessibility/accessibility.jsx";
import { ModalCloseBtn } from "../../assets/svg/svgIcons";
import { loadSvg } from "../../helper/index.js";
import { GetFloorPlans, GetFloors } from "../../helper/api/requests.ts";

const IndoorNavigation = () => {
  const [t, il8n] = useTranslation();
  const location = useLocation();
  const { token, getToken, setToken } = useAuth();

  const { modes, loading: travelMode } = useGetTravelMode(
    location?.state?.buildingid
  );
  const { fetchRouteByType } = useGetRouteByType();
  const { fetchNodeList, isNodeListLoading, nodeList } =
    useGetNodeListFetching();

  const {
    totalSteps,
    stepsCounter,
    setStepsCounter,
    origin,
    setOrigin,
    destination,
    setDestination,
    selectRoute,
    setSelectedRoute,
    setIsPathActive,
    setCurrentSvgIndex,
    isPathDrawing,
    setIsPathDrawing,
    setTotalSteps,
    clickedNode,
    setBuildingId,
    isPathLoading,
    setIsPathLoading,
    setSourcePath,
    setDestinationPath,
    setSvgEelement,
    svgContainerRef,
    floors,
    buildingId,
    setFloors,
    setClickedNode,
    steps,
  } = useIndoorNavigation();

  const { distanceDragged } = useDraggableContext();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const indoorNavContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [nodeSuggessions, setNodeSuggessions] = useState([]);
  const [clickedInput, setClickedInput] = useState(null);

  UseRemoveMapShape();
  const destinationInputRef = useRef(null);
  const setScrollProperties = () => {
    setTimeout(() => {
      if (
        isMobileScreen &&
        indoorNavContainerRef &&
        indoorNavContainerRef.current &&
        indoorNavContainerRef.current.style
      ) {
        indoorNavContainerRef.current.style.setProperty(
          "height",
          `${sideBarContentHeight}px`
        );
        indoorNavContainerRef.current.style.setProperty("overflow-y", "auto");
      }
    }, 100);
  };

  const fetchFloors = async () => {
    const floors_result = await GetFloors(
      t,
      token,
      location?.state?.makani[0]?.Makani
    );

    if (floors_result.IsExp) {
      let newToken = await getToken();
      setToken(newToken);
      localStorage.setItem("token", JSON.stringify(newToken));
      floors_result = await GetFloors(
        t,
        newToken,
        location?.state?.makani[0]?.Makani
      );
    }
    if (floors_result.error) {
      toast.error(floors_result.error, {
        // render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: il8n.language === "ar" ? "top-left" : "top-right",
      });
    } else {
      setFloors(floors_result?.data?.Data);
    }
  };

  useEffect(() => {
    if (modes) {
      modes.length === 1 && setSelectedRoute(modes[0]?.Id);
    }
  }, [modes]);

  useEffect(() => {
    if (clickedNode.input && clickedNode.value) {
      const availableNodes = nodeList.map((item) => item.nodeName);
      const nodeFetched = availableNodes.find(
        (item) => item === clickedNode.value
      );

      if (nodeFetched == null) {
        toast.error(t("Node_notfound"), {
          autoClose: 2000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
        return;
      }

      if (clickedNode.input == "origin") {
        setOrigin(clickedNode.value);
        destinationInputRef.current.focus();
      }
      if (clickedNode.input == "destination") {
        setDestination(clickedNode.value);
        inputRef.current.focus();
      }
      setClickedNode({ input: null, value: null });
    }
  }, [clickedNode]);

  useEffect(() => {
    const calculatedHeight = 200 - distanceDragged;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    if (
      nodeList.length > 0 &&
      clickedInput === "origin" &&
      !origin &&
      !destination
    ) {
      const sourceNode = nodeList?.find((node) => {
        const index = location?.state?.index ?? 0;
        let unitAddress = null;

        if (location?.state?.poi?.[index]?.TypeE == "Unit Address") {
          unitAddress = location?.state?.poi?.[index]?.NameE;
        }

        const makani = location?.state?.makani?.[index]?.Makani;
        return node?.nodeName === (unitAddress !== null ? unitAddress : makani);
      })?.nodeName;

      if (sourceNode) {
        setOrigin(sourceNode);
        setClickedInput(null);
        destinationInputRef.current.focus();
      }
    }
  }, [nodeList]);

  useEffect(() => {
    setScrollProperties();
  }, [isMobileScreen, indoorNavContainerRef, sideBarContentHeight]);

  useEffect(() => {
    if (!floors) {
      fetchFloors();
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
    fetchNodeList(location?.state?.buildingid ?? buildingId);
    setIsMobileScreen(window.innerWidth <= 767);
    setScrollProperties();
    setBuildingId(location?.state?.buildingid);
    setCurrentSvgIndex(0);
    setOrigin("");
    setDestination("");
    setSelectedRoute("");
    setStepsCounter(1);
    setIsPathActive(false);
    setIsPathDrawing(false);
  }, []);

  useEffect(() => {
    findNodesFromList();
  }, [origin, destination]);

  useEffect(() => {
    if (clickedInput && isPathDrawing) {
      updateSvg();
    }
  }, [clickedInput]);

  const updateSvg = async () => {
    setIsPathDrawing(false);

    setIsPathLoading(true);

    if (steps && steps.length > 0) {
      const parsedStepKey = `step${stepsCounter}`;
      const stepData = steps?.find(
        (step) => Object.keys(step)[0] === parsedStepKey
      );
      const url = Object.values(stepData)[0].svgUrl;
      const svgElement = await loadSvg(url);
      const container = svgContainerRef.current;
      if (container) {
        container.innerHTML = "";
        container.appendChild(svgElement);
        svgElement.setAttribute("width", "100%");
        svgElement.setAttribute("height", "100%");
        svgElement.setAttribute("viewBox", "0 0 1000 1000");
        setSvgEelement(svgElement);
      }
      setIsPathLoading(false);
    }
  };

  useEffect(() => {
    handleChangeStep();
  }, [stepsCounter]);

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const findNodesFromList = () => {
    if (!origin || !destination) {
      setNodeSuggessions([]);
    }
    if (nodeList.length > 0) {
      const valueToFilter = clickedInput === "origin" ? origin : destination;
      if (valueToFilter && valueToFilter.length >= 1) {
        const filteredNodes = nodeList.filter((item) =>
          item.nodeName.includes(String(valueToFilter).toUpperCase())
        );
        setNodeSuggessions(filteredNodes);
      }
    }
  };

  const handleSwap = () => {
    updateSvg();
    const temp = origin;
    setOrigin(destination);
    setDestination(temp);
  };

  const handleDrawPath = async () => {
    if (origin && destination) {
      setIsPathLoading(true);
      if (location?.state?.buildingid) {
        setBuildingId(location?.state?.buildingid);
      }

      setTotalSteps(0);
      setStepsCounter(1);
      setSourcePath(null);
      setDestinationPath(null);
      setClickedInput(null);
      setIsPathDrawing(true);

      const originId = nodeList?.find(
        (node) => node?.nodeName === origin.toUpperCase()
      )?.nodeID;
      const destinationId = nodeList?.find(
        (node) => node?.nodeName === destination.toUpperCase()
      )?.nodeID;

      fetchRouteByType(
        originId,
        destinationId,
        selectRoute,
        location?.state?.buildingid,
        true
      );

      setIsPathActive(true);
    }
  };

  const handleChangeStep = async () => {
    if (origin && destination && selectRoute) {
      const originId = nodeList?.find(
        (node) => node?.nodeName === origin
      )?.nodeID;
      const destinationId = nodeList?.find(
        (node) => node?.nodeName === destination
      )?.nodeID;

      fetchRouteByType(
        originId,
        destinationId,
        selectRoute,
        location?.state?.buildingid,
        false
      );
    }
  };

  function reverseAndConcat(input) {
    return input.split(" ").reverse().join(" ");
  }

  const getFormattedTravelModeName = (modes, selectRoute, language) => {
    const selectedMode = modes.find((item) => item.Id === selectRoute);

    if (!selectedMode) return t("Travel Mode");

    const nameToUse =
      language === "ar" && selectedMode.NameAr
        ? selectedMode.NameAr
        : selectedMode.NameEn;

    const formattedName =
      nameToUse.length > 10
        ? `${nameToUse.charAt(0).toUpperCase()}${nameToUse
            .slice(1)
            .toLowerCase()}`
        : `${nameToUse.charAt(0).toUpperCase()}${nameToUse
            .slice(1)
            .toLowerCase()}`;

    return formattedName || t("Travel Mode");
  };

  return (
    <div className="sideBarContent inner-content inner-article swipe-line indoornavigation-otr">
      <div className="PageHeaderTitleWarper">
        <PageHeaderTitle />
      </div>
      <div className="settingsContent" ref={indoorNavContainerRef}>
        <div className="searchTitleWarper">
          <p className="searchTitle">
            {location?.state?.makani[0]?.BldgNameAr && il8n.language === "ar"
              ? location?.state?.makani[0]?.BldgNameAr
              : location?.state?.makani[0]?.BldgNameEn}
          </p>
          <p className="searchAddress">
            {!location?.state?.makani[0] &&
              [1].map((item) => <Skeleton height={15} width={`45%`} />)}
            {location.state.makani[0] && il8n.language === "ar"
              ? location?.state?.makani[0]?.CommAr
              : location?.state?.makani[0]?.CommEn}
          </p>
        </div>

        <div className="indoornav-content">
          <div className="indoor-navcontroller">
            <div className="navigation-group">
              <div className="route-info">
                <div className="lines">
                  <div className="marker ">
                    <div className="arrow-green"></div>
                  </div>
                  <div className="marker circle-green"></div>
                </div>
              </div>

              <div className="navigation-controler">
                <div className="input-cvr">
                  <div className="frm_controler_otr">
                    <div className="frm-input">
                      <input
                        ref={inputRef}
                        type="text"
                        placeholder={t("Choose_Start_Point")}
                        name="origin"
                        value={
                          origin === location?.state?.makani[0]?.Makani &&
                          il8n.language === "ar"
                            ? reverseAndConcat(origin)
                            : origin
                        }
                        className="frm_controler"
                        onChange={(e) => setOrigin(e.target.value)}
                        onFocus={() => setClickedInput("origin")}
                      />
                      {origin && (
                        <AccessibleButton
                          className="clear-search-indoor-nav"
                          onClick={() => {
                            setOrigin("");
                            inputRef.current?.focus();
                            isPathDrawing && updateSvg();
                          }}
                        >
                          <ModalCloseBtn />
                        </AccessibleButton>
                      )}
                    </div>
                  </div>
                </div>

                <div className="divider"></div>

                <div className="input-cvr">
                  <div className="frm_controler_otr">
                    <div className="frm-input">
                      <input
                        ref={destinationInputRef}
                        type="text"
                        placeholder={t("Choose_Finish_Point")}
                        name="destination"
                        value={
                          destination === location?.state?.makani[0]?.Makani &&
                          il8n.language === "ar"
                            ? reverseAndConcat(destination)
                            : destination
                        }
                        className="frm_controler"
                        onChange={(e) => {
                          setDestination(e.target.value);
                        }}
                        onFocus={() => {
                          setClickedInput("destination");
                        }}
                      />
                      {destination && (
                        <AccessibleButton
                          className="clear-search-indoor-nav"
                          onClick={() => {
                            setDestination("");
                            destinationInputRef.current?.focus();
                            isPathDrawing && updateSvg();
                          }}
                        >
                          <ModalCloseBtn />
                        </AccessibleButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="swap-button " onClick={() => handleSwap()}>
                <div className="swap_item">
                  <Swapplace />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        {clickedInput &&
          (origin || destination) &&
          nodeSuggessions.length > 1 && (
            <div className="indoor-search-otr">
              <div className="search-list-area">
                <div className="search-list-view">
                  <ul className="list_view">
                    <p
                      style={{
                        fontFamily: "VisbyMedium",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      Result
                    </p>
                    <div className="nodeWrapper">
                      {(isNodeListLoading || !nodeSuggessions) &&
                        [1, 2, 3].map((item) => (
                          <Skeleton height={15} width={`45%`} />
                        ))}
                    </div>

                    {!isNodeListLoading &&
                      nodeSuggessions.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              clickedInput === "origin"
                                ? setOrigin(item.nodeName)
                                : setDestination(item.nodeName);

                              origin == null
                                ? setClickedInput("origin")
                                : inputRef.current?.focus();

                              destination == null
                                ? setClickedInput("destination")
                                : destinationInputRef.current?.focus();
                            }}
                          >
                            {item.nodeName} -{" "}
                            {Number(item.floorNumber) === 800
                              ? "Ground Floor"
                              : `Floor ${item.floorNumber}`}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}

        <div className="indoor-broup-btn">
          <div className="navi-otr">
            <div className="sub-menu-otr ">
              <button
                className="navi-btn primary-navi travel_mode_indoor"
                onClick={() => {
                  if (modes.length != 0) {
                    toggleSubmenu();
                  }
                }}
              >
                {!travelMode
                  ? modes.length != 0
                    ? getFormattedTravelModeName(
                        modes,
                        selectRoute,
                        il8n.language
                      )
                    : t("no_travel_mode")
                  : t("Travel Mode")}
                {modes.length != 0 && (
                  <div className="icon-control">
                    {!isSubmenuOpen ? (
                      <span className="arrowdown"></span>
                    ) : (
                      <span className="arrowup"></span>
                    )}
                  </div>
                )}
              </button>
              {isSubmenuOpen && (
                <div className="submenu">
                  {modes.map((item) => {
                    return (
                      <button className="sub-item">
                        <label
                          for={item.NameEn}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {il8n.language === "ar"
                            ? item.NameEn
                            : item.NameEn.charAt(0).toUpperCase() +
                              item?.NameEn.slice(1).toLowerCase()}
                        </label>
                        <div className="input-otr">
                          <input
                            id={item.NameEn}
                            type="radio"
                            value={item.Id}
                            checked={selectRoute === item.Id}
                            onChange={() => {}}
                            onClick={(e) => {
                              isPathDrawing && updateSvg();
                              setIsPathDrawing(false);
                              setIsPathActive(false);
                              if (selectRoute === item.Id) {
                                setSelectedRoute(null);
                              } else {
                                setSelectedRoute(item.Id);
                              }
                              setIsSubmenuOpen(false);
                            }}
                            name="selectedRoute"
                            style={{ accentColor: " #009D91" }}
                          />
                        </div>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            {!isPathDrawing ? (
              <button
                className="navi-btn start-btn"
                onClick={() => {
                  setIsSubmenuOpen(false);
                  if (origin && destination && origin === destination) {
                    toast.error(t("nodes_must_not_be_identical"), {
                      autoClose: 2000,
                      position:
                        il8n.language === "ar" ? "top-left" : "top-right",
                      style: {
                        textAlign: il8n.language === "ar" ? "right" : "left",
                      },
                    });
                    return;
                  } else {
                    handleDrawPath();
                  }
                }}
              >
                {t("start")}
              </button>
            ) : (
              <>
                {totalSteps > 0 && !isPathLoading ? (
                  <>
                    <div class="floor-navigation-btn">
                      <button
                        class="arrow-button left"
                        onClick={() => {
                          if (il8n.language === "en") {
                            if (stepsCounter > 1) {
                              const count = stepsCounter - 1;
                              setStepsCounter(count);
                            }
                          } else {
                            if (stepsCounter < totalSteps) {
                              const count = stepsCounter + 1;
                              setStepsCounter(count);
                            }
                          }
                        }}
                      >
                        {il8n.language === "en" ? "←" : "→"}
                      </button>
                      {totalSteps > 0 && (
                        <div class="floor-nav-content">
                          {stepsCounter}/{totalSteps}
                        </div>
                      )}
                      <button
                        class="arrow-button right"
                        onClick={() => {
                          if (il8n.language === "en") {
                            if (stepsCounter < totalSteps) {
                              const count = stepsCounter + 1;
                              setStepsCounter(count);
                            }
                          } else {
                            if (stepsCounter > 1) {
                              const count = stepsCounter - 1;
                              setStepsCounter(count);
                            }
                          }
                        }}
                      >
                        {il8n.language === "en" ? "→" : "←"}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="floor-navigation-btn"
                      style={{ color: "#fff" }}
                    >
                      {t("loading")}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <br />
        </div>

        <MakaniDetailsComponent
          makani={location?.state?.makani}
          poi={location?.state?.poi}
          style={{ margin: 0 }}
          showParcelId={false}
        />
        <br />

        <div className="copy_right">{t("Copyright")}</div>
      </div>
    </div>
  );
};

export default withTour(IndoorNavigation, "indoorNavigation");
