export const handleError = (t, errorCode) => {
  switch (String(errorCode)) {
    case "1":
      return t("code1");
    case "2":
      return t("code2");
    case "3":
      return t("code3");
    case "4":
      return t("code4");
    case "5":
      return t("code5");
    case "6":
      return t("code6");
    case "7":
      return t("code7");
    case "8":
      return t("code8");
    case "9":
      return t("code9");
    case "10":
      return t("code10");
    case "11":
      return t("code11");
    case "12":
      return t("code12");
    case "13":
      return t("code13");
    case "14":
      return t("code14");
    case "15":
      return t("code15");
    case "16":
      return t("code16");
    case "17":
      return t("code17");
    case "18":
      return t("code18");
    case "19":
      return t("code19");
    case "20":
      return t("code20");
    case "21":
      return t("code21");
    case "22":
      return t("code22");
    case "23":
      return t("code23");
    case "24":
      return t("code24");
    case "25":
      return t("code25");
    case "26":
      return t("code26");
    case "27":
      return t("code27");
    case "28":
      return t("code28");
    case "29":
      return t("code29");
    case "30":
      return t("code30");
    case "31":
      return t("code31");
    case "32":
      return t("code32");
    case "33":
      return t("code33");
    case "34":
      return t("code34");
    case "35":
      return t("code35");
    case "36":
      return t("code36");
    case "37":
      return t("code37");

    default:
      return t("unknown");
  }
};
