import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MakaniCard, MyMakaniCardsList } from "../../component/index.js";
import { makaniCloseIcon, NoDataImg } from "../../assets/index.js";
import { useNavigate, useLocation } from "react-router-dom";
import "../../scss/myMakaniCards.scss";
import EditFavoritesModel from "../models/editFavoritesModel/index.jsx";
import { useAppearance } from "../../context/AppearanceContext.js";
import { useFavorites } from "../../context/FavouritesContext.js";
import { toast } from "react-toastify";
const MyMakaniCards = ({
  setIsEdit,
  ConfirmEdit,
  setFavName,
  FavName,
  setMyMakani,
}) => {
  const { theme } = useAppearance();
  const navigate = useNavigate();
  const [t, il8n] = useTranslation();
  const [isMobileView, setIsMobileView] = useState(false);
  const { setDisaplayMakaniMarker, myMakani, loading, setRefetch } =
    useFavorites();

  const location = useLocation();

  useEffect(() => {
    setDisaplayMakaniMarker(true);
    setRefetch((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1100);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    let toastId;

    if (loading) {
      toastId = toast.info(t("loading"), {
        position: il8n.language === "ar" ? "top-left" : "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
    } else {
      toast.dismiss(toastId);
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [loading]);

  useEffect(() => {
    if (!loading && myMakani.length === 0) {
      toast.info(t("No_Makani_Card_Found"), {
        position: il8n.language === "ar" ? "top-left" : "top-right",
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
    }
  }, [loading]);
  return (
    <>
      <div
        className="myMakaniCards"
        style={{
          right: il8n.language === "ar" && "",
          left: il8n.language === "ar" && "",
        }}
      >
        <div
          className="makaniCloseIcon"
          onClick={() => {
            navigate("/profile");
            setDisaplayMakaniMarker(false);
          }}
        >
          <img src={makaniCloseIcon} alt="close" />
        </div>

        {myMakani.length > 0 && (
          <MyMakaniCardsList
            setMyMakani={setMyMakani}
            setIsEdit={setIsEdit}
            setFavName={setFavName}
            FavName={FavName}
            // cardRef={cardRef}
          />
        )}
        {myMakani.length === 0 && (
          <div className="no-data-makani">
            <div className="no-data-img">
              <img src={NoDataImg} alt="No data" />
            </div>
            <p className="noDataFound">{t("noResult")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MyMakaniCards;
