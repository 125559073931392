import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import searchShopping from "../../assets/images/searchShopping.svg";
import "./style.css";
import { useRecentSearch } from "../../context/recentSearchContext";
import { useFavorites } from "../../context/FavouritesContext";
import { usePOICategories } from "../../context/POICategoriesContext";
import { formatTimestamp } from "../../helper/index.js";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";
import { direction } from "../../assets/index.js";

const RecentSearch = ({ setSearch }) => {
  const { recentSearch, setRecentSearch } = useRecentSearch();
  const { POICategories, loading: POILoading } = usePOICategories();
  const { locations, loading } = useFavorites();

  const [mouseDownX, setMouseDownX] = useState(0);
  const [mouseDownY, setMouseDownY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLocation, setIsLocation] = useState(null);

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    const savedData = JSON.parse(localStorage.getItem("recentSearch"));
    if (savedData) {
      setRecentSearch(savedData);
    }

    checkLocation();
  }, []);

  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [t, il8n] = useTranslation();
  const navigate = useNavigate();

  const handleMouseDown = (e) => {
    setMouseDownX(e.clientX);
    setMouseDownY(e.clientY);
    setIsDragging(false);
  };

  const handleMouseUp = (e) => {
    const dragDistance = Math.sqrt(
      Math.pow(e.clientX - mouseDownX, 2) + Math.pow(e.clientY - mouseDownY, 2)
    );
    if (dragDistance > 5) {
      // Adjust the threshold as needed
      setIsDragging(true);
    }
  };

  const handleClick = (item) => {
    if (!isDragging) {
      setSearch(il8n.language === "ar" ? item.CategoryA : item.CategoryE);
    }
  };

  // styling to align recent searches and clear searches
  const recentSearchTitleWraperStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
  // function to remove clear recent search
  const handleClickClearSearch = () =>{
    localStorage.removeItem("recentSearch");
    setRecentSearch([]);
  }

  function reverseAndConcat(input) {
    // return input.replace(/ /g, '\u00A0');
    return input.split(' ').reverse().join(' ');
  }


  return (
    <>
      <div className="pointOfInternetSearchSuggestions">
        {POILoading &&
          [...Array(3)].map((item, index) => {
            return (
              <div
                key={index}
                className="pointOfInternetItemSearchSuggestions"
                style={{
                  paddingCategoryERight: il8n.language === "ar" && "0",
                  paddingLeft: il8n.language === "ar" && "14px",
                }}
              >
                <div
                  className="pointOfInternetIcon"
                  style={{
                    background: "unset",
                    width: "38px",
                    height: "38px",
                  }}
                >
                  <Skeleton circle={true} height={30} width={30} />
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <Skeleton width={60} />
                </span>
              </div>
            );
          })}
      </div>

      <Slider className="poi-recent-slide" {...settings}>
        {!POILoading &&
          POICategories.length > 0 &&
          POICategories.map((item, index) => {
            return (
              <div
                key={index}
                className="pointOfInternetItemSearchSuggestions"
                style={{
                  paddingCategoryERight: il8n.language === "ar" && "0",
                  paddingLeft: il8n.language === "ar" && "14px",
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onClick={() => handleClick(item)}
              >
                <div
                  className="pointOfInternetIcon"
                  style={{
                    background: item.Color,
                    width: "",
                    height: "",
                  }}
                >
                  <img
                    style={{
                      height: "",
                    }}
                    src={item.Symbol}
                    alt={item.CategoryE}
                  />
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {il8n.language === "ar" ? item.CategoryA : item.CategoryE}
                </span>
              </div>
            );
          })}
      </Slider>

      <div className="searchSuggestions">
        <div className="recentSearchesWarper">
          <div className="recentSearchTitleWraper" style={recentSearchTitleWraperStyle}>
            <p className="recentSearchersTitle">{t("recentsearches")}</p>
            <p className="recentSearchersTitle"
            onClick={handleClickClearSearch}
            style={{cursor: 'pointer'}}
            >{t("clearSearch")}</p>
          </div>
          <div className="recentSearchers">
            <p className="noDataFound">
              {" "}
              {recentSearch.length === 0 && t("noResult")}
            </p>
            {recentSearch.length > 0 &&
              [...recentSearch]
                .reverse()
                .splice(0, 4)
                .map((item, index) => {
                  return item.category ? (
                    <div
                      key={index}
                      className="recentSearch"
                      onClick={() => {
                        navigate("/searchResult", {
                          state: {
                            dgis: item.dgis_id,
                            featureclassId: item.featureclass_id,
                            searchValue: item.text,
                          },
                        });
                      }}
                      style={{
                        paddingTop: index === 0 ? 0 : "",
                        borderBottom: index === 2 ? "none" : "",
                      }}
                    >
                      <p className="recentSearchTitle">{item.text}</p>
                      <div
                        className="recentSearchLocation"
                        onClick={() => {
                          navigate("/searchResult", {
                            state: {
                              dgis: item.dgis_id,
                              featureclassId: item.featureclass_id,
                              searchValue: item.text,
                            },
                          });
                        }}
                      >
                        <p className="recentSearchAddress">{item.category}</p>
                        <p className="recentSearchDestination"> </p>
                      </div>
                    </div>
                  ) : null;
                })}
          </div>
        </div>
      </div>

      <div
        className="searchSuggestions"
        style={{
          marginTop: "0px",
        }}
      >
        <div className="recentSearchesWarper">
          <p className="recentSearchersTitle">{t("favourites")}</p>
          <div className="recentSearchers">
            {loading &&
              [...Array(3)].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="recentSearch"
                    style={{
                      paddingTop: index === 0 && 0,
                    }}
                  >
                    <p className="recentSearchTitle">
                      <Skeleton width={100} />
                    </p>
                    <div className="recentSearchLocation">
                      <p className="recentSearchAddress">
                        <Skeleton width={100} />
                      </p>
                      <p className="recentSearchDestination">
                        {" "}
                        <Skeleton width={50} />
                      </p>
                    </div>
                    <div className="pointOfInternetType">
                      <div
                        className="shoppingIcon"
                        style={{
                          background: "unset",
                        }}
                      >
                        <Skeleton circle={true} height={20} width={20} />
                      </div>
                      <p className="pointOfInternetTypeTitle">
                        <Skeleton width={50} />
                      </p>
                    </div>
                  </div>
                );
              })}

            {!loading &&
              locations.length > 0 &&
              [...locations].splice(0, 4).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="recentSearch"
                    onClick={() => {
                      navigate("/searchResult", {
                        state: {
                          dgis: item.FavValue.replace(/\s+/g, ""),
                          featureclassId: "2",
                          searchValue: item.FavName,
                        },
                      });
                    }}
                    style={{
                      paddingTop: index === 0 && 0,
                    }}
                  >
                    <p className="recentSearchTitle">{item.FavName}</p>
                    <div className="recentSearchLocation">
                      <p className="recentSearchAddress">{il8n.language === 'ar' ? reverseAndConcat(item.FavValue) : item.FavValue}</p>
                      <p className="recentSearchDestination">
                        {!isLocation
                          ? "--"
                          : parseFloat(item?.distance?.toFixed(2))}{" "}
                        {t("km")}
                      </p>
                    </div>
                    <div className="pointOfInternetType">
                      <div className="shoppingIcon">
                        <img src={searchShopping} alt="searchShopping" />
                      </div>
                      <p className="pointOfInternetTypeTitle">
                        {formatTimestamp(item.LastUpdated)}
                      </p>
                    </div>
                  </div>
                );
              })}
            {!loading && locations.length === 0 && (
              <p className="noDataFound">{t("noResult")}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentSearch;
