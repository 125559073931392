import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import tourSteps from './tourSteps';
import { useTranslation } from 'react-i18next';
import { useToggle,useAppearance,useTour } from './context/index.js';

const withTour = (WrappedComponent, pageKey) => {

  return (props) => {
    const { walkThrough,setWalkThrough,theme,showPopup} =useAppearance();
    const { run, stepIndex, setStepIndex, startTour, stopTour,    tourSkipped,
      setTourSkipped } = useTour();
    const [t, il8n] = useTranslation();
    // const [run, setRun] = useState(false);


    // useEffect(() => {
    //   setRun(true);
    // }, []);
    const handleJoyrideCallback = (data) => {
  
      const { status, index, action,type } = data;
    
      if (status === 'finished'|| action === "close") {
        stopTour();
      } else if(status === 'skipped' ){
        setWalkThrough(false)
        setTourSkipped(true)
      }
      else{
          setStepIndex(index); 
        }
      // else if (type === 'error:target_not_found') {
      //     stopTour();
      //   setStepIndex(index); 
      // }
    };



    useEffect(() => {
      if (walkThrough && !showPopup) {
        // Wait until the component is fully rendered
        setTimeout(() => {
          startTour();
        }, 100); // Adjust timing if needed
      } else {
        stopTour();
      }
    }, [pageKey, walkThrough, showPopup]);

    return (
      <>
        <WrappedComponent {...props} />
        <Joyride
          steps={tourSteps()[pageKey]}
          run={run}
          continuous
          showProgress={false}
          showSkipButton
          disableBeacon={true}  
          callback={handleJoyrideCallback}
          locale={{
          back: t('back'),
          close: t('close'),
          last: t('last'),
          next: t('next'),
          skip: t('skip')
          }}
          styles={{
          options: {
            arrowColor: theme === "dark" ? "#4e5972" : "#fff",
            backgroundColor: theme === "dark" ? "#4e5972" : "#fff",
            textColor: theme === "dark" ? "#fff":"#009D91",
            zIndex: 9,
          },
          buttonNext: {
            color: '#fff',
      backgroundColor: theme === "dark" ? '#1e222b' : '#333c4f',
      '&:hover': {
        backgroundColor: '#009d91',
      },
    },
    buttonBack: {
      color: '#fff',
      backgroundColor: theme === "dark" ? '#1e222b' : '#333c4f',
      '&:hover': {
        backgroundColor: '#009d91',
      },
    },
    buttonSkip: {
      color: '#fff',
      backgroundColor: theme === "dark" ? '#1e222b' : '#333c4f',
      '&:hover': {
        backgroundColor: '#009d91',
      },
    },
    buttonClose: {
      display: 'none',
    },
        }}
        />
        </>
 
    );
  };
};

export default withTour;
