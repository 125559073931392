import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { cardLogoDark, cardLogo } from "../../../assets/index";
import "../style.css";
import { useTranslation } from "react-i18next";
import { SendIcon, ShareIcon } from "../../../assets/svg/svgIcons";
import { useAppearance } from "../../../context/AppearanceContext";
import { useFavorites } from "../../../context/FavouritesContext";
import { useMapControl } from "../../../context/MapControllContext";
import isLocationEnabled from "../../../helper/utils/userLocationStatus";
import { SHARE_URL_BASE } from "../../../helper/api/constants.ts";
const HomeMakaniCard = ({ item, HomeMakaniCard, setShare, index }) => {
  const { setSharedURL, myMakani } = useFavorites();
  const {
    setHomeSelectedMakani,
    setHomeFavroiteSelect,
    homeSelectedMakani,
    setCenter,
  } = useMapControl();

  const { theme } = useAppearance();
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();
  const [isLocation, setIsLocation] = useState(null);

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    checkLocation();
  }, []);

  function reverseAndConcat(input) {
    const splitArray = input.split(' '); 
    const reversedArray = splitArray.reverse();
    const result = reversedArray.join(' ');
    
    return result;
}

  return (
    <div
      className="MakaniCard"
      onClick={() => {
        setHomeSelectedMakani(index);
        setHomeFavroiteSelect(-1);
        setCenter({
          lat: parseFloat(myMakani[index].Location.split(",")[0]),
          lng: parseFloat(myMakani[index].Location.split(",")[1]),
        });
      }}
    >
      <div
        className={
          il8n.language === "ar" ? "MakaniCardLogoAr" : "MakaniCardLogo"
        }
      >
        {theme === "dark" ? (
          <img src={cardLogoDark} alt="makani logo" width="86" height="33" />
        ) : (
          <img src={cardLogo} alt="makani logo" width="86" height="33" />
        )}
      </div>
      <div
        className={
          il8n.language === "ar" ? "MakaniCardInfoAr" : "MakaniCardInfo"
        }
      >
        <p className="makaniCardTitle">{(item.FavName === item.FavValue) && il8n.language==='ar' ? reverseAndConcat(item.FavName):item.FavName}</p>
        <p className="makaniCardNumber">{il8n.language ==="ar" ? reverseAndConcat(item.FavValue): item.FavValue}</p>
        <p className="makaniCardDistance">
          <span>
            {!isLocation ? "--" : parseFloat(item.distance?.toFixed(2))}
          </span>{" "}
          {t("km")}
        </p>
      </div>
      <div
        className={
          il8n.language === "ar" ? "MakaniCardBtnsAr" : "MakaniCardBtns"
        }
      >
        <div
          className="MakaniCardBtn"
          onClick={() => {
            navigate("/direction", {
              state: {
                BuildingNameE: item.BuildingNameE || item.FavName,
                BuildingNameA: item.BuildingNameA || item.FavName,
                position: {
                  lat: parseFloat(item.Location.split(",")[0]),
                  lng: parseFloat(item.Location.split(",")[1]),
                },
              },
            });
          }}
        >
          <SendIcon />
        </div>
        <div
          className="MakaniCardBtn MakaniCardShareBtn"
          onClick={(e) => {
            e.stopPropagation();
            setShare({
              value: `${SHARE_URL_BASE}?l=${il8n.language === "ar" ? "A" : "E"}&m=${item.FavValue.replace(/\s+/g, "")}`,
              isShare: true,
            });
          }}
        >
          <ShareIcon />
        </div>
      </div>
    </div>
  );
};

export default HomeMakaniCard;
//
