import { useTranslation } from 'react-i18next';
import { SettingToggle } from '../../component/index';
import { useAppearance } from '../../context/AppearanceContext';
import AccessibleButton from '../Accessibility/accessibility';
const AppearanceSettings = () => {
  const [t] = useTranslation();
  const { theme, setTheme } = useAppearance();
  return (
    <div
      className='collapsibleMenuItems'
      style={{
        padding: '10px 0 25px 0',
      }}
    >
      
        <AccessibleButton 
          className='collapsibleMenuItemWrapper MenuItemRow '
          onClick={() => {}}
          ariaLabel={t('Click here to change theme')}
        >
          <p>{t('theme')}</p>

          <SettingToggle
            values={{
              dark: t('dark'),
              light: t('light'),
            }}
            value={theme}
            setState={setTheme}
          />
        </AccessibleButton>
     
    </div>
  );
};

export default AppearanceSettings;
//18:39
