import React from 'react';
import './style.css'; // Import the CSS file
import { MdOutlineEmail } from 'react-icons/md';
import { FaFacebookF } from 'react-icons/fa6';
import { FiTwitter } from 'react-icons/fi';
import { IoIosLink } from 'react-icons/io';
import { ShareSocial } from 'react-share-social';

const PopupModal = ({ setShare, url }) => {
  return (
    <div className='popup'>
      <div className='closeWrapper'>
        <p className='popTitle'>Share</p>
        <p className='close' onClick={() => setShare(false)}>
          &times;
        </p>
      </div>

      <div className='content'>
        {/* <div className='icon'>
          <IoIosLink size={23} />
        </div>
        <div className='icon'>
          <MdOutlineEmail size={23} />
        </div>
        <div className='icon'>
          <FaFacebookF size={23} />
        </div>
        <div className='icon'>
          <FiTwitter size={23} />
        </div> */}
        <ShareSocial
          url={`${url}`}
          socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'email']}
        />
      </div>
    </div>
  );
};

export default PopupModal;
    