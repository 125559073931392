import React from 'react';


const CustomLoader = () => {
  return (
    <div className="spinner-outer">
        {/* <div className="tooltip-wrp">
         <div className="near-makkani">Nearest Makani No.</div>
         <strong>18518 79408 <span><CopyBtn/></span></strong>
      </div> */}
      <div className='spin-wrap'>
        <div class="spinner-wrap">
          <div className="center-point"></div>
          <div class="spinner-item"></div>
          <div class="spinner-item spinner-item--2">
          </div>
        </div>
      </div>
    </div>
  )
};

export default CustomLoader;
