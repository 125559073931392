import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeaderTitle, ProfileCard } from "../../component/index";
import {
  makaniCard,
  profileFavoruites,
  profileSettingPage,
  makaniCardDark,
  profileFavoruitesDark,
  profileSettingDark,
  signClose,
  logout,
  logOutDarkMode,
} from "../../assets/index";
import "../../scss/common.scss";
import "../../scss/profile.scss";
import { useFavorites } from "../../context/FavouritesContext.js";
import { useAuth } from "../../context/auth.js";
import axios from "axios";
import { BackIcon } from "../../assets/svg/svgIcons.jsx";
import { useAppearance } from "../../context/AppearanceContext";
import { UseRemoveMapShape } from "../../customHooks";
import { postData } from "../../helper/api/requests.ts";
import { useDraggableContext } from "../../context/DraggableContext";
import AccessibleButton from "../../component/Accessibility/accessibility.jsx";
import queryString from "query-string";
import {
  AUTH_BASE_URL,
  REDIRECT_lOGIN_URL,
} from "../../helper/api/constants.ts";

const Profile = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { distanceDragged } = useDraggableContext();
  const location = useLocation();

  UseRemoveMapShape();
  const {
    setDisaplayMakaniMarker,
    setSelectedFavoriteMarker,
    setSelectedMakaniMarker,
    setDisplayMarker,
  } = useFavorites();
  const { code } = queryString.parse(location.search);

  useEffect(() => {
    setDisaplayMakaniMarker(false);
    setSelectedFavoriteMarker(-1);
    setSelectedMakaniMarker(-1);
    setDisplayMarker(false);
  }, []);
  const [isMobileView, setIsMobileView] = useState(false);
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const {
    token,
    setSessionId,
    setUserId,
    sessionId,
    userId,
    url,
    user,
    setUser,
  } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("UserId");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("user");
    setSessionId("");
    setUserId("");
    navigate("/login");
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1100);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchUserInfo = async (access_token) => {
    try {
      /*const formData = new FormData();
      formData.append("access_token", access_token);
      const response = await axios.post(
        `${AUTH_BASE_URL}/get_user_info.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );*/

      const response = await axios.post(
        `${AUTH_BASE_URL}/get-user-info`,
        {
          "AccessToken": access_token,
      },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let data = response.data;
      // console.log("User ID and Session ID :", data);
      let authUserInfo = await postData(
        {
          idType: null,
          sub: data.sub,
          lastnameEN: data.lastnameEN,
          lastnameAR: data.lastnameAR,
          firstnameEN: data.firstnameEN,
          firstnameAR: data.firstnameAR,
          nationalityAR: data.nationalityAR,
          idn: data.idn,
          userType: data.userType,
          fullnameAR: data.firstnameAR
            ? data.lastnameAR
              ? `${data.firstnameAR} ${data.lastnameAR}`
              : data.firstnameAR
            : null,
          email: data.email,
          fullnameEN: data.fullnameEN,
          domain: null,
          nationalityEN: data.nationalityEN,
          gender: data.gender,
          uuid: data.uuid,
          mobile: data.mobile,
        },
        token
      );
      localStorage.setItem("sessionId", JSON.stringify(authUserInfo.SessionId));
      localStorage.setItem("UserId", JSON.stringify(authUserInfo.UserId));
      setUserId(authUserInfo.UserId);
      setSessionId(authUserInfo.SessionId);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...authUserInfo,
          fullnameAR: data.firstnameAR
            ? data.lastnameAR
              ? `${data.firstnameAR} ${data.lastnameAR}`
              : data.firstnameAR
            : null,
          mobile: data.mobile,
        })
      );
      setUser(authUserInfo);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async (code, state) => {
      //const formData = new FormData();
      //formData.append("code", code);
      //formData.append("redirect_uri", REDIRECT_lOGIN_URL);

      try {
        /*const response = await axios.post(
          `${AUTH_BASE_URL}/get_access_token.php`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );*/

         const response = await axios.post(
          `${AUTH_BASE_URL}/get-access-token`,
          {
            Code: code, // sending as raw JSON data
            RedirectUri:REDIRECT_lOGIN_URL
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.access_token) {
          fetchUserInfo(response.data.access_token);
        } else {
          throw "Not found";
        }
        // const response = await axios.post(
        //   `https://stg-id.uaepass.ae/idshub/token?grant_type=authorization_code&redirect_uri=${encodeURIComponent(
        //     REDIRECT_lOGIN_URL
        //   )}&code=${encodeURIComponent(code)}&state=${state}`,
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //       Authorization: `Basic ${process.env.REACT_APP_UAE_PASS_SECRET_KEY}`,
        //     },
        //   }
        // );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const { code, state } = queryString.parse(location.search);
    if (code) {
      fetchData(code, state);
    }
  }, [location.search]);

  useEffect(() => {
    const calculatedHeight = 155 - distanceDragged;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    const { code } = queryString.parse(location.search);

    if ((!sessionId || !userId) && !code) {
      return navigate("/login");
    } else if (sessionId && userId) {
    }
  }, [sessionId, userId]);

  return (
    <div
      className="sideBarContent inner-spacing-01"
      style={{
        padding: isMobileView ? " " : "",
      }}
    >
      {!isMobileView && <PageHeaderTitle title={t("profile")} />}
      <div className="backPage-sm-side">
        <BackIcon />
      </div>
      <div className="userInfo">
        {isMobileView && (
          <div className="signWarper" onClick={() => navigate("/")}>
            <div className="close_btn">
              <img src={signClose} alt="close" />
            </div>
          </div>
        )}
        <div className="userInfoDetails">
          {/* <div>
            <img src={userAvatar} alt='avater' />
          </div> */}
          <div className="userDetailsWarper">
            <p className="userName">
              {i18n.language === "ar"
                ? JSON.parse(localStorage.getItem("user"))?.fullnameAR ||
                  user.UserName
                : user.UserName}
            </p>
            {JSON.parse(localStorage.getItem("user"))?.mobile && (
              <p className="userDetails">
                {JSON.parse(localStorage.getItem("user"))?.mobile}
              </p>
            )}
            <p className="userDetails">{user.Email}</p>
          </div>
        </div>
      </div>

      <div
        className="profileCards"
        style={{
          overflowY: "auto",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
        }}
      >
        <AccessibleButton
          ariaLabel={t("Click here for my Makani cards")}
          onClick={() =>
            navigate("/profile/myMakaniCards", {
              state: {
                from: "/profile",
              },
            })
          }
        >
          <ProfileCard
            path={"/profile/myMakaniCards"}
            title={t("myMakaniCards")}
            img={makaniCard}
            imgDark={makaniCardDark}
            hasArrow={true}
            newClass="mycard"
          />
        </AccessibleButton>
        <AccessibleButton
          ariaLabel={t("Click here for my favourites")}
          onClick={() =>
            navigate("/favorites", {
              state: {
                from: "/profile",
              },
            })
          }
        >
          <ProfileCard
            path={"/favorites"}
            title={t("myFaviourites")}
            img={profileFavoruites}
            imgDark={profileFavoruitesDark}
            hasArrow={true}
          />
        </AccessibleButton>
        <AccessibleButton
          ariaLabel={t("Click here for settings")}
          onClick={() => navigate("/settings")}
        >
          <ProfileCard
            path={"/settings"}
            title={t("settings")}
            img={profileSettingPage}
            imgDark={profileSettingDark}
            hasArrow={true}
          />
        </AccessibleButton>
        <AccessibleButton
          ariaLabel={t("Click here to logout")}
          onClick={() => {
            handleLogout();
          }}
        >
          <ProfileCard
            path={"#"}
            title={t("logout")}
            img={logout}
            imgDark={logOutDarkMode}
            hasArrow={false}
          />
        </AccessibleButton>
      </div>
      <div class="copy_right top-spacing">{t("Copyright")}</div>
    </div>
  );
};

export default Profile;

//10:12
