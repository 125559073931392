import React, { createContext, useContext, useState, useRef } from "react";
const IndoorNavigationContext = createContext();

export const useIndoorNavigation = () => useContext(IndoorNavigationContext);

const IndoorNavigationProvider = ({ children }) => {
  const [indoorSvgs, setIndoorSvgs] = useState([]);
  const [currentSvgIndex, setCurrentSvgIndex] = useState(0);
  const svgContainerRef = useRef(null);
  const [svgEelement, setSvgEelement] = useState(null);
  const [totalSteps, setTotalSteps] = useState(0);
  const [stepsCounter, setStepsCounter] = useState(1);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState(null);
  const [selectRoute, setSelectedRoute] = useState("");
  const [isPathActive, setIsPathActive] = useState(false);
  const [isPathDrawing, setIsPathDrawing] = useState(false);
  const [clickedNode, setClickedNode] = useState({ input: null, value: null });
  const [showSvg, setShowSvg] = useState(false);
  const [buildingId, setBuildingId] = useState(null);
  const [sourcePath, setSourcePath] = useState(null);
  const [destinationPath, setDestinationPath] = useState(null);
  const [isPathLoading, setIsPathLoading] = useState(false);
  const [routeNotFound, setRouteNotFound] = useState(null);
  const [distanceAndFloor, setDistanceAndFloor] = useState(null);
  const [floors, setFloors] = useState(null);
  const [steps, setSteps] = useState(null);

  const value = {
    setIndoorSvgs,
    indoorSvgs,
    currentSvgIndex,
    setCurrentSvgIndex,
    svgContainerRef,
    svgEelement,
    setSvgEelement,
    totalSteps,
    setTotalSteps,
    stepsCounter,
    setStepsCounter,
    origin,
    setOrigin,
    destination,
    setDestination,
    selectRoute,
    setSelectedRoute,
    isPathActive,
    setIsPathActive,
    isPathDrawing,
    setIsPathDrawing,
    clickedNode,
    setClickedNode,
    showSvg,
    setShowSvg,
    buildingId,
    setBuildingId,
    sourcePath,
    setSourcePath,
    destinationPath,
    setDestinationPath,
    isPathLoading,
    setIsPathLoading,
    routeNotFound,
    setRouteNotFound,
    distanceAndFloor,
    setDistanceAndFloor,
    floors,
    setFloors,
    steps,
    setSteps,
  };

  return (
    <IndoorNavigationContext.Provider value={value}>
      {children}
    </IndoorNavigationContext.Provider>
  );
};

export default IndoorNavigationProvider;
