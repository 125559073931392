import * as d3 from "d3";

const plotRoute = (route, svgElement, stepKey, loading, onComplete) => {
  if (loading && !route) {
    return;
  }

  const svg = d3.select(svgElement);
  const lineGenerator = d3
    .line()
    .x((d) => d[0])
    .y((d) => d[1]);

  const parsedStepKey = `step${stepKey}`;
  const stepData = route.find((step) => Object.keys(step)[0] === parsedStepKey);

  if (stepData) {
    const stepDetails = Object.values(stepData)[0].stepDetails;

    const routePoints = stepDetails.map((detail) => {
      const [x, y] = detail.svgXY.split(" ").map((coord) => parseFloat(coord));
      return [x, y];
    });

    const createMarker = (group, color) => {
      group
        .append("circle")
        .attr("r", 8)
        .attr("fill", "white")
        .attr("stroke", color)
        .attr("stroke-width", 2);

      group.append("circle").attr("r", 5).attr("fill", color);
    };

    const startMarkerGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${routePoints[0][0]}, ${routePoints[0][1]})`
      );

    createMarker(startMarkerGroup, "#EB5364");

    const endMarkerGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${routePoints[routePoints.length - 1][0]}, ${
          routePoints[routePoints.length - 1][1]
        })`
      );

    createMarker(endMarkerGroup, "#EB5364");

    const path = svg
      .append("path")
      .attr("d", lineGenerator(routePoints))
      .attr("stroke", "#EB5364")
      .attr("stroke-width", 5)
      .attr("fill", "none")
      .attr("stroke-dasharray", function () {
        return this.getTotalLength();
      })
      .attr("stroke-dashoffset", function () {
        return this.getTotalLength();
      });

    const totalLength = path.node().getTotalLength();

    // Adjusted duration calculation for your specific needs
    const minDuration = 2000;
    const maxDuration = 5000;
    const maxPathLength = 1100;

    // Map the total length to a duration between min and max
    const duration = Math.min(
      Math.max((totalLength / maxPathLength) * maxDuration, minDuration),
      maxDuration
    );

    const animatePath = () => {
      path
        .transition()
        .duration(duration)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0)
        .on("end", function () {
          if (onComplete) onComplete();

          d3.timeout(() => {
            path
              .transition()
              .duration(0)
              .attr("stroke-dashoffset", totalLength)
              .on("end", animatePath);
          }, 3000);
        });
    };

    animatePath();
  }
};

export default plotRoute;
