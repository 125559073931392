import { useRef, useEffect, useState } from 'react';
import { checked } from '../../assets/index';

import { useTranslation } from 'react-i18next';
import {useFavorites} from '../../context/FavouritesContext'
import './style.css';

const SortDropDown = ({
  sortToggle,
  setSortToggle,
  selectedIndex,
  setSelectedIndex,
  handleSort
}) => {
  const dropdownRef = useRef(null);
  const {
    locations,
    setLocations,
    setFavoritesMarker
  } = useFavorites();

  const [t, il8n] = useTranslation();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSortToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  return (
    <div
      ref={dropdownRef}
      className='sortDropDown'
      style={{
        left: il8n.language === 'ar' && '35px',
        right: il8n.language === 'en' && '35px',
        display: sortToggle ? 'block' : 'none',
      }}
    >
      <ul>
        {[{
          text:t('dateAdded'),
          value: "date"
        },{
          text: t('distance'),
          value:'distance'
        },{
          text: t('alphabetically'),
          value :"alphabetically"
        }].map(
          (item, index) => (
            <li
              onClick={() =>{

                setSelectedIndex(index)
                handleSort(item.value)
              }}
              style={{
                flexDirection: il8n.language === 'ar' && 'row-reverse',
              }}
            >
              <img
                src={checked}
                alt='checked'
                style={{
                  visibility: selectedIndex !== index && 'hidden',
                }}
              />
              <p
                style={{
                  width: il8n.language === 'ar' && '70%',
                }}
              >
                {item.text}
              </p>
            </li>
          )
        )}
      </ul>
    </div>
  );
};
export default SortDropDown;
