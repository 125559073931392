import { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Home,
  SignUp,
  SearchResult,
  Settings,
  SearchByBuildingAddress,
  Favorites,
  Direction,
  BuildingAddress,
  Makani,
  SearchByLandNumber,
  UploadQR,
} from "./pages/index";
import {
  ParentLayout,
  ProfileLayout,
  MyMakaniCards,
  ChildrenLayout,
  MyMakaniCardsList,
  PageHeaderTitle,
} from "./component/index";
import withTour from "./withTour";
import About from "./pages/about/index";
import Legend from "./pages/legend/index";
import Faq from "./pages/faq/index";
import Contact from "./pages/contact/index";
import Disclaimer from "./pages/disclaimer/index";
import Sitemap from "./pages/sitemap/index";
import IndoorNavigation from "./pages/IndoorNavigation/index.jsx";
import { useAuth } from "./context/auth.js";
import { useMapControl } from "./context/MapControllContext.js";

// import UpdateMakaniModel from './component/updateMakaniModel/index';
import { useAppearance } from "./context/AppearanceContext";
import Callback from "./pages/Callback.js";
import MakaniPlateDamage from "./pages/ReportMakaniPlateDamage/index.jsx";

import { makaniCardResponsive, makaniCardResponsiveDark } from "./assets/index";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { initGA, logPageView } from "./analytics";

function App() {
  const { theme } = useAppearance();
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const { sessionId, userId } = useAuth();
  const [t, il8n] = useTranslation();
  const { setCurrentLocationDirection, mapRef } = useMapControl();

  const [currentSlide, setCurrentSlide] = useState(0);

  const [isMobileView, setIsMobileView] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [FavName, setFavName] = useState("");
  const [share, setShare] = useState({
    value: "",
    isShare: false,
  });
  const sidebarRef = useRef(null);
  const sliderRef = useRef(null);
  const [search, setSearch] = useState("");

  // useEffect(() => {

  //   const fetchNearestMakani = async () => {

  //     try {
  //       const location = await getUserLocation();
  //       setCurrentLocationDirection({
  //         lat: location.lat,
  //         lng: location.lng,
  //         BuildingNameEn:(await getLocationName(location.lat,location.lng)) .englishName,
  //         BuildingNameAr: (await getLocationName(location.lat,location.lng  )) .arabicName,
  //       });

  //     } catch (error) {
  //       setCurrentLocationDirection({
  //         lat:25.2642691390032, lng: 55.3119033649999,
  //         BuildingNameEn:(await getLocationName(25.2642691390032,55.3119033649999)) .englishName,
  //         BuildingNameAr: (await getLocationName(25.2642691390032,55.3119033649999)) .arabicName,
  //       });
  //     }
  //   };
  //   if(mapRef.current) fetchNearestMakani();
  // }, [mapRef.current]);

  useEffect(() => {
    initGA(`${process.env.GOOGLE_ANALYTICS_KEY}`);
    logPageView();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [lastScrollTop, setLastScrollTop] = useState(0);
  // const [startY, setStartY] = useState(0);
  const [topValue, setTopValue] = useState("calc(100% - 250px)");
  const [startY, setStartY] = useState(null); // To store initial touch position

  const handleTouchMove = (e) => {
    if (startY === null) return;

    const deltaY = e.touches[0].clientY - startY;
    const newPosition = deltaY > 0 ? "100px" : `${50 + deltaY}px`; // Adjust position based on touch movement

    setTopValue("calc(100% - 550px)");
  };

  const handleTouchEnd = () => {
    setStartY(null); // Reset initial touch position
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;

  //     if (scrollTop > lastScrollTop) {
  //       // Scrolling down
  //       setTopValue('calc(100% - 50px)');
  //     } else {
  //       // Scrolling up
  //       setTopValue('calc(100% - 700px)');
  //     }
  //     setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  //   };

  //   const handleTouchMove = (event) => {
  //     const touchY = event.touches[0].clientY;

  //     if (touchY < startY) {
  //       // Moving up
  //       setTopValue('calc(100% - 700px)');
  //     } else {
  //       // Moving down
  //       setTopValue('calc(100% - 50px)');
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   window.addEventListener('touchstart', (event) =>
  //     setStartY(event.touches[0].clientY)
  //   );
  //   window.addEventListener('touchmove', handleTouchMove);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     window.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, [lastScrollTop, startY]);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY); // Store initial touch position
  };
  const cardRef = useState(null);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ParentLayout
            displaySuggestions={displaySuggestions}
            HighPOI={["/searchResult"]}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            sliderRef={sliderRef}
            Requests
            the
            geolocation
            permission
            on
            page
            load
            setShare={setShare}
            share={share}
            sidebarRef={sidebarRef}
            search={search}
            setSearch={setSearch}
          />
        }
      >
        <Route
          path="/"
          element={
            <ChildrenLayout
              topValue={topValue}
              displaySuggestions={displaySuggestions}
              HighPOI={["/searchResult"]}
              HighMarginValue={[
                "/signup",
                "/profile",
                "/settings",
                "/favorites",
              ]}
              sidebarRef={sidebarRef}
            />
          }
        >
          <Route
            path="/"
            element={
              <Home
                handleTouchMove={handleTouchMove}
                handleTouchStart={handleTouchStart}
                setDisplaySuggestions={setDisplaySuggestions}
                displaySuggestions={displaySuggestions}
                setShare={setShare}
                search={search}
                setSearch={setSearch}
              />
            }
          />
          <Route
            path="/map"
            element={
              <Home
                handleTouchMove={handleTouchMove}
                handleTouchStart={handleTouchStart}
                setDisplaySuggestions={setDisplaySuggestions}
                displaySuggestions={displaySuggestions}
                setShare={setShare}
              />
            }
          />
          <Route path="/login" element={<SignUp />} />
          <Route
            path="/searchResult"
            element={
              <SearchResult
                setCurrentSlide={setCurrentSlide}
                sliderRef={sliderRef}
                currentSlide={currentSlide}
              />
            }
          />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/searchByBuildingAddress"
            element={<SearchByBuildingAddress />}
          />
          <Route path="/searchByLandNumber" element={<SearchByLandNumber />} />
          <Route path="/uploadQR" element={<UploadQR />} />

          <Route path="/favorites" element={<Favorites />} />
          <Route
            path="/makani"
            element={<Makani setShare={setShare} setSearch={setSearch} />}
          />

          <Route path="/building-address" element={<BuildingAddress />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legend" element={<Legend />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/direction" element={<Direction />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/indoorNavigation" element={<IndoorNavigation />} />
          <Route
            path="/report-makani-plate-damage"
            element={<MakaniPlateDamage />}
          />
        </Route>
        <Route
          path="/profile"
          element={
            <ProfileLayout
              sidebarRef={sidebarRef}
              path={["/profile/myMakaniCards"]}
            />
          }
        >
          <Route
            path="myMakaniCards"
            element={
              isMobileView ? (
                <div
                  className="sideBarContent swipe-line top-spacing "
                  style={{
                    padding: "",
                  }}
                >
                  <PageHeaderTitle
                    responsiveIcon={
                      theme === "dark"
                        ? makaniCardResponsiveDark
                        : makaniCardResponsive
                    }
                    parent="/profile"
                    title={t("myMakaniCards")}
                  />

                  <MyMakaniCardsList
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    setFavName={setFavName}
                  />
                </div>
              ) : (
                <MyMakaniCards
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  setFavName={setFavName}
                  FavName={FavName}
                  cardRef={cardRef}
                />
              )
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

//18:04
