import React, { useState,useEffect,useRef } from 'react';
import { modelClose } from '../../../assets/index';
import '../AddFavoritesModel/style.css';
import { useTranslation } from "react-i18next";

const AddFavoritesMapModel = ({item,handleAddToFavorite,onFavoriteCancelClick,index}) => {
  const [value, setValue] = useState('');
  const inputRef = useRef(null)
  const [t, il8n] = useTranslation();
  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddToFavorite(item,value,index)
    }
  };

  return (
    <div className='AddFavoriteModel'>
      <div className='AddFavoriteModelHeader'>
        <p>
        {t('favouriteName')}
        </p>
        <div
          className='closeFavoriteWarper'
          onClick={() =>{
            onFavoriteCancelClick()
          }
       
          }
        >
          <img src={modelClose} alt='close' />
        </div>
      </div>
      <div className='AddFavoriteContent'>
        <div className='input-cover'>
          <input
                ref={inputRef}
            className='AddInput'
            placeholder={t('Name')}
            // placeholder={item.makaniNumber}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div
          className='addBtn'
          onClick={() => {
            handleAddToFavorite(item,value,index)
       
          }}
        >
          <p>{t('save')}</p>
        </div>
      </div>
    </div>
  );
};

export default AddFavoritesMapModel;
