import {makaniCloseIcon} from '../../../assets/index';
import { useToggle } from '../../../context/ToggleModel.js';
import { useTranslation } from "react-i18next";
const AllowAccessModel=()=>{
  const [t, il8n] = useTranslation();
    const {setAllowAccessLocation} =useToggle()
    return (        
          <div className='delete-m-card loginPopUp allowAccess'>
        <div className="d-head">
        <span  style={{
          marginRight: il8n.language ==="ar" && "auto",
          marginLeft: il8n.language ==="ar" && "unset"
        }}><strong> {t('userDeniedGeolocations')} </strong></span>
          <button className='close_btn'
           style={{
          marginRight: il8n.language ==="ar" && "auto",
          marginLeft: il8n.language ==="ar" && "unset"
        }}
          onClick={()=>setAllowAccessLocation(false)}
          ><img src={makaniCloseIcon} alt="close button" /></button>
        </div>
        <div className="body-content">

          <p className="allowAccessDesc">{t('pleaseEnableLocationAccessToUseThisFeature')}</p>
          <div className="btn__brp">
          </div>
        </div>
       </div>)
}
export default AllowAccessModel;