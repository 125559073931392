import { useAppearance } from '../../context/AppearanceContext';
import '../../scss/common.scss';
const FontSelector = () => {
  const { fontSize, setFontSize } = useAppearance();
  return (
    <div>
      <div className='font-controller'>
        <div className='f-small'>A</div>
        <div className='font-size-row'>
          {['-2', '-1', '0', '1', '2'].map((item) => {
            return (
              <div
                className={`f-size ${fontSize === item && 'active'} `}
                onClick={() => setFontSize(item)}
              ></div>
            );
          })}
        </div>
        <div className='f-large'>A</div>
      </div>
    </div>
  );
};

export default FontSelector;
