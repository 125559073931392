import React, { useEffect, useState } from 'react';
import { useLocation, Outlet ,useNavigate} from 'react-router-dom';
import { menuIcon, logo } from '../../assets/index.js';

import { Profile } from '../../pages/index.js';
import { useAppearance } from '../../context/AppearanceContext.js';
import './style.css';
import Draggable from 'react-draggable';
import { useDraggableContext } from '../../context/DraggableContext.js';
import {useToggle} from '../../context/ToggleModel.js'
import { MOBILE_VIEW_DRAGGABLE_HEIGHT_PERCENT, MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants.js';
import { useTranslation } from "react-i18next";
const ProfileLayout = ({  path ,sidebarRef}) => {
  const { theme } = useAppearance();
  const { setDistanceDragged } = useDraggableContext();
  const [isMobileView, setIsMobileView] = useState(false);
  const location = useLocation();
  const [t,i18n] = useTranslation();
  //not using the existing mobile view logic, as it is not working as expected
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [dragBound, setDragBound] = useState({ top: 0, bottom: 125 });

  function handleUpdatesOnDrag (_e, data) {
    setDistanceDragged(data.y);
  }
  const {handleMenuIcon}=useToggle();
  const navigate = useNavigate();

  useEffect(() => {
    dragSideBar();
    setDistanceDragged(12);
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };
    isMobileView && handleResize();

    window.addEventListener('resize', handleResize);

    return () => {  
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const dragSideBar = () => {
    setIsMobileScreen(window.innerWidth <= 767);
    //for setting the drag up limit
    if (window.innerWidth <= 767) {
      const screenHeight = window.innerHeight;
      const draggable_height = MOBILE_VIEW_DRAGGABLE_HEIGHT_PERCENT / 100;
      const halfScreenHeight = screenHeight * draggable_height;
      const sideBarHeight =  250;
      const dragTop = sideBarHeight - halfScreenHeight;
      if(halfScreenHeight > sideBarHeight) {
        // Setting the drag up limit only if the bottom bar height is less than half of the screen height
        setDragBound({top: dragTop, bottom: 200});
      }
    }
  }

  
  return (
    <>
      <Draggable
        axis="y"
        handle=".dragger"
        disabled={isMobileScreen ? false : true}
        bounds = {{top: dragBound.top, bottom: dragBound.bottom}}
        onDrag={handleUpdatesOnDrag}
      >
       
       <div
          className={`sideBar  ${ location.pathname ==='/profile/myMakaniCards' &&'ipad-responsive' }   `}
          ref={sidebarRef}
        >
          <div
            className='dragger'
            style={{
              height: MOBILE_VIEW_DRAGGER_HEIGHT
            }}
          ></div>
          <div className='sideBarHeader'>
            <div>
              <img
                src={menuIcon}
                alt='menuIcon'
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleMenuIcon()}
              />
            </div>
            <div className='logo'>
            <img src={logo} alt='logo'  onClick={()=>navigate('/')}       
             style={{
            cursor:"pointer"
          }} />
              <p class='header-title'>{t('geographicAddressingSystem')}</p>
            </div>
          </div>
   
        {
          window.innerWidth <= 767 && path.includes(location.pathname)   ? <Outlet />:<>

           <Profile />
          {
            path.includes(location.pathname)  && <Outlet />
          }
        
          </>
        }
     
      

        </div>
      </Draggable>
 
    </>
  );
};

export default ProfileLayout;
