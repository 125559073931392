import { getDistance } from 'geolib';

export const Units = {
  KM: 'km',
  MILE: 'mile'
};

const calculateDistanceBetweenCoordinates = (location1, location2, unit) => {

const toRadians = (degrees) => degrees * (Math.PI / 180);


const R = 6371; // Earth's radius in kilometers
const dLat = toRadians(location2.lat - location1.lat);
const dLon = toRadians(location2.lng - location1.lng);
const a =
  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  Math.cos(toRadians(location1.lat)) * Math.cos(toRadians(location2.lat)) *
  Math.sin(dLon / 2) * Math.sin(dLon / 2);
const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
let distanceInKm = R * c;


if (unit === Units.KM) {
  return distanceInKm;
} else if (unit === Units.MILE) {
  return distanceInKm * 0.621371;
}
};



// Correctly export the function
export default calculateDistanceBetweenCoordinates;


// return <div>Distance: {distance.toFixed(2)} {unit === Units.KM ? 'km' : 'miles'}</div>;