import React, { useEffect, useState } from 'react';
import {
  PageHeaderTitle,
  MakaniDetails as MakaniDetailsComponent,
  MakaniCard,
} from '../../component';
import '../../scss/common.scss';
import '../../scss/article.scss';
import '../../scss/faq.scss';
import AccessibleButton from '../../component/Accessibility/accessibility';
import { arrow } from '../../assets/index';
import { arrowUp } from '../../assets/index';
import { UseRemoveMapShape } from '../../customHooks';
import { useLocation, Outlet } from 'react-router-dom';
import { useDraggableContext } from '../../context/DraggableContext';
import { MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants';
import { useTranslation } from 'react-i18next';
import { SpeechSynthesisButton } from '../../component/index';
const Faq = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { distanceDragged } = useDraggableContext();
  const location = useLocation();
  const [t, il8n] = useTranslation();
  UseRemoveMapShape();

  const toggleFaq = (index) => {
    // console.log(`Toggling faq at : ${index}`);
    setExpandedFaq(expandedFaq === index ? null : index);
  };
//   useEffect(() => {
//     console.log(`Expanded faq: ${expandedFaq}`);
// }, [expandedFaq]);

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() =>{
    const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  const textToRead = [
    `${t('faq')}`,
    `${t('WHAT_IS_MAKANI')}: ${t('faq_answer1')}`,
    `${t('WHAT_IS_GEO_ADDRESS')}: ${t('faq_answer2')}`,
    `${t('IS_MAKANI_GOING_REPLACE')}: ${t('faq_answer3')}`,
    `${t('IS_MAKANI_GOING_IMPLEMENTED')}: ${t('faq_answer4')}`,
    `${t('ON_WHAT_PLATFORM_MAKANI')}: ${t('faq_answer5')}`,
    `${t('HOW_FIND_BUILDING_MAKANI')}: ${t('faq_answer6')}`,
    `${t('CAN_SEARCH_SHOPS_POINT')}: ${t('faq_answer7')}`,
    `${t('WHY_LONG_DIGIT_NUMBER')}: ${t('faq_answer8')}`,
    `${t('CAN_I_USE_MAKANI_WITHOUT_INTERNET')}: ${t('faq_answer9')}`,
    `${t('WHAT_IS_GEO-TAGGED_PHOTO')}: ${t('faq_answer10')}`
  ].join(' ');

  return (
    <div className='sideBarContent inner-content inner-article swipe-line'>
      <div className='PageHeaderTitleWarper'>
        <PageHeaderTitle title={t('faq')} parent={location?.state?.parent}  fromMenu={location?.state?.fromMenu} />
        <SpeechSynthesisButton text={textToRead}/>
      </div>
      
      <div
        className="content-cover"
        style={{
          overflowY: isMobileScreen ? 'auto' : '',
          height: isMobileScreen ? `${sideBarContentHeight}px` : 'auto', // added scroll ability for mobile screen
        }}  
      >
        <div className="faq-cover-main">
          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(0)}>
           {t('WHAT_IS_MAKANI')}
              <span className={`arrow`}>
                {expandedFaq === 0 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 0 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer1')}</p>
            </div>
          </div>
          
          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(1)}>
              {t('WHAT_IS_GEO_ADDRESS')}
              <span className={`arrow`}>
              {expandedFaq === 1 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 1 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer2')}</p>
            </div>
          </div>
          
          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(2)}>
              {t('IS_MAKANI_GOING_REPLACE')}
              <span className={`arrow`}>
              {expandedFaq === 2 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 2 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer3')}</p>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(3)}>
            {t('IS_MAKANI_GOING_IMPLEMENTED')}
              <span className={`arrow`}>
              {expandedFaq === 3 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 3 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer4')}</p>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(4)}>
            {t('ON_WHAT_PLATFORM_MAKANI')}
              <span className={`arrow`}>
              {expandedFaq === 4 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 4 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer5')}</p>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(5)}>
            {t('HOW_FIND_BUILDING_MAKANI')}
              <span className={`arrow`}>
              {expandedFaq === 5 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 5 ? 'expanded' : 'collapsed'}`}>
            <p>{t('faq_answer6')}</p>
<ul>
  <li>{t('faq_option1')}</li>
  <li>{t('faq_option2')}</li>
  <li>{t('faq_option3')}</li>
</ul>

            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(6)}>
            {t('CAN_SEARCH_SHOPS_POINT')}
              <span className={`arrow`}>
              {expandedFaq === 6 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 6 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer7')}</p>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(7)}>
                  {t('WHY_LONG_DIGIT_NUMBER')}
              <span className={`arrow`}>
              {expandedFaq === 7 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 7 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer8')}</p>
              <ul>
                <li> {t('faq-8-option1')}</li>
                <li> {t('faq-8-option2')}</li>
                <li> {t('faq-8-option3')}</li>
                <li> {t('faq-8-option4')}</li>

              </ul>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(8)}>
            {t('CAN_I_USE_MAKANI_WITHOUT_INTERNET')}
              <span className={`arrow`}>
              {expandedFaq === 8 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 8 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer9')}</p>
            </div>
          </div>

          <div className="content-faq">
            <div className="faq-head" onClick={() => toggleFaq(9)}>
                {t('WHAT_IS_GEO-TAGGED_PHOTO')}
              <span className={`arrow`}>
              {expandedFaq === 9 ? <img src={arrowUp} alt='arrowUp'></img> : <img src={arrow} alt='arrow'></img>}
              </span>
            </div>
            <div className={`para ${expandedFaq === 9 ? 'expanded' : 'collapsed'}`}>
              <p>{t('faq_answer10')}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="copy_right">{t('Copyright')}</div>
    </div>
  );
};

export default Faq;
