import { useState ,useEffect,useRef} from 'react';
import { PageHeaderTitle, Upload } from '../../component';
import '../../scss/common.scss';
import '../../scss/article.scss';
import { useTranslation } from 'react-i18next';
import { LocationPoint, FileUploadIcon } from '../../assets/svg/svgIcons.jsx';
import { useReportMakani } from '../../customHooks/index';
import { useDraggableContext,useAuth } from '../../context/index.js';
import { MOBILE_VIEW_DRAGGER_HEIGHT } from '../../helper/appConstants.js';
import {convertToEasternArabicNumerals,convertToWesternArabicNumerals} from '../../helper/index.js'
import {GetSmartSearchResult} from '../../helper/api/requests.ts'


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const MakaniPlateDamage = () => {
  const [t,i18n] = useTranslation();
  const [file, setFile] = useState(null);
  const [fileDataUrl, setFileDataUrl] = useState(null);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [makaniNumber, setMakaniNumber] = useState('');
  const [location, setLocation] = useState('123,123');
  const [errors, setErrors] = useState({});
  const [isGreen , setIsGreen]=useState(false)
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { distanceDragged } = useDraggableContext();
  const { fetchData, result, loading, error } = useReportMakani();
  const prevMakaniNumber =usePrevious(makaniNumber)
  const [localLocading,setLocalLoading]=useState(false)
  const inputRef = useRef(null);
  const {token,getToken,setToken}=useAuth()


  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };
  useEffect(()=>{
    let user =JSON.parse(localStorage.getItem('user'))
    if(user){
      setEmail(user.Email || '')
      setMobile(user.mobile || '')
    }
   
  },[])
  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

useEffect(() => {
  const validateAndSetStatus =  () => {
    const validationErrors =  validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setIsGreen(true);
    } else {
      setIsGreen(false);
    }
  };

  validateAndSetStatus(); 

}, [email, mobile, fileDataUrl, makaniNumber]);


  const handleChange = async (event) => {
    const file = event.target.files[0];
    setFile(file.name);
    if (file) {
      try {
        const imageDataUrl = await readFile(file);
        setFileDataUrl(imageDataUrl);
      } catch (error) {
        console.error('Error reading file.', error);
      }
    }
  };
  const validateMakaniNumber = async (makaniNumber, token) => {
    try {
      let searchResult = await GetSmartSearchResult(2, makaniNumber, token);
      if (searchResult.isExp) {
        let newToken = await getToken();
        localStorage.setItem("token", JSON.stringify(newToken));
        setToken(newToken);
        let searchResult = await GetSmartSearchResult(2, makaniNumber, newToken);
      }

      
      return searchResult.error
    } catch (error) {
      
      return true
    }
  };
  

  const validateForm =  () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^05\d{8}$/;
    

    if (!email) {
      newErrors.email = t("Required_Field")
    } else if (!emailRegex.test(email)) {
      newErrors.email = t("incorrect_email_address")
    }

    if (!mobile) {
      newErrors.mobile = t("Required_Field")
    }else if (!mobileRegex.test(mobile)) {
      newErrors.mobile = t('Mobile_format_is_incorrect')
    }
    if (!makaniNumber) newErrors.makaniNumber =t("Required_Field");
  

    if (!fileDataUrl) newErrors.file = t("Required_Field")
    
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors =  validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
 
    else {
      setLocalLoading(true)
      if(await validateMakaniNumber(makaniNumber,token)){
        setErrors({
          ...errors,
          makaniNumber: t("invalid_makani_number")
          
        })
        setLocalLoading(false)
        return ;
      }
      
    }

    const data = {
      email,
      mobile :mobile,
      makaniNumber:makaniNumber,
      location,
      imageBase64: fileDataUrl,
    };

    try {
      setErrors({})
      let response = await fetchData(data);

      setEmail('');
      setMobile('');
      setMakaniNumber('');
      setFile(null);
      setFileDataUrl(null);
      setErrors({});
     
      setLocalLoading(false)
    } catch (error) {
      console.error('Error submitting report:', error);
    }
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() =>{
    const calculatedHeight = 180 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  return (
    <div className='sideBarContent inner-content inner-article swipe-line'>
      <div className='PageHeaderTitleWarper'>
        <PageHeaderTitle title={t('reportMakaniPlateDamage')} />
      </div>
      <div
        className="content-cover"
        style={{
          overflowY: isMobileScreen ? 'auto' : '',
          height: isMobileScreen ? `${sideBarContentHeight}px` : 'auto', // added scroll ability for mobile screen
        }}  
      >
        <div className="form__box">
          <div className="form-group mb">
            <input
            ref={inputRef}
              className='formcontrol'
              type="email"
              placeholder={t('emailaddress')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <div className="validationError">{errors.email}</div>}
          </div>
          <div className="form-group mb">
            <input
              className='formcontrol'
              type="text"
              placeholder={t('mobile')}
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value)
                // if(i18n.language === "ar"){
                //   const inputValue = e.target.value;
                //   setMobile(convertToEasternArabicNumerals(inputValue))
                // }else{
                 
                // }
              }
              
             }
              required
            />
            {errors.mobile && <div className="validationError">{errors.mobile}</div>}
          </div>
          <div className="form-group mb">
            <input
              className='formcontrol'
              type="text"
              placeholder={t('Makani Number')}
              value={makaniNumber}
              required
              onChange={(e) =>{
                setMakaniNumber(e.target.value);
              //   if(i18n.language === "ar"){
              //   const inputValue = e.target.value;
              //   setMakaniNumber(convertToEasternArabicNumerals(inputValue))
              // }
              // else{
          
              // }
              }
              }
            />
            {errors.makaniNumber && <div className="validationError">{errors.makaniNumber}</div>}
          </div>
          <div className="form-group mb">
            <div className="file-upload-bx formcontrol">
              <div className="upload-and-content">
                <FileUploadIcon />
                <span>{t('Upload Photo')}</span>
                <Upload fileName={file} handleChange={handleChange} />
              </div>
            </div>
            {errors.file && <div className="validationError">{errors.file}</div>}
          </div>
          <button className="btnsend" onClick={handleSubmit} disabled={loading} style={{
            background : isGreen && "green"
          }}>
            {localLocading || loading ? t('Sending') : t('Send')}
          </button>
        </div>
      </div>
      <div class="copy_right">{t('Copyright')}</div>
    </div>
  );
};

export default MakaniPlateDamage;

