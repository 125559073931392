import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const AccessibleButton = ({ className, onClick, ariaLabel, children }) => {
  const buttonRef = useRef(null);
  const liveRegionRef = useRef(null);

  useEffect(() => {
    const handleMouseEnter = () => {
      if (liveRegionRef.current) {
        liveRegionRef.current.textContent = ariaLabel;
      }
    };

    const handleFocus = () => {
      if (liveRegionRef.current) {
        liveRegionRef.current.textContent = ariaLabel;
      }
    };

    const element = buttonRef.current;
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('focus', handleFocus);

      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('focus', handleFocus);
      };
    }
  }, [ariaLabel]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <>
      <div
        ref={buttonRef}
        className={className}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        tabIndex="0"
        role="button"
        aria-label={ariaLabel}
      >
        {children}
      </div>
      <div
        ref={liveRegionRef}
        aria-live="assertive"
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          border: '0',
          padding: '0',
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          clipPath: 'inset(100%)',
          whiteSpace: 'nowrap',
        }}
      ></div>
    </>
  );
};

AccessibleButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AccessibleButton;
