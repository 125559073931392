import { useState, useEffect } from 'react';
import { GetAllPOIsOfCategoriesAPI } from '../helper/api/requests.ts';
import { useAuth } from '../context/auth.js';

const useGetAllPOIsOfCategoriesFetching = (setPOIs, POIs) => {
  const { token,getToken,setToken } = useAuth();
  const GetAllPOIsOfCategories = async (categoryId) => {
    try {
      setPOIs({
        ...POIs,
        [categoryId]: {
          loading: true,
        },
      });
      let result = await GetAllPOIsOfCategoriesAPI(categoryId, token);
      if(result.isExp){
        let newToken= await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result=   await GetAllPOIsOfCategoriesAPI(
          categoryId,
          newToken
        );
      }


      setPOIs({
        ...POIs,
        [categoryId]: {
          loading: false,
          data: result,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    GetAllPOIsOfCategories,
  };
};

export default useGetAllPOIsOfCategoriesFetching;
