'react';
import { Link } from 'react-router-dom';
import { rightArrowProfile, rightArrowProfileAr } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import { useAppearance } from '../../context/AppearanceContext';
const ProfileCard = ({ path, title, imgDark, img, hasArrow, newClass }) => {
  const [il8n] = useTranslation();
  const { theme } = useAppearance();
  return (
    <Link style={{ textDecoration: 'none' }} to={path}>
      <div className='ProfileCardWarper'>
        <div className='profileWrapperLeft'>
          <div className={`profileWrapper ${newClass == 'mycard' ? 'card-active' : ''}`}>
            
            {theme === 'dark' ? (
              <img src={imgDark} alt='makani Card' />
            ) : (
              <img src={img} alt='makani Card' />
            )}
            
          </div>
          <p
            className='profileCardTitle'
            style={{
              color: !hasArrow && '#c82b28',
            }}
          >
            {title}
          </p>
        </div>
        {hasArrow && (
          <div>
            <img
              src={
                il8n.language === 'ar' ? rightArrowProfileAr : rightArrowProfile
              }
              alt='arrow'
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default ProfileCard;
