import { PageHeaderTitle } from "../../component";
import {
  makaniPageLogo,
  navigateIcon2,
  email,
  website,
  phone,
} from "../../assets/index";

import {
  ShareIcons,
  StreetView,
  DirectionsIcon,
} from "../../assets/svg/svgIcons";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMapControl,usePOICategories,useAuth } from "../../context/index.js";
import { useLongPressFetching } from "../../customHooks/index.js";
import "./style.css";
import "../../scss/common.scss";
import { calculateDistanceBetweenCoordinates } from "../../helper/index.js";
const Makani = ({ setShare, setSearch }) => {
  const [t, il8n] = useTranslation();
  const { selectedPoi, poi } = usePOICategories();

  const { setStreetLevel } = useMapControl();
  const navigate = useNavigate();
  const { LongPressShareLocation } = useLongPressFetching(setShare);
  const { token } = useAuth();
  const {  currentLocationDirection } = useMapControl();

  return (
    <div
      className="sideBarContent swipe-line this-page-spacing"
      style={{
        padding: "",
      }}
    >
      <div className="PageHeaderTitleWarper">
        <PageHeaderTitle title={""} />
      </div>

      {true && (
        <>
          <div className="searchTitleWarper">
          {
            (il8n.language === "ar"
                ? poi[selectedPoi]?.NameA
                : poi[selectedPoi]?.NameE)
                && <p className="searchTitle">
              {il8n.language === "ar"
                ? poi[selectedPoi]?.NameA
                : poi[selectedPoi]?.NameE}
            </p>
          }
            
         
          </div>

          <div className="MakaniPageContent">
            <div className="MakaniPageContentText">
              {/* <p class="searchAddress">{t("nearestParking")}</p> */}
              {(il8n.language === "ar"
                ? poi[selectedPoi]?.SubCategoryL1_A
                : poi[selectedPoi]?.SubCategoryL1_E) && <p class="searchAddress">
              {il8n.language === "ar"
                ? poi[selectedPoi]?.SubCategoryL1_A
                : poi[selectedPoi]?.SubCategoryL1_E}
            </p>
              }
              { poi[selectedPoi]?.Lat &&  poi[selectedPoi]?.Lng &&<p className="MakaniCardContentDesinence">
                <span className="directions">
                  <DirectionsIcon />
                </span>
                <strong className="km-bold">
          
                {
                  parseFloat(calculateDistanceBetweenCoordinates(
            {
              lat: Number(currentLocationDirection.lat),
              lng: Number(currentLocationDirection.lng),
            },
            {
              lat: Number(poi[selectedPoi]?.Lat),
              lng: Number(poi[selectedPoi]?.Lng),
            },
            "km"
          ).toFixed(2))
                }</strong> <span>{t("km")} </span>{" "}
              </p>
            }
            </div>
             
            <div className="DetailsMakaniPageBtns DetailsMakaniCardBtns">
              <div
                className="DetailsMakaniCardBtn"
                onClick={() => {
                  LongPressShareLocation(token, {
                    lat: poi[selectedPoi]?.Lat,
                    lng: poi[selectedPoi]?.Lng,
                  });
                }}
              >
                <ShareIcons />
              </div>

              <div
                className="DetailsMakaniCardBtn"
                onClick={() => {
                  setStreetLevel({
                    lat: poi[selectedPoi]?.Lat,
                    lng: poi[selectedPoi]?.Lng,
                  });
                }}
              >
                <StreetView />
              </div>
              <div
                className="makaniPageLogo"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                  setSearch(
                    il8n.language === "ar"
                      ? poi[selectedPoi]?.NameA.split("-")[0]
                      : poi[selectedPoi]?.NameE.split("-")[0]
                  );
                }}
              >
                <img src={makaniPageLogo} />
              </div>

              <div
                className="navigateWarper"
                style={{
                  width: "149px",
                }}
                onClick={() => {
                  navigate("/direction", {
                    state: {
                      BuildingNameE: poi[selectedPoi]?.NameE,
                      BuildingNameA: poi[selectedPoi]?.NameE,
                      position: {
                        lat: poi[selectedPoi]?.Lat,
                        lng: poi[selectedPoi]?.Lng,
                      },
                    },
                  });
                }}
              >
                <img src={navigateIcon2} alt="navigate" />
                <p className="navigateTitle">{t("navigate")}</p>
              </div>
            </div>
          </div>

          <div className="makaniCardDetails">
            <p className="makaniCardDetailsTitle">{t("details")}</p>
            <div className="makaniCardDetailsAddress">
              <div className="makaniCardDetailsSubAddressWrapper">
                <p className="makaniCardDetailsAddressTitle">{t("address")}</p>
                {/* <p className="makaniCardDetailsSubAddress">
                  {t("buildingNumber")}: 01
                </p> */}
                {/* <p className="makaniCardDetailsSubAddress">{t("street")}:373</p> */}
                {(il8n.language === "ar"
                    ? poi[selectedPoi]?.CommunityA
                    : poi[selectedPoi]?.CommunityE) && <p className="makaniCardDetailsSubAddress">
                  {t("community")}:{" "}
                  {il8n.language === "ar"
                    ? poi[selectedPoi]?.CommunityA
                    : poi[selectedPoi]?.CommunityE}
                </p>
                }
                {/* <p className="makaniCardDetailsSubAddress">
                  {t("emirate")}:{t("dubai")}
                </p> */}
              </div>
              {
                (poi[selectedPoi]?.TelNo || poi[selectedPoi]?.Url || poi[selectedPoi]?.Email)   
                && 
              <div
                className="contactDetailsWrapper"
                style={{
                  textAlign: il8n.language === "ar" && "right",
                }}
              >
                <p
                  style={{
                    textAlign: il8n.language === "ar" && "right",
                  }}
                >
                  {t("contactDetails")}
                </p>
                <div className="contactIconsWarper">
                  {poi[selectedPoi]?.TelNo && (
                    <div className="contactItem">
                      <div className="contactIconWarper">
                        <img src={phone} alt="phone" />
                      </div>
                      <p>{poi[selectedPoi]?.TelNo}</p>
                    </div>
                  )}

                  {poi[selectedPoi]?.Url && (
                    <div className="contactItem">
                      <div className="contactIconWarper">
                        <img src={website} alt="website" />
                      </div>
                      <p>{poi[selectedPoi]?.Url}</p>
                    </div>
                  )}

                  {poi[selectedPoi]?.Email && (
                    <div className="contactItem">
                      <div className="contactIconWarper">
                        <img src={email} alt="email" />
                      </div>
                      <p>{poi[selectedPoi]?.Email}</p>
                    </div>
                  )}
                </div>
              </div>
              }

            </div>
          </div>
        </>
      )}
      <div class="copy_right">{t('Copyright')}</div>
    </div>
  );
};

export default Makani;
