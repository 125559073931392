import React, { useState,useRef,useEffect } from 'react';
import './style.css'; 
import { modelClose } from '../../../assets/index';
import { useFavorites } from '../../../context/FavouritesContext';
import { useTranslation } from "react-i18next";
const AddFavoritesModel = ({setIsMakaniToggle,setIsFavoriteToggle,item,isMakaniToggle,isFavoriteToggle,setIsFavorite,setIsMakani}) => {
  const { fetchSyncFavorites } =
    useFavorites();
    const inputRef = useRef(null)
    const [t, il8n] = useTranslation();
  const [value, setValue] = useState(il8n.language === 'ar' ? reverseAndConcat(item.FavValue) : item.FavValue);
  
  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handleSave = () => {
    item.FavName = value;
    fetchSyncFavorites(
      {
        ...item,
      },
      {
        action: 'add',
      }
    );

    if (isFavoriteToggle) {
      setIsFavorite(true);
      setIsFavoriteToggle(false);
    } else if (isMakaniToggle) {
      setIsMakani(true);
      setIsMakaniToggle(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };
  function reverseAndConcat(input){
    return input.split(' ').reverse().join(' ');
  }


  return (
    <div className='AddFavoriteModel'>
      <div className='AddFavoriteModelHeader'>
        <p>
          {isFavoriteToggle
            ?t('favouriteName')
            :t('makaniName')
          }
        </p>
        <div
          className='closeFavoriteWarper'
          onClick={() =>{
              setIsMakaniToggle(false) 
              setIsFavoriteToggle(false)
          }
          }
        >
          <img src={modelClose} alt='close' />
        </div>
      </div>
      <div className='AddFavoriteContent'>
        <div className='input-cover'>
          <input
          ref={inputRef}
            className='AddInput'
            placeholder={t('Name')}
            // placeholder={item.FavValue}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div
          className='addBtn'
          onClick={handleSave}
        >
          <p>{t('save')}</p>
        </div>
      </div>
    </div>
  );
};

export default AddFavoritesModel;
