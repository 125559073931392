import React, { useEffect, useState, useRef } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { MapContainer, Menu, LoginModel } from "../../index.js";
import ShareModel from "../../models/shareModel/index";
import { useTranslation } from "react-i18next";
import { menuIconResponsive, LogoMain } from "../../../assets/index.js";
import { loadSvg } from "../../../helper/index.js";
import { RightArrow, LeftArrow, ManWalk } from "../../../assets/svg/svgIcons";
import "./style.css";
import withTour from "../../../withTour";
import ReactLoading from "react-loading";
import {
  useAppearance,
  useIndoorNavigation,
  useAuth,
} from "../../../context/index.js";
import { useToggle } from "../../../context/ToggleModel.js";
import DownloadApp from "../../downloadApp";
import Slider from "react-slick";
import MapLayer from "../../models/mapLayer/index.jsx";
import AllowAccessModel from "../../models/allowAccess/index.js";
import { usePOICategories } from "../../../context/POICategoriesContext";
import { useMapControl } from "../../../context/MapControllContext";
import { useGetFloorPlansFetching } from "../../../customHooks";
import threedImage from "../../../assets/images/threedImage.svg";
import { toast } from "react-toastify";
import SettingsAlert from "../../models/settingsAlert/index.js";
import { GetFloorPlans } from "../../../helper/api/requests.ts";
const ParentLayout = ({
  displaySuggestions,
  HighPOI,
  currentSlide,
  sliderRef,
  setCurrentSlide,
  share,
  setShare,
  sidebarRef,
  setSearch,
}) => {
  const location = useLocation();
  const [t, il8n] = useTranslation();
  const {
    makaniCoords,
    settingsAlert,
    shareFromMakaniCard,
    setShareFromMakaniCard,
  } = useMapControl();
  const { loading } = useGetFloorPlansFetching();
  const { fontSize } = useAppearance();
  const { theme, showPopup } = useAppearance();
  const { POICategories, GetAllPOIsOfCategories } = usePOICategories();
  const { token, getToken, setToken } = useAuth();

  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [initialViewBox, setInitialViewBox] = useState([0, 0, 1000, 1000]);

  const {
    svgContainerRef,
    setSvgEelement,
    currentSvgIndex,
    setCurrentSvgIndex,
    isPathActive,
    isPathDrawing,
    setClickedNode,
    showSvg,
    setShowSvg,
    sourcePath,
    destinationPath,
    setIsPathLoading,
    setTotalSteps,
    setStepsCounter,
    setSourcePath,
    setDestinationPath,
    setIsPathDrawing,
    setIsPathActive,
    distanceAndFloor,
    setDistanceAndFloor,
    floors,
    buildingId,
    isPathLoading,
  } = useIndoorNavigation();

  const {
    handleMenuIcon,
    mapLayerToggle,
    menuToggle,
    isRequireLogin,
    allowAccessLocation,
  } = useToggle();

  const [viewBox, setViewBox] = useState([0, 0, 1000, 1000]);
  const [perspective, setPerspective] = useState(false);
  const [isFloorEditing, setIsFloorEditing] = useState(false);
  const [isSvgCardOpen, setIsSvgCardOpen] = useState(false);
  const [clickedText, setClickedText] = useState(null);

  useEffect(() => {
    setIsSvgCardOpen(false);
    setClickedText(null);
    location.pathname === "/indoorNavigation" &&
    location?.state?.buildingid != null
      ? setShowSvg(true)
      : setShowSvg(false);
  }, [location.pathname]);

  //SHAHEEN CODE start***********

  const svgContainerElement = svgContainerRef.current;

  useEffect(() => {
    if (svgContainerElement) {
      svgContainerElement.addEventListener("click", handleSvgClick);
    }

    return () => {
      if (svgContainerElement) {
        svgContainerElement.removeEventListener("click", handleSvgClick);
      }
    };
  }, [svgContainerElement]);

  const handleSvgClick = (event) => {
    const svgContainerElement = svgContainerRef.current;
    if (!svgContainerElement) return;

    const rect = svgContainerElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const svgElement = svgContainerElement.querySelector("svg");
    if (!svgElement) return;

    const element = document.elementFromPoint(event.clientX, event.clientY);

    if (element && element.closest("svg") === svgElement) {
      const textElement = element.closest("text");

      if (textElement) {
        const textContent = textElement.textContent;
        setClickedText(textContent);
        setIsSvgCardOpen(true);
      }
    }
  };

  //SHAHEEN CODE end***********
  // This useEffect is to check if I'm receriving shareFromMakaniCard state correctly from the context
  useEffect(() => {}, [shareFromMakaniCard]);

  const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    variableWidth: true,
    rtl: true, // Enable RTL mode
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDisplayPoi = (categoryId, position, MapIcon, color) => {
    GetAllPOIsOfCategories(categoryId, position, MapIcon, color);
  };

  const zoomIn = () => {
    adjustZoom(0.9);
  };

  const zoomOut = () => {
    adjustZoom(1.1);
  };

  const togglePerspective = () => {
    setPerspective(!perspective);
  };

  const adjustZoom = (scaleFactor) => {
    const [x, y, width, height] = viewBox;
    const newWidth = width * scaleFactor;
    const newHeight = height * scaleFactor;
    const newX = x - (newWidth - width) / 2;
    const newY = y - (newHeight - height) / 2;

    setViewBox([newX, newY, newWidth, newHeight]);
    setInitialViewBox([newX, newY, newWidth, newHeight]);
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartPoint({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const dx = event.clientX - startPoint.x;
    const dy = event.clientY - startPoint.y;

    const [x, y, width, height] = initialViewBox;
    const scaleX = width / svgContainerRef.current.offsetWidth;
    const scaleY = height / svgContainerRef.current.offsetHeight;

    const newX = x - dx * scaleX;
    const newY = y - dy * scaleY;

    setViewBox([newX, newY, width, height]);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setInitialViewBox(viewBox);
  };

  const preventTouchZoom = (event) => {
    if (event.scale !== 1) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("gesturestart", preventTouchZoom);
    window.addEventListener("gesturechange", preventTouchZoom);
    window.addEventListener("gestureend", preventTouchZoom);

    return () => {
      window.removeEventListener("gesturestart", preventTouchZoom);
      window.removeEventListener("gesturechange", preventTouchZoom);
      window.removeEventListener("gestureend", preventTouchZoom);
    };
  }, []);

  useEffect(() => {
    if (svgContainerRef.current) {
      const svgElement = svgContainerRef.current.querySelector("svg");
      if (svgElement) {
        svgElement.setAttribute(
          "viewBox",
          `${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`
        );
      }
    }
  }, [viewBox, svgContainerRef]);

  const handleWheel = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }

    const scaleFactor = event.deltaY > 0 ? 1.1 : 0.9;
    adjustZoom(scaleFactor);
  };

  const handleFloorChange = async (type) => {
    if (isPathActive) {
      setTotalSteps(0);
      setStepsCounter(1);
      setSourcePath(null);
      setDistanceAndFloor(null);
      setDestinationPath(null);
      setIsPathDrawing(false);
      setIsPathActive(false);
    }

    let newIndex = Number(currentSvgIndex);
    let url;

    setIsPathLoading(true);

    if (type === "DOWN") {
      newIndex = Math.max(newIndex - 1, 0);
    } else if (type === "UP") {
      newIndex += 1;
    }

    setCurrentSvgIndex(newIndex);
    const currentFloor = newIndex === 0 ? "800" : String(newIndex);
    const floorRef = floors?.find(
      (floor) => floor?.floor === currentFloor
    ).floorRef;

    let result = await GetFloorPlans(t, token, buildingId, floorRef);

    if (result.isExp) {
      let newToken = await getToken();
      localStorage.setItem("token", JSON.stringify(newToken));
      setToken(newToken);
      result = await GetFloorPlans(t, newToken, buildingId, floorRef);
    }

    if (result.error) {
      if (result.error.includes("Unable to proccess the request")) {
        let errorMessage = t("unable_to_process_the_request");
        toast.error({
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      }
      return;
    }

    let floorData = result?.floor?.find((item) => item.floor == currentFloor);

    if (!floorData) {
      setCurrentSvgIndex(0);
      toast.error(t("floor_not_found"), {
        autoClose: 2000,
        position: il8n.language === "ar" ? "top-left" : "top-right",
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
    }

    url = floorData?.svgUrl;

    if (url) {
      const svgElement = await loadSvg(url);
      const container = svgContainerRef.current;
      container.innerHTML = "";
      container.appendChild(svgElement);
      svgElement.setAttribute("width", "100%");
      svgElement.setAttribute("height", "100%");
      svgElement.setAttribute("viewBox", "0 0 1000 1000");
      setSvgEelement(svgElement);
    }

    setIsPathLoading(false);
  };

  return (
    <div className="rootContainer" data-size={fontSize} data-theme={theme}>
      {location.pathname === "/indoorNavigation" && isSvgCardOpen && (
        <div className="clicked-text-parent">
          <div className="clicked-text">{clickedText} </div>
          <div className="clicked-text-button-div">
            <button
              onClick={() => {
                setClickedNode({ input: "origin", value: clickedText });
                setIsSvgCardOpen(false);
              }}
            >
              {t("start")}
            </button>
            <button
              onClick={() => {
                setClickedNode({ input: "destination", value: clickedText });
                setIsSvgCardOpen(false);
              }}
            >
              {t("finish")}
            </button>
          </div>
        </div>
      )}

      <div
        className="overlay"
        style={{
          opacity: menuToggle || allowAccessLocation ? 1 : 0,
          visibility: menuToggle || allowAccessLocation ? "visible" : "hidden",
        }}
      ></div>
      <Menu theme={theme} />
      <Outlet />
      <div className="mapContainer">
        {mapLayerToggle && <MapLayer />}
        {allowAccessLocation && <AllowAccessModel />}
        {showSvg ? (
          <>
            {loading ? (
              <div className="loading-overlay">
                <ReactLoading type={"balls"} color="#009D91" />
              </div>
            ) : (
              <>
                <div>
                  {isPathActive &&
                    isPathDrawing &&
                    sourcePath &&
                    destinationPath && (
                      <div className="indoor-direction-card">
                        <ManWalk />

                        <div className="text-container">
                          <div className="from-to">
                            {t("from")}{" "}
                            <span
                              style={{
                                fontWeight: "bolder",
                                marginLeft:
                                  il8n.language === "ar" ? "0px" : "1px",
                                marginRight:
                                  il8n.language === "ar" ? "1px" : "0px",
                              }}
                            >
                              {sourcePath.length > 20
                                ? sourcePath.substring(0, 20) + "..."
                                : sourcePath}
                            </span>
                          </div>
                          <div className="destination">
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="to-text"
                            >
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "center",
                                  alignItems: "baseline",
                                }}
                              >
                                {t("to")}{" "}
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    marginLeft:
                                      il8n.language === "ar" ? "0px" : "18px",
                                    marginRight:
                                      il8n.language === "ar" ? "8px" : "0px",
                                  }}
                                >
                                  {destinationPath.length > 20
                                    ? destinationPath.substring(0, 20) + "..."
                                    : destinationPath}
                                </span>
                              </span>
                              {distanceAndFloor?.distance && (
                                <span style={{ marginTop: "8px" }}>
                                  {t("distance")} :{" "}
                                  {Math.trunc(distanceAndFloor?.distance) +
                                    " m "}
                                </span>
                              )}
                              {distanceAndFloor?.floor && (
                                <span style={{ marginTop: "8px" }}>
                                  {t("floor")} :{" "}
                                  {distanceAndFloor?.floor === "800"
                                    ? t("ground_floor")
                                    : distanceAndFloor?.floor}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  <div
                    ref={svgContainerRef}
                    onWheel={handleWheel}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    viewBox={`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`}
                    style={{
                      position:
                        window.innerWidth <= 600 ? "static" : "absolute",
                      left: "50px",
                      right: "50px",
                      width: "100%",
                      height: "100%",
                      transform: perspective
                        ? `perspective(1000px) rotateX(45deg) scale(1.1)`
                        : "none",
                      cursor: isDragging ? "grabbing" : "grab",
                    }}
                    className="svgBackground"
                  />
                  {isPathLoading && (
                    <div className="loading-overlay">
                      <ReactLoading type={"balls"} color="#009D91" />
                    </div>
                  )}
                  <div
                    className={
                      il8n.language === "ar"
                        ? "zoom-controls-svg-ar"
                        : "zoom-controls-svg"
                    }
                  >
                    <button className="zoom-btn-svg" onClick={zoomIn}>
                      +
                    </button>
                    <button className="zoom-btn-svg" onClick={zoomOut}>
                      -
                    </button>
                    <button
                      className="zoom-btn-svg"
                      onClick={togglePerspective}
                    >
                      <img
                        src={threedImage}
                        alt="Perspective"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </button>
                  </div>

                  {/* Floor selector start */}
                  {location.pathname === "/indoorNavigation" &&
                    !isPathDrawing && (
                      <div className="select-floor indoor-broup-btn">
                        <div className="navi-btn start-btn btn__grp">
                          <button
                            className="lft-navi control-btn"
                            style={{
                              color:
                                currentSvgIndex == 0 || isPathDrawing
                                  ? "#717a8e"
                                  : "inherit",
                            }}
                            onClick={async () => {
                              if (currentSvgIndex !== 0 && !isPathDrawing) {
                                handleFloorChange("DOWN");
                              }
                            }}
                          >
                            <LeftArrow />
                          </button>
                          {/* Editable field  start*/}
                          <div>
                            {isFloorEditing ? (
                              <input
                                className="floor-selector"
                                type="number"
                                value={currentSvgIndex}
                                onChange={(e) => {
                                  setCurrentSvgIndex(e.target.value);
                                }}
                                onBlur={() => {
                                  setIsFloorEditing(false);
                                  handleFloorChange("MANUAL");
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    setIsFloorEditing(false);
                                    handleFloorChange("MANUAL");
                                  }
                                }}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  !isPathDrawing && setIsFloorEditing(true)
                                }
                              >
                                {currentSvgIndex == 0 ? (
                                  <>{t("ground_floor")}</>
                                ) : (
                                  <>
                                    {t("floor")}
                                    <strong>{currentSvgIndex}</strong>
                                  </>
                                )}
                              </span>
                            )}
                          </div>
                          {/* Editable field end*/}
                          <button
                            className="rgt-navi control-btn"
                            style={{
                              color:
                                currentSvgIndex == floors?.length - 1 ||
                                isPathDrawing
                                  ? "#717a8e"
                                  : "inherit",
                            }}
                            onClick={async () => {
                              if (
                                currentSvgIndex !== floors?.length - 1 &&
                                !isPathDrawing
                              ) {
                                handleFloorChange("UP");
                              }
                            }}
                          >
                            <RightArrow />{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  {/* Floor selector end */}
                </div>
              </>
            )}
          </>
        ) : (
          <MapContainer
            currentSlide={currentSlide}
            sliderRef={sliderRef}
            setCurrentSlide={setCurrentSlide}
            setShare={setShare}
            sidebarRef={sidebarRef}
            setSearch={setSearch}
          />
        )}
      </div>
      <div className="headerMenuMobile">
        <div className="menubtn">
          <img
            width="25"
            height="19"
            style={{
              cursor: "pointer",
            }}
            src={menuIconResponsive}
            alt="menu"
            onClick={() => handleMenuIcon()}
          />
        </div>
        <div>
          <img
            className="head-logo"
            src={LogoMain}
            alt="logo"
            width="95"
            height="37"
          />
        </div>
      </div>
      {share.value && share.isShare ? (
        <ShareModel url={share.value} setShare={setShare} />
      ) : null}
      {/* This is how I'm setting shareFromMakaniCard into the ShareModel to display the ShareModel but I'm not able to close */}
      {shareFromMakaniCard.value && shareFromMakaniCard.isShare ? (
        <ShareModel
          url={shareFromMakaniCard.value}
          setShare={(value) => setShareFromMakaniCard(value)}
        />
      ) : null}
      {showPopup && <DownloadApp />}

      {displaySuggestions ||
        (HighPOI.includes(location.pathname) && (
          <div className="POIMap">
            <Slider {...settings}>
              {POICategories.map((item) => {
                return (
                  <div
                    className="pointOfInternetItemSearchSuggestions"
                    onClick={() => {
                      if (makaniCoords[currentSlide]) {
                        let position = `${makaniCoords[currentSlide]?.lat},${makaniCoords[currentSlide]?.lng}`;
                        handleDisplayPoi(
                          item.CategoryId,
                          position,
                          item.MapIcon,
                          item.Color
                        );
                      } else {
                        toast.info("No POI found!", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          style: {
                            textAlign:
                              il8n.language === "ar" ? "right" : "left",
                          },
                        });
                      }
                    }}
                    style={{
                      paddingRight: il8n.language === "ar" && "0",
                      paddingLeft: il8n.language === "ar" && "14px",
                    }}
                  >
                    <div
                      className="pointOfInternetIcon"
                      style={{ background: item.Color }}
                    >
                      <img
                        width="38"
                        height="38"
                        src={item.MapIcon}
                        alt="atm"
                      />
                    </div>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {il8n.language === "ar" ? item.CategoryA : item.CategoryE}
                    </span>
                  </div>
                );
              })}
            </Slider>
          </div>
        ))}
      {isRequireLogin && <LoginModel />}
      {settingsAlert.value && <SettingsAlert message={settingsAlert.message} />}
    </div>
  );
};
export default withTour(ParentLayout, "map");
