 const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {

            reject(new Error('Error getting user location: ' + error.message));
          }
        );
      } else {
    


        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };
  export default  getUserLocation;
  