import { GetTravelModes } from "../helper/api/requests.ts";
import { useState, useEffect } from "react";
import { useAuth } from "../context/index.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const useGetTravelMode = (buildingid) => {
  const { token, getToken, setToken } = useAuth();
  const [modes, setModes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [t, il8n] = useTranslation();

  const fetchTravelMode = async () => {
    try {
      let result = await GetTravelModes(t, token, buildingid);
      if (result.isExp) {
        let newToken = await getToken();
        localStorage.setItem("token", JSON.stringify(newToken));
        setToken(newToken);
        result = await GetTravelModes(t, newToken, buildingid);
      }

      setLoading(false);
      if (result.error) {
        setModes([]);
        // toast.error(result.error, {
        //   // render: errorMessage,
        //   type: "error",
        //   isLoading: false,
        //   autoClose: 3000,
        //   position: il8n.language === "ar" ? "top-left" : "top-right",
        //   style: {
        //     textAlign: il8n.language === "ar" ? "right" : "left",
        //   },
        // });
      } else {
        setModes(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (token) fetchTravelMode();
  }, []);
  return {
    modes,
    loading,
  };
};

export default useGetTravelMode;
