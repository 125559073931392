import './style.css';
const Toggle = ({ checked, setChecked }) => {
  return (
    <label class='switch'>
      <input
        type='checkbox'
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <span class='slider round'></span>
    </label>
  );
};

export default Toggle;
