import React, { createContext, useState, useContext, useEffect } from 'react';

import {useMapControl} from './MapControllContext'
const RecentSearchContext = createContext();
export const useRecentSearch = () => useContext(RecentSearchContext);

export const RecentSearchProvider = ({ children }) => {
  const [recentSearch, setRecentSearch] = useState([]);
  


  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('recentSearch'));
    
    if (savedData) {
      setRecentSearch(savedData.reverse().splice(0, 3));
    }
  }, []);

  return (
    <RecentSearchContext.Provider
      value={{
        setRecentSearch,
        recentSearch,
      }}
    >
      {children}
    </RecentSearchContext.Provider>
  );
};
export default RecentSearchProvider;
