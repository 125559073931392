import { useState } from "react";
import { ReportMakaniApi } from "../helper/api/requests.ts";
import { useAuth } from "../context/auth.js";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
const useReportMakani = () => {
  const { token, getToken, setToken } = useAuth();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [t, il8n] = useTranslation();
  const fetchData = async (data) => {
    setLoading(true);
    try {
      let result = await ReportMakaniApi(t, data, token);

      if (result.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result = await ReportMakaniApi(t, data, newToken);
      }
      if (result.error) {
        toast.error(result.error, {
          // render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      } else {
        toast.success(t("submitted_successfully"), {
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
        setResult(result);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchData,
    result,
    loading,
    error,
  };
};

export default useReportMakani;
