import { useEffect, useState } from "react";
import {
  ModalCloseBtn,
  Hearticon,
  ShareIcons,
  StreetView,
  ArrowLink,
  DirectionsIcon,
} from "../../assets/svg/svgIcons.jsx";
import { navigateIcon2, detailsMakanilogo } from "../../assets/index.js";
import { useTranslation } from "react-i18next";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";

import "../../scss/common.scss";
import { SHARE_URL_BASE } from "../../helper/api/constants.ts";

const FavoriteLocationPopup = ({
  language,
  info,
  loading,
  handleClick,
  handleNavigate,
  handleStreetView,
  localInfo,
  setShare,
  handelMakaniSearch,
}) => {
  const [isLocation, setIsLocation] = useState(null);

  const [t, il8n] = useTranslation();
  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    checkLocation();
  }, []);
  return (
    <div className="makanicard-details-popup">
      <div className="m-card-top">
        <button className="btn-close" onClick={() => handleClick()}>
          <ModalCloseBtn />
        </button>
        <div className="title-md">
          {loading
            ? t("loading")
            : (language === "ar" ? info.BldgNameAr : info.BldgNameEn) ||
              localInfo?.FavName}
        </div>
        <p> {t("indianRestaurant")}</p>
        <p>
          {" "}
          {t("entrance")} |
          {loading
            ? t("loading")
            : language === "ar"
            ? info.EntNameA
            : info.EntNameE}
        </p>
      </div>

      <div className="button-group">
        <p className="MakaniCardContentDesinence">
          <span className="directions">
            <DirectionsIcon />
          </span>
          {loading
            ? t("loading")
            : !isLocation
            ? "--"
            : parseFloat(info.distance?.toFixed(2))}{" "}
          <span>{t("km")}</span>
        </p>

        <button
          className="md-btn"
          onClick={() => {
            setShare({
              value: `${SHARE_URL_BASE}?l=${
                il8n.language === "ar" ? "A" : "E"
              }&m=${localInfo.FavValue.replace(/\s+/g, "")}`,
              isShare: true,
            });
          }}
        >
          <ShareIcons />
        </button>
        <button className="md-btn">
          <Hearticon />
        </button>
        <button className="md-btn" onClick={() => handleStreetView(info)}>
          <StreetView />
        </button>
        <button
          className="md-btn logo-btn"
          onClick={() => {
            handelMakaniSearch(info);
          }}
        >
          <img
            src={detailsMakanilogo}
            alt="makani-logo"
            className="logo-in-card"
          />
        </button>

        <button
          className="md-btn navg-btn"
          onClick={() => handleNavigate(info, localInfo?.FavName)}
        >
          <img src={navigateIcon2} alt="navigate" />
          {t("navigate")}
        </button>
      </div>
      <div>
        <p class="makaniCardDetailsTitle">{t("details")}</p>
        <div className="makaniCardDetailsAddress">
          <div className="makaniCardDetailsSubAddressWrapper">
            <p className="makaniCardDetailsAddressTitle">{t("address")}</p>
            <p className="makaniCardDetailsSubAddress">
              {t("buildingNumber")} : 01
            </p>
            <p className="makaniCardDetailsSubAddress">
              {t("street")}: {loading ? t("loading") : info.street}
            </p>
            <p className="makaniCardDetailsSubAddress">
              {t("community")}:{" "}
              {loading
                ? t("loading")
                : language === "ar"
                ? info.CommAr
                : info.CommEn}{" "}
              {/* <span className="arrow-link">
                <ArrowLink />
              </span> */}
            </p>
            <p className="makaniCardDetailsSubAddress">
              {t("emirate")}:{" "}
              {loading
                ? t("loading")
                : language === "ar"
                ? info.EmirateA
                : info.EmirateE}
            </p>
          </div>

          {/* COMMENTING CONTACT DETAILS FOR NOW */}
          {/* <div className='contactDetailsWrapper'>
  <p className='makaniCardDetailsAddressTitle'> {t('contactDetails')}</p>
      <div>
          <p>
              <span className='contactDetailsLabel'>
            <div className='icon-md'><img src={SmIconCall} alt="icon" /></div>  
            <a href="tel:+97142200294" className="contact-link"> +971 4 220 0294
    </a>
            </span>  
              <span className='arrow-link'><ArrowLink/></span>
          </p>
      </div>
      <div>

      <p>
              <span className='contactDetailsLabel'>
              <div className='icon-md'><img src={SmIconWeb} alt="icon" /></div>
              
              <a 
      href="http://www.geodubai.ae" 
      className="contact-link" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      www.geodubai.ae
    </a>
            </span>  
              <span className='arrow-link'><ArrowLink/></span>
          </p>
         
      </div>
      <div>
      <p>
              <span className='contactDetailsLabel'>
              <div className='icon-md'><img src={SmIconEmail} alt="icon" /></div>
             
              <a 
      href="mailto:info@gazebo.ae" 
      className="contact-link" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      info@gazebo.ae
    </a>
            </span>  
              <span className='arrow-link'><ArrowLink/></span>
          </p>
      </div>
  </div> */}
        </div>
      </div>
    </div>
  );
};

export default FavoriteLocationPopup;
