import { useState } from "react";
import { GetNodeList } from "../helper/api/requests.ts";
import { useAuth, useAppearance } from "../context/index.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const useGetNodeListFetching = () => {
  const [isNodeListLoading, setLoading] = useState(true);
  const { token, getToken, setToken } = useAuth();
  const [nodeList, setNodeList] = useState([]);
  const [t, il8n] = useTranslation();
  const fetchNodeList = async (buildingid) => {
    try {
      setLoading(true);
      let result = await GetNodeList(t, token, buildingid);

      if (result.isExp) {
        let newToken = await getToken();

        localStorage.setItem("token", JSON.stringify(newToken));
        setToken(newToken);
        result = await GetNodeList(t, newToken, buildingid);
      }

      setNodeList(result.data);
      setLoading(false);
      // if (result.error) {
      //   toast.error(result.error, {
      //     type: "error",
      //     isLoading: false,
      //     autoClose: 3000,
      //     position: il8n.language === "ar" ? "top-left" : "top-right",
      //     style: {
      //       textAlign: il8n.language === "ar" ? "right" : "left",
      //     },
      //   });
      // } else {
      //   setNodeList(result.data);
      //   setLoading(false);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fetchNodeList,
    isNodeListLoading,
    nodeList,
  };
};

export default useGetNodeListFetching;
